import { clientRPC } from "@/utils/hono-client";

export type Feature =
  | "mnemonic"
  | "explain"
  | "example"
  | "visualize"
  | "gen-text"
  | "gen-describe"
  | "deck-expand"
  | "chat"
  | "quiz"
  | "remediation"
  | "explain-pdf"
  | "highlight-flashcards"
  | "usage"
  | RewardAction;

export type RewardAction =
  | "study-flashcard-reward"
  | "do-quiz-reward"
  | "share-deck-reward";

export function getPosthogHandleForRewardAction(action: RewardAction) {
  if (action === "study-flashcard-reward") {
    return "Reward-Awarded-Study-Flashcard";
  }
  if (action === "do-quiz-reward") {
    return "Reward-Awarded-Do-Quiz";
  }
  if (action === "share-deck-reward") {
    return "Reward-Awarded-Share-Deck";
  }

  throw Error(`${action} is not an allowed RewardAction`);
}

export const allRewards = [
  "study-flashcard-reward",
  "do-quiz-reward",
  "share-deck-reward",
] as readonly RewardAction[];

export const REWARD_AMOUNTS: Record<RewardAction, number> = {
  "study-flashcard-reward": 15,
  "do-quiz-reward": 15,
  "share-deck-reward": 20,
};

export const getAvailableRewardsClient = async () => {
  const response = await clientRPC.api.rewards.$get();

  if (!response.ok) {
    throw new Error("Error fetching Rewards");
  }

  const data = await response.json();

  return { availableRewards: data.availableRewards } as {
    availableRewards: Partial<Record<RewardAction, { credits: number }>>;
  };
};
