// import { SignInButton, useUser } from "@clerk/nextjs";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { SignInButton, SignOutButton, useUser } from "@clerk/react-router";
import { Button } from "./ui/button";
import { ExternalLinkIcon, UserIcon } from "lucide-react";
import { DiscordSVG } from "./icons/discord";
import { AICreditDisplay } from "./ai-credits";
// import { SignOutButton } from "@clerk/nextjs";
// import { Button } from "@/components/ui/button";
// import Link from "next/link";
// import { ExternalLinkIcon } from "lucide-react";
// import { DiscordSVG } from "./icons/discord";
// import { AICreditDisplay } from "./folder/ai-credits";
// import { useGuidedTour } from "./guided-tour/guided-tour-provider";
// import { useRouter } from "next/navigation";
// import posthog from "posthog-js";
// import { PersonIcon } from "@radix-ui/react-icons";

export const UserStatusSVG = ({ status }: { status: UserStatus }) => {
  if (status === "master") {
    return <MasterSVG />;
  }
  if (status === "veteran") {
    return <MasterSVG />;
  }
  if (status === "basic") {
    return <UserIcon />;
  }
  if (status === "beast") {
    return <BeastAvatarSVG />;
  }
  if (status === "wizard") {
    return <WizardAvatarSVG />;
  }

  return <></>;
};

const WizardAvatarSVG = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9792_55766)">
        <rect width="32" height="32" rx="8" fill="#C4B5FD" />
        <path
          d="M9.40408 20.4002C5.88408 25.5202 9.80408 31.8669 12.2041 34.4002C14.7376 34.8002 19.8047 35.3602 19.8047 34.4002C19.8047 33.2002 17.0047 29.2002 15.0047 27.2002C13.0047 25.2002 16.2047 18.8002 18.6047 16.4002C21.0047 14.0002 19.8047 11.2002 18.6047 7.2002C17.6447 4.0002 20.6047 0.000195384 22.2047 -0.799805H17.8047C12.6047 2.4002 14.6047 3.6002 13.8047 10.8002C13.0047 18.0002 13.8041 14.0002 9.40408 20.4002Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M10.4737 21.8336C12.11 27.8275 6.31759 32.528 3.21685 34.129C0.693012 33.6718 -4.27559 32.5309 -3.95925 31.6245C-3.56383 30.4915 0.397857 27.6376 2.94519 26.4083C5.49253 25.179 4.58016 18.082 3.10504 15.0252C1.62993 11.9684 3.68556 9.72022 6.13661 6.33904C8.09745 3.6341 6.62083 -1.11787 5.37381 -2.40042L9.52807 -0.950543C13.3832 3.78423 11.0995 4.25817 9.48226 11.3197C7.86505 18.3812 8.42838 14.3411 10.4737 21.8336Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M22.7138 21.8336C21.0775 27.8275 26.8699 32.528 29.9706 34.129C32.4945 33.6718 37.4631 32.5309 37.1468 31.6245C36.7513 30.4915 32.7896 27.6376 30.2423 26.4083C27.695 25.179 28.6073 18.082 30.0825 15.0252C31.5576 11.9684 29.5019 9.72022 27.0509 6.33904C25.0901 3.6341 26.5667 -1.11787 27.8137 -2.40042L23.6594 -0.950543C19.8043 3.78423 22.088 4.25817 23.7052 11.3197C25.3224 18.3812 24.7591 14.3411 22.7138 21.8336Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <g style={{ mixBlendMode: "color" }}>
          <path
            d="M-1.38281 -0.0791016L-0.131725 25.3992L0.411472 31.391L32.8415 30.9953L34.3372 -0.515042L-1.38281 -0.0791016Z"
            fill="url(#paint0_linear_9792_55766)"
          />
        </g>
        <path
          d="M7.94956 15.8961C7.9429 14.5706 9.012 13.4902 10.3375 13.4829L21.206 13.4229C22.5315 13.4156 23.6114 14.4842 23.618 15.8097L23.684 28.9389L8.01553 29.0253L7.94956 15.8961Z"
          fill="#FEF08A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinejoin="round"
        />
        <path
          d="M8.01562 28.9775L23.6841 28.8911L23.7589 43.7684L8.09038 43.8548L8.01562 28.9775Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinejoin="round"
        />
        <path
          d="M8.45077 14.9354C8.44934 14.6529 8.59297 14.4034 8.81178 14.2575L8.95979 43.5491L8.59536 43.5511L8.45077 14.9354Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="0.626658"
        />
        <path
          d="M20.3984 20.7998C19.5984 21.8024 17.9984 21.8682 16.7984 21.1998"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M12 20.7998C12.8 21.8024 14.4 21.8682 15.6 21.1998"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M7.70149 15.1561L4 17.3258C4.88836 19.0616 9.55224 18.2901 11.7731 17.6874C14 18.8004 17.596 18.049 19.2 17.3258C19.8169 16.9642 20.2866 16.5302 21.7672 17.6874C23.2478 18.8446 27.0726 17.9285 28.8 17.3258L24 14.8004L21.7672 10.4004L24 11.6004L27.6 12.0004L20 6.40039L9.92239 9.37012L7.70149 15.1561Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.8 12.8004L8 15.2004H24L22.8 12.4004L8.8 12.8004Z"
          fill="#FEF08A"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M14.8429 12.0002C14.8429 11.8038 15.0021 11.6446 15.1984 11.6446H17.5984C17.7948 11.6446 17.954 11.8038 17.954 12.0002V13.2002V14.4002C17.954 14.5966 17.7948 14.7558 17.5984 14.7558H15.1984C15.0021 14.7558 14.8429 14.5966 14.8429 14.4002V13.2002V12.0002Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M33.0855 22.8154L23.1992 26.0003V30.8003L33.0855 33.7784V22.8154Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M-0.400001 23.2002L8 26.0002V30.8002L-0.8 33.6002L-0.400001 23.2002Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M9.60173 27.6004C6.72173 27.6004 7.89246 24.5835 8.55913 23.3835C8.95991 20.9838 11.7584 23.7832 12.5584 23.3832C13.1984 23.0632 14.8017 22.8004 16.1584 23.3832C17.6017 22.8004 18.425 22.9836 19.3584 23.3835C20.1584 22.6572 21.4917 22.7168 22.5584 22.9835C24.4784 23.3035 24.1584 25.2501 23.7584 26.1835C25.3584 26.1835 23.7584 28.5835 24.1584 29.7835C24.5584 30.9835 20.1546 32.0894 19.7546 32.8894C19.3546 33.6894 16.1546 31.6894 15.7546 32.4894C15.3546 33.2894 9.20251 30.9835 8.80173 29.7835C8.48111 28.8235 9.33455 27.8671 9.60173 27.6004Z"
          fill="#8B5CF6"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8008 24.001C15.6008 24.401 16.8008 24.401 17.6008 24.001"
          stroke="black"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
      </g>
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#A78BFA" />
      <defs>
        <linearGradient
          id="paint0_linear_9792_55766"
          x1="16.4772"
          y1="-0.297072"
          x2="16.8615"
          y2="31.1903"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEF08A" stopOpacity="0" />
          <stop offset="1" stopColor="#FEF08A" />
        </linearGradient>
        <clipPath id="clip0_9792_55766">
          <rect width="32" height="32" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const BeastAvatarSVG = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9792_55723)">
        <rect width="32" height="32" rx="8" fill="#FEF3C6" />
        <path
          d="M3.19418 10.0527C3.11283 8.72972 4.11933 7.59075 5.44228 7.50875L17.6477 6.7522C18.9706 6.6702 20.109 7.67621 20.1904 8.9992L21.079 23.4493L4.08277 24.5028L3.19418 10.0527Z"
          fill="#FDE68A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinejoin="round"
        />
        <path
          d="M4.08203 24.4512L21.0782 23.3977L22.0706 39.536L5.07443 40.5895L4.08203 24.4512Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinejoin="round"
        />
        <path
          d="M3.65614 9.18948C3.63492 8.84456 3.80709 8.53236 4.07914 8.35868L6.04054 40.24L5.56823 40.2693L3.65614 9.18948Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="0.681054"
        />
        <path
          d="M11.4135 16.4379C11.452 17.063 10.9764 17.6012 10.3513 17.6399C9.72618 17.6787 9.18829 17.2033 9.14985 16.5782C9.11141 15.9531 9.58698 15.415 10.2121 15.3762C10.8372 15.3375 11.3751 15.8128 11.4135 16.4379Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M16.1401 16.1459C16.1785 16.771 15.7029 17.3092 15.0778 17.3479C14.4527 17.3867 13.9149 16.9113 13.8764 16.2862C13.838 15.6611 14.3135 15.123 14.9386 15.0842C15.5637 15.0455 16.1016 15.5208 16.1401 16.1459Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <ellipse
          cx="0.392925"
          cy="0.392921"
          rx="0.392925"
          ry="0.392921"
          transform="matrix(0.998084 -0.0618654 0.0613778 0.998115 10.3945 15.2471)"
          fill="#FDE68A"
        />
        <ellipse
          cx="0.39177"
          cy="0.391766"
          rx="0.39177"
          ry="0.391766"
          transform="matrix(0.998084 -0.0618654 0.0613778 0.998115 15.1953 15.2471)"
          fill="#FDE68A"
        />
        <path
          d="M7.19867 15.5998C7.78527 14.5505 8.75985 13.3084 11.5993 13.9992"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M13.6017 13.5049C14.4016 12.8002 16.0011 12.4 17.6735 14.1098"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M4.00234 26.3992L2.40234 24.7992L4.00234 23.1992V26.3992Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M4.5 30L4.339 26.7988L2.4043 24.7988L0.404297 26.7988L-4.5 32L-0.5 35L4.5 30Z"
          fill="#FDE68A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M4.40469 22.3994L0.804688 26.3994"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M21.2031 20.8004V25.2004L22.4031 25.3267L22.0031 20.0657C21.6108 20.2146 21.6083 20.4531 21.2031 20.8004Z"
          fill="#A78BFA"
        />
        <path
          d="M27.3503 25.5258C28.3265 25.5651 29.1097 24.728 29.0056 23.7567L28.0031 14.4004L26.4031 14.8004V22.8004C26.2891 21.4319 24.3219 19.1857 22.0031 20.0657L22.4031 25.3267L27.3503 25.5258Z"
          fill="#FDE68A"
        />
        <path
          d="M22.0031 20.0657C24.3219 19.1857 26.2891 21.4319 26.4031 22.8004V14.8004L28.0031 14.4004L29.0056 23.7567C29.1097 24.728 28.3265 25.5651 27.3503 25.5258L22.4031 25.3267M22.0031 20.0657C21.6108 20.2146 21.6083 20.4531 21.2031 20.8004V25.2004L22.4031 25.3267M22.0031 20.0657L22.4031 25.3267"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinejoin="round"
        />
        <g style={{ mixBlendMode: "color" }}>
          <path
            d="M15.6806 12.12L23.6016 10.7998L29.2016 13.9998L20.6184 20.6022C19.5072 21.457 18.0053 21.6017 16.7514 20.9747L15.8591 20.5286C13.4526 19.3253 12.3702 16.4783 13.3694 13.9802C13.7619 12.999 14.6381 12.2937 15.6806 12.12Z"
            fill="url(#paint0_linear_9792_55723)"
          />
        </g>
        <path
          d="M23.8163 10.7419C23.7721 10.5506 23.8913 10.3596 24.0827 10.3154L27.9876 9.41303C28.1789 9.36881 28.3699 9.48807 28.4141 9.6794L28.8653 11.6319L29.3165 13.5844C29.3607 13.7757 29.2414 13.9666 29.0501 14.0108L25.1451 14.9132C24.9538 14.9574 24.7629 14.8382 24.7187 14.6468L24.2675 12.6944L23.8163 10.7419Z"
          fill="#C4B5FD"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <ellipse
          cx="13.2125"
          cy="18.8113"
          rx="0.399634"
          ry="0.399906"
          transform="rotate(1.60425 13.2125 18.8113)"
          fill="#0A0A0A"
        />
      </g>
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#FEE685" />
      <defs>
        <linearGradient
          id="paint0_linear_9792_55723"
          x1="28.0016"
          y1="11.1998"
          x2="13.6016"
          y2="17.1998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A78BFA" />
          <stop offset="1" stopColor="#A78BFA" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_9792_55723">
          <rect width="32" height="32" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const MasterSVG = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9792_55697)">
        <rect width="32" height="32" rx="8" fill="#C4B4FF" />
        <path
          d="M7.39605 30.379L5.69844 28.883L7.19448 27.1853L7.39605 30.379Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M8.11834 33.9423L7.75602 30.7576L5.69918 28.8835L3.82913 31.0055L-0.737808 36.5052L3.44322 39.2473L8.11834 33.9423Z"
          fill="#FDE68A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M7.54207 26.3621L4.20118 30.5809"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M24.6054 10.9068C24.6033 9.58135 23.527 8.50805 22.2016 8.50954L9.97274 8.5233C8.64726 8.5248 7.57448 9.60052 7.57662 10.926L7.60001 25.4034L24.6288 25.3842L24.6054 10.9068Z"
          fill="#FDE68A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinejoin="round"
        />
        <path
          d="M24.625 25.333L7.59618 25.3522L7.6223 41.521L24.6511 41.5018L24.625 25.333Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinejoin="round"
        />
        <path
          d="M24.089 10.0745C24.0884 9.72896 23.8969 9.42823 23.6145 9.27203L23.6652 41.2136L24.1384 41.213L24.089 10.0745Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="0.681054"
        />
        <path
          d="M16.8057 18.7973C16.8067 19.4236 17.3153 19.9307 17.9416 19.93C18.5679 19.9293 19.0747 19.421 19.0737 18.7947C19.0727 18.1685 18.5642 17.6613 17.9379 17.662C17.3116 17.6627 16.8047 18.171 16.8057 18.7973Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M12.0713 18.8032C12.0724 19.4294 12.5809 19.9366 13.2072 19.9359C13.8335 19.9352 14.3404 19.4269 14.3394 18.8006C14.3383 18.1743 13.8298 17.6672 13.2035 17.6679C12.5772 17.6686 12.0703 18.1769 12.0713 18.8032Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <ellipse
          cx="0.392925"
          cy="0.392921"
          rx="0.392925"
          ry="0.392921"
          transform="matrix(-0.999999 0.00112623 0.0016148 0.999999 17.8828 17.7412)"
          fill="#FDE68A"
        />
        <ellipse
          cx="0.39177"
          cy="0.391766"
          rx="0.39177"
          ry="0.391766"
          transform="matrix(-0.999999 0.00112623 0.0016148 0.999999 12.9531 17.8467)"
          fill="#FDE68A"
        />
        <path
          d="M20.9602 17.6946C20.3087 16.6844 19.2578 15.5062 16.4675 16.3744"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M14.439 16.0073C13.5963 15.3544 11.9747 15.0558 10.4133 16.8674"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
          strokeLinecap="round"
        />
        <path
          d="M24.9563 29.2681L26.4523 27.5705L24.7547 26.0744L24.9563 29.2681Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M24.6892 32.8935L24.6483 29.6885L26.4532 27.5706L28.5752 29.4407L33.7973 34.3226L29.9943 37.5686L24.6892 32.8935Z"
          fill="#FDE68A"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M24.3056 25.301L28.1504 29.0663"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M14 21C14.3333 21.1667 15.3 21.4 16.5 21"
          stroke="#0A0A0A"
          strokeWidth="0.89"
          strokeLinecap="round"
        />
        <path
          d="M25 12.5H7V3.5L8.5 6L10 3.5L11.5 6L13 3.5L14.5 6L16 3.5L17.5 6L19 3.5L20.5 6L22 3.5L23.5 6L25 3.5V12.5Z"
          fill="#FFB900"
          stroke="#0A0A0A"
          strokeWidth="0.89"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4444 8.8C13.4444 8.60363 13.6036 8.44444 13.8 8.44444H17.8079C18.0042 8.44444 18.1634 8.60363 18.1634 8.8V10.8039V12.8079C18.1634 13.0042 18.0042 13.1634 17.8079 13.1634H13.8C13.6036 13.1634 13.4444 13.0042 13.4444 12.8079V10.8039V8.8Z"
          fill="#C4B5FD"
          stroke="#0A0A0A"
          strokeWidth="0.888889"
        />
        <path
          d="M5.90482 26.1429C6.29487 25.2286 7.36753 25 7.85511 25L10.7804 26.1429C12.2431 26.7143 11.268 27.8571 12.2431 27.8571C13.2183 27.8571 12.2431 30.1429 13.2183 30.1429C13.9984 30.1429 14.5185 32.0476 14.681 33H17.1189C16.6313 32.4286 17.6064 31.2857 17.6064 30.7143C17.6064 30.1429 19.5567 28.4286 21.0194 27.8571C22.4822 27.2857 22.9697 26.7143 23.4573 25.5714C23.9449 24.4286 24.4324 25.5714 25.8952 25.5714C27.3579 25.5714 26.3827 27.8571 26.8703 28.4286C27.3579 29 26.3827 31.2857 25.4076 31.2857C24.6275 31.2857 24.7575 32.4286 24.92 33H17.1189H14.681H7.85511C7.85511 32.2381 7.66005 30.7143 6.87982 30.7143C5.90454 30.7143 6.39239 29 5.41724 28.4286C4.4421 27.8571 5.41724 27.2857 5.90482 26.1429Z"
          fill="#FFB900"
        />
        <path
          d="M17.1189 33H24.92C24.7575 32.4286 24.6275 31.2857 25.4076 31.2857C26.3827 31.2857 27.3579 29 26.8703 28.4286C26.3827 27.8571 27.3579 25.5714 25.8952 25.5714C24.4324 25.5714 23.9449 24.4286 23.4573 25.5714C22.9697 26.7143 22.4822 27.2857 21.0194 27.8571C19.5567 28.4286 17.6064 30.1429 17.6064 30.7143C17.6064 31.2857 16.6313 32.4286 17.1189 33ZM17.1189 33H14.681M14.681 33H7.85511C7.85511 32.2381 7.66005 30.7143 6.87982 30.7143C5.90454 30.7143 6.39239 29 5.41724 28.4286C4.4421 27.8571 5.41724 27.2857 5.90482 26.1429C6.29487 25.2286 7.36753 25 7.85511 25C8.34263 25.1905 9.61023 25.6857 10.7804 26.1429C12.2431 26.7143 11.268 27.8571 12.2431 27.8571C13.2183 27.8571 12.2431 30.1429 13.2183 30.1429C13.9984 30.1429 14.5185 32.0476 14.681 33Z"
          stroke="#0A0A0A"
          strokeWidth="0.89"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="0.592593"
        y="0.592593"
        width="30.8148"
        height="30.8148"
        rx="7.40741"
        stroke="#A684FF"
        strokeWidth="1.18519"
      />
      <defs>
        <clipPath id="clip0_9792_55697">
          <rect width="32" height="32" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

type UserStatus = "master" | "veteran" | "basic" | "wizard" | "beast";
export const AvatarWithDropdown = () => {
  const { isLoaded, user } = useUser();
  // const { isGuidedTourAvailable, resetTour } = useGuidedTour("acceptance");

  // const router = useRouter();

  if (!isLoaded) {
    return <></>;
  }

  const userId = user?.id;
  const userStatus = (user?.publicMetadata.status || "basic") as UserStatus;

  return userId ? (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button variant="secondary" size="iconSm" className="p-0">
          <UserStatusSVG status={userStatus} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[320px]">
        <UserUsage />
        <DropdownMenuSeparator />
        <a href="/settings">
          <DropdownMenuItem>Settings</DropdownMenuItem>
        </a>
        {/* {isGuidedTourAvailable ? (
          <Link
            href="/add" // Go to the page of the first step of the tour
            onClick={() => {
              posthog.capture("Guided-Tour-Retake-Dropdown");
              resetTour(true);
            }}
          >
            <DropdownMenuItem>Take Onboarding Tour</DropdownMenuItem>
          </Link>
        ) : (
          <></>
        )} */}
        <DropdownMenuSeparator />
        <a href="/home" target="_blank">
          <DropdownMenuItem className="flex justify-between items-center">
            <div>Flashka Homepage</div>
            <ExternalLinkIcon className="h-4 w-4" />
          </DropdownMenuItem>
        </a>
        <a href={"https://changelog.flashka.ai/changelog"} target="_blank">
          <DropdownMenuItem className="flex justify-between items-center">
            <div>Flashka Updates</div>
            <ExternalLinkIcon className="h-4 w-4" />
          </DropdownMenuItem>
        </a>
        <a href="https://discord.gg/eafSXxrB3F" target="_blank">
          <DropdownMenuItem className="flex justify-between items-center">
            <div className="flex items-center justify-start gap-2">
              <DiscordSVG /> <div>Discord</div>
            </div>
            <ExternalLinkIcon className="h-4 w-4" />
          </DropdownMenuItem>
        </a>
        <DropdownMenuSeparator />
        <SignOutButton>
          <DropdownMenuItem className="text-red-500">Sign Out</DropdownMenuItem>
        </SignOutButton>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <SignInButton>
      <Button variant="primary">Sign In</Button>
    </SignInButton>
  );
};

export const UserUsage = () => {
  return (
    <div className="p-3">
      <AICreditDisplay isCollapsed={false} isShowWhenPremium={true} />
    </div>
  );
};
