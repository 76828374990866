import { useAuth } from "@clerk/react-router";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { useEffect } from "react";

posthog.init(import.meta.env.VITE_POSTHOG_KEY as string, {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  person_profiles: "identified_only",
});
console.log("PHProvider");

export function PHProvider({ children }: { children: any }) {
  const { userId } = useAuth();
  const posthog = usePostHog();

  useEffect(() => {
    if (userId) {
      posthog.identify(userId);
    }
  }, [posthog, userId]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
