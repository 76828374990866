export const GoogleIcon = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3596_789)">
          <path d="M19.7623 10.1868C19.7623 9.36743 19.6958 8.76949 19.552 8.14941H10.1953V11.8477H15.6874C15.5768 12.7668 14.9788 14.1509 13.65 15.081L13.6314 15.2048L16.5898 17.4967L16.7948 17.5171C18.6771 15.7787 19.7623 13.2208 19.7623 10.1868Z" />
          <path d="M10.1937 19.9312C12.8844 19.9312 15.1432 19.0453 16.7932 17.5173L13.6484 15.0812C12.8069 15.6681 11.6774 16.0777 10.1937 16.0777C7.55834 16.0777 5.32163 14.3393 4.5243 11.9365L4.40743 11.9464L1.33124 14.3271L1.29102 14.439C2.92979 17.6944 6.29598 19.9312 10.1937 19.9312Z" />
          <path d="M4.52478 11.9363C4.31439 11.3163 4.19264 10.6518 4.19264 9.96534C4.19264 9.27878 4.31439 8.61443 4.51371 7.99435L4.50813 7.86229L1.3934 5.44336L1.29149 5.49183C0.616073 6.84275 0.228516 8.35977 0.228516 9.96534C0.228516 11.5709 0.616073 13.0879 1.29149 14.4388L4.52478 11.9363Z" />
          <path d="M10.1937 3.85336C12.065 3.85336 13.3273 4.66168 14.047 5.33718L16.8596 2.59107C15.1322 0.985496 12.8844 0 10.1937 0C6.29598 0 2.92979 2.23672 1.29102 5.49214L4.51323 7.99466C5.32163 5.59183 7.55834 3.85336 10.1937 3.85336Z" />
        </g>
        <defs>
          <clipPath id="clip0_3596_789">
            <rect width="20" height="20" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const RedditIcon = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z" />
      </svg>
    </div>
  );
};
