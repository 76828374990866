import { createContext, useCallback, useContext } from "react";
import { useLocalStorage } from "../hooks/use-local-storage";
import { produce } from "immer";
import { useWindowSize } from "usehooks-ts";
import { useGetRemoteUserDecks } from "@/lib/decks-utils/utils";
import { useAuth } from "@clerk/react-router";

export type GuidedTourState = {
  acceptance?: boolean;
  creation?: boolean;
  pdfView?: boolean;
  spacedRepetition?: boolean;
  skipped: boolean;
};

export type GuidedTourContextData = {
  isGuidedTourAvailable: boolean;
  guidedTourState: GuidedTourState;
  skipTour: (step?: GuidedTourStep) => void;
  completeTourStep: (step: GuidedTourStep) => void;
  shouldShowTour: (step: GuidedTourStep) => boolean;
  shouldConfirmTourLeave: (step: GuidedTourStep) => boolean;
  resetTour: (resetToAccepted: boolean) => void;
};

export type GuidedTourStep = keyof Omit<GuidedTourState, "skipped">;

const GuidedTourContext = createContext<GuidedTourContextData | undefined>(
  undefined
);

interface GuidedTourProviderProps {
  children: React.ReactNode;
}

export const useGuidedTour = (currStep: GuidedTourStep) => {
  const guidedTour = useContext(GuidedTourContext);

  if (guidedTour === undefined) {
    throw Error(
      "useGuidedTour hook must be used inside a GuidedTourProvider component"
    );
  }

  const {
    guidedTourState,
    isGuidedTourAvailable,
    skipTour,
    completeTourStep,
    shouldShowTour,
    shouldConfirmTourLeave,
    resetTour,
  } = guidedTour;

  const completeStep = useCallback(() => {
    completeTourStep(currStep);
  }, [currStep, completeTourStep]);

  return {
    guidedTourState,
    skipTour,
    completeTourStep: completeStep,
    shouldShowTour: shouldShowTour(currStep),
    shouldConfirmTourLeave: shouldConfirmTourLeave(currStep),
    resetTour,
    isGuidedTourAvailable,
  };
};

export const GuidedTourProvider: React.FC<GuidedTourProviderProps> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { userId } = useAuth();
  const { data: remoteData } = useGetRemoteUserDecks(userId);

  const userHasAtLeastOneDeck =
    remoteData && remoteData.success
      ? Object.keys(remoteData.data?.decks?.decks ?? {}).length >= 1 ||
        // If this is true, the user has had probably at least one deck
        (remoteData?.data?.deckCount ?? 0) >= 1
      : undefined;

  const { width } = useWindowSize();

  const isMobile = width < 768;

  const [guidedTourState, setGuidedTourState] =
    useLocalStorage<GuidedTourState>("guidedTourState", { skipped: false });

  const skipTour = useCallback(
    (currentStep?: keyof Omit<GuidedTourState, "skipped">) =>
      setGuidedTourState(
        produce(guidedTourState, (draft) => {
          draft.skipped = true;
          // Set completed the current step, to avoid having the step proposed again
          if (currentStep === "pdfView") {
            draft[currentStep] = true;
          }
        })
      ),
    [guidedTourState, setGuidedTourState]
  );

  const completeTourStep = useCallback(
    (step: GuidedTourStep) =>
      setGuidedTourState(
        produce(guidedTourState, (draft) => {
          draft[step] = true;
        })
      ),
    [guidedTourState, setGuidedTourState]
  );

  const resetTour = useCallback(
    (resetToAccepted?: boolean) => {
      setGuidedTourState({ skipped: false, acceptance: resetToAccepted });
    },
    [setGuidedTourState]
  );

  const shouldShowTour = useCallback(
    (currStep: keyof Omit<GuidedTourState, "skipped">) => {
      if (isMobile) {
        return false;
      }
      if (currStep === "acceptance") {
        return (
          userHasAtLeastOneDeck !== undefined &&
          !userHasAtLeastOneDeck &&
          !guidedTourState.acceptance &&
          !guidedTourState.skipped
        );
      } else if (currStep === "pdfView") {
        // Show the tour even if it was skipped
        return !guidedTourState.pdfView;
      } else {
        return (
          (guidedTourState.acceptance ?? false) &&
          !guidedTourState.skipped &&
          !guidedTourState[currStep]
        );
      }
    },
    [userHasAtLeastOneDeck, guidedTourState, isMobile]
  );

  const shouldConfirmTourLeave = useCallback(
    (currStep: keyof Omit<GuidedTourState, "skipped">) => {
      if (isMobile) {
        return false;
      }
      if (currStep === "acceptance") {
        return false;
      } else if (currStep === "pdfView") {
        return (
          (guidedTourState.acceptance ?? false) &&
          !guidedTourState.skipped &&
          !guidedTourState.pdfView
        );
      } else {
        return (
          (guidedTourState.acceptance ?? false) &&
          !guidedTourState.skipped &&
          !guidedTourState[currStep]
        );
      }
    },
    [guidedTourState, isMobile]
  );

  const value = {
    isGuidedTourAvailable: !isMobile,
    guidedTourState,
    skipTour,
    completeTourStep,
    shouldShowTour,
    shouldConfirmTourLeave,
    resetTour,
  };

  return (
    <GuidedTourContext.Provider value={value}>
      {children}
    </GuidedTourContext.Provider>
  );
};
