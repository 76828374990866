import { registerSW } from 'virtual:pwa-register';

export function registerPWA() {
  const updateSW = registerSW({
    onNeedRefresh() {
      // You can show a notification to the user that an update is available
      console.log('New content available, click on reload button to update.');
    },
    onOfflineReady() {
      // You can show a notification that the app is ready for offline use
      console.log('App ready to work offline');
    },
  });

  return updateSW;
}