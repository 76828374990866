import * as React from "react";
import { cn } from "@/lib/utils";
import { Button } from "./button";
import { ArrowUp } from "lucide-react";

type InputProps = React.ComponentProps<"input"> & {
  withSubmit?: boolean;
  onSubmitClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  submitButtonType?: "button" | "reset" | "submit";
};

export function Input({
  className,
  withSubmit = false,
  type,
  submitButtonType = "button",
  onSubmitClick,
  ...props
}: InputProps) {
  const inputComponent = (
    <input
      type={type}
      className={cn(
        "flex h-9 sm:h-11 w-full border-input bg-secondary-50 px-2 py-1 sm:px-3 sm:py-1.5 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-body-600 placeholder:italic focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1  disabled:cursor-not-allowed disabled:opacity-50 rounded-xl border-2 border-secondary-200 hover:border-secondary-400 focus:border-secondary-700 focus-visible:border-secondary-700",
        className
      )}
      {...props}
    />
  );

  if (withSubmit) {
    return (
      <div className="relative">
        {inputComponent}
        <Button
          variant="secondary"
          size="iconMd"
          onClick={onSubmitClick}
          type={submitButtonType}
          className="absolute hidden sm:flex end-1 top-1 rounded-lg"
        >
          <ArrowUp className="h-icon-sm w-icon-sm sm:h-icon-md sm:w-icon-md " />
        </Button>
        <Button
          variant="secondary"
          size="iconSm"
          onClick={onSubmitClick}
          type={submitButtonType}
          className="h-7 w-7 absolute flex sm:hidden end-1 top-1 rounded-lg"
        >
          <ArrowUp className="h-icon-sm w-icon-sm sm:h-icon-md sm:w-icon-md " />
        </Button>
      </div>
    );
  } else {
    return inputComponent;
  }
}

Input.displayName = "Input";

export type { InputProps };
