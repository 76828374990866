interface Currency {
  code: string;
  name: string;
  flag: string;
  symbol: string;
  symbolPosition: "before" | "after";
}

export const CURRENCIES: Currency[] = [
  {
    code: "usd",
    name: "US Dollars",
    flag: "🇺🇸",
    symbol: "$",
    symbolPosition: "before",
  },
  {
    code: "eur",
    name: "Euros",
    flag: "🇪🇺",
    symbol: "€",
    symbolPosition: "before",
  },
  {
    code: "gbp",
    name: "British Pounds",
    flag: "🇬🇧",
    symbol: "£",
    symbolPosition: "before",
  },
  {
    code: "nok",
    name: "Norwegian Kroner",
    flag: "🇳🇴",
    symbol: "kr",
    symbolPosition: "after",
  },
  {
    code: "nzd",
    name: "New Zealand Dollars",
    flag: "🇳🇿",
    symbol: "$",
    symbolPosition: "before",
  },
  {
    code: "aud",
    name: "Australian Dollars",
    flag: "🇦🇺",
    symbol: "$",
    symbolPosition: "before",
  },
  {
    code: "cad",
    name: "Canadian Dollars",
    flag: "🇨🇦",
    symbol: "$",
    symbolPosition: "before",
  },
  {
    code: "jpy",
    name: "Japanese Yen",
    flag: "🇯🇵",
    symbol: "¥",
    symbolPosition: "before",
  },
] as const;

export const CURRENCY_MULTIPLIER: Record<
  (typeof CURRENCIES)[number]["code"],
  number
> = {
  usd: 1,
  eur: 0.95,
  gbp: 0.85,
  nok: 11,
  nzd: 1.75,
  aud: 1.6,
  cad: 1.4,
  jpy: 150,
};
