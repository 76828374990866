import { AdditionalProperites, UserSettings } from "@/lib/decks-utils/utils";
import { CardProps } from "./study-session";
import { Folder } from "@/database/db";

export type UserSync = {
  addedAiDecks: string[];
  decks: Record<string, CardProps[]>;
  additionalProperites: Record<string, AdditionalProperites>;
  folders: Folder[];
  settings: UserSettings;
};

import { useCallback } from "react";
import debounce from "lodash/debounce";
import { compressData, shouldCompress } from "@/lib/compression/utils";
import { clientRPC } from "@/utils/hono-client";

const DEBOUNCE_DELAY = 20_000;
const MAX_WAIT = 60_000;

let USE_COMPRESSION = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounceSync = (toast: (stuff: any) => void) => {
  return useCallback(
    debounce(
      ({
        localDeck,
        additionalProperites,
        latestLocalUpdate,
        folders,
        settings,
      }: {
        localDeck: Record<string, CardProps[]>;
        additionalProperites: Record<string, AdditionalProperites>;
        folders: Folder[];
        latestLocalUpdate: string;
        settings: UserSettings;
        userId: string;
      }) => {
        const data: UserSync = {
          addedAiDecks: localDeck ? Object.keys(localDeck) : [],
          decks: localDeck,
          additionalProperites: additionalProperites,
          folders: folders,
          settings: settings,
        };

        // This is here for testing webWorker approach
        // webWorkerCompress(data);

        // If USE_COMPRESS is already true we don't need to re-compute
        USE_COMPRESSION =
          USE_COMPRESSION === false ? shouldCompress(data) : true;
        console.log(
          "%cMyProject%cline:106%cUSE_COMPRESSION",
          "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
          "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
          "color:#fff;background:rgb(3, 38, 58);padding:3px;border-radius:2px",
          USE_COMPRESSION
        );

        clientRPC.api["user-status"]["sync-user"]
          .$post({
            json: {
              compressed: USE_COMPRESSION,
              data: USE_COMPRESSION ? compressData(data) : data,
              latestLocalUpdate,
            },
            headers: {
              "Content-Type": "application/json",
              ...(USE_COMPRESSION ? { "Content-Encoding": "gzip" } : {}),
            },
          })
          .then((response) => {
            if (response.status === 413) {
              throw new Error("Payload Too Large");
            } else if (!response.ok) {
              throw new Error("Failed to sync");
            }
          })
          .catch((error) => {
            if (error.message === "Payload Too Large") {
              toast({
                title:
                  "Failed to Sync. Please refresh the page and reach out to support to make sure no flashcards would be lost.",
                variant: "destructive",
              });
            } else {
              console.log(`Error syncing: ${error.message}`);
            }
          });
      },
      DEBOUNCE_DELAY,
      { maxWait: MAX_WAIT, leading: true } // Maximum wait of 30 seconds before forcing sync
    ),
    [toast]
  );
};
