import ISO6391 from "iso-639-1";

import { countries } from "countries-list";

const COMMON_COUNTRIES = [
  "United States",
  "United Kingdom",
  "Canada",
  "Australia",
  "Spain",
  "Mexico",
  "Argentina",
  "France",
  "Germany",
  "China",
  "Japan",
  "South Korea",
  "Saudi Arabia",
  "Egypt",
  "Portugal",
  "Brazil",
  "Italy",
  "Switzerland",
];

const COMMON_LANGUAGES = [
  "English",
  "Spanish",
  "French",
  "German",
  "Chinese",
  "Japanese",
  "Korean",
  "Arabic",
  "Portuguese",
  "Italian",
];

export const COUNTRY_LIST = [
  ...COMMON_COUNTRIES,
  ...Object.values(countries)
    .map((country) => country.name)
    .filter((country) => !COMMON_COUNTRIES.includes(country)),
];

export const LANGUAGE_LIST = [
  ...COMMON_LANGUAGES,
  ...ISO6391.getAllNames().filter((lang) => !COMMON_LANGUAGES.includes(lang)),
];

export const FIELDS_OF_STUDY = [
  { key: "high-school", display: "🎓 High School" },
  { key: "medicine", display: "👨‍⚕️ Medicine" },
  { key: "biology", display: "🧬 Biology" },
  { key: "pharmacy", display: "💊 Pharmacy / Pharmacology" },
  { key: "dentistry", display: "🦷 Dentistry" },
  { key: "nursing", display: "💉 Nursing" },
  { key: "veterinary", display: "🐕 Veterinary" },
  { key: "chemistry", display: "🧪 Chemistry" },
  { key: "law", display: "👨‍⚖️ Law" },
  { key: "languages", display: "🌐 Languages" },
  { key: "business", display: "📊 Business studies" },
  { key: "history", display: "🏛️ History" },
  { key: "physics", display: "🍎 Physics / Astronomy" },
  { key: "mathematics", display: "➕ Mathematics" },
  { key: "engineering", display: "🔧 Engineering" },
  { key: "computer-science", display: "💻 Computer Science" },
  { key: "politics", display: "💎 Politics" },
  { key: "geography", display: "🗺️ Geography" },
  { key: "marketing", display: "📈 Marketing" },
  { key: "design", display: "🎨 Design / Architecture" },
  { key: "sports", display: "🤼 Sports" },
  { key: "other", display: "😊 Other" },
];

export const MED_UNIVERSITIES = {
  "United States": [
    "University of Alabama at Birmingham Marnix E. Heersink School of Medicine",
    "Frederick P. Whiddon College of Medicine at the University of South Alabama",
    "University of Arizona College of Medicine - Phoenix",
    "University of Arizona College of Medicine - Tucson",
    "Alice L. Walton School of Medicine",
    "University of Arkansas for Medical Sciences College of Medicine",
    "California Northstate University College of Medicine",
    "California University of Science and Medicine - School of Medicine",
    "Charles R. Drew University of Medicine and Science College of Medicine",
    "David Geffen School of Medicine at UCLA",
    "Kaiser Permanente Bernard J. Tyson School of Medicine",
    "Keck School of Medicine of the University of Southern California",
    "Loma Linda University School of Medicine",
    "Stanford University School of Medicine",
    "University of California, Davis School of Medicine",
    "University of California, Irvine School of Medicine",
    "University of California, Riverside School of Medicine",
    "University of California, San Diego School of Medicine",
    "University of California, San Francisco School of Medicine",
    "University of Colorado School of Medicine",
    "Frank H. Netter MD School of Medicine at Quinnipiac University",
    "University of Connecticut School of Medicine",
    "Yale School of Medicine",
    "George Washington University School of Medicine and Health Sciences",
    "Georgetown University School of Medicine",
    "Howard University College of Medicine",
    "Charles E. Schmidt College of Medicine at Florida Atlantic University",
    "Florida International University Herbert Wertheim College of Medicine",
    "Florida State University College of Medicine",
    "Nova Southeastern University Dr. Kiran C. Patel College of Allopathic Medicine",
    "University of Central Florida College of Medicine",
    "University of Florida College of Medicine",
    "University of Miami Leonard M. Miller School of Medicine",
    "USF Health Morsani College of Medicine",
    "Emory University School of Medicine",
    "Medical College of Georgia at Augusta University",
    "Mercer University School of Medicine",
    "Morehouse School of Medicine",
    "John A. Burns School of Medicine University of Hawaii at Manoa",
    "Carle Illinois College of Medicine",
    "Chicago Medical School at Rosalind Franklin University of Medicine and Science",
    "Loyola University Chicago Stritch School of Medicine",
    "Northwestern University Feinberg School of Medicine",
    "Rush Medical College of Rush University Medical Center",
    "Southern Illinois University School of Medicine",
    "University of Chicago Division of the Biological Sciences, The Pritzker School of Medicine",
    "University of Illinois College of Medicine",
    "Indiana University School of Medicine",
    "University of Iowa Roy J. and Lucille A. Carver College of Medicine",
    "University of Kansas School of Medicine",
    "University of Kentucky College of Medicine",
    "University of Louisville School of Medicine",
    "Louisiana State University School of Medicine in Shreveport",
    "LSU Health Sciences Center School of Medicine in New Orleans",
    "Tulane University School of Medicine",
    "Johns Hopkins University School of Medicine",
    "Uniformed Services University of the Health Sciences, F. Edward Hébert School of Medicine",
    "University of Maryland School of Medicine",
    "Boston University Aram V. Chobanian & Edward Avedisian School of Medicine",
    "Harvard Medical School",
    "Tufts University School of Medicine",
    "University of Massachusetts T.H. Chan School of Medicine",
    "Central Michigan University College of Medicine",
    "Michigan State University College of Human Medicine",
    "Oakland University William Beaumont School of Medicine",
    "University of Michigan Medical School",
    "Wayne State University School of Medicine",
    "Western Michigan University Homer Stryker M.D. School of Medicine",
    "Mayo Clinic Alix School of Medicine",
    "University of Minnesota Medical School",
    "University of Mississippi School of Medicine",
    "Saint Louis University School of Medicine",
    "University of Missouri-Columbia School of Medicine",
    "University of Missouri-Kansas City School of Medicine",
    "Washington University in St. Louis School of Medicine",
    "Creighton University School of Medicine",
    "University of Nebraska College of Medicine",
    "Kirk Kerkorian School of Medicine at UNLV",
    "University of Nevada, Reno School of Medicine",
    "Geisel School of Medicine at Dartmouth",
    "Cooper Medical School of Rowan University",
    "Hackensack Meridian School of Medicine",
    "Rutgers New Jersey Medical School",
    "Rutgers, Robert Wood Johnson Medical School",
    "University of New Mexico School of Medicine",
    "Albany Medical College",
    "Albert Einstein College of Medicine",
    "Columbia University Vagelos College of Physicians and Surgeons",
    "CUNY School of Medicine",
    "Donald and Barbara Zucker School of Medicine at Hofstra/Northwell",
    "Icahn School of Medicine at Mount Sinai",
    "Jacobs School of Medicine and Biomedical Sciences at the University at Buffalo",
    "New York Medical College",
    "NYU Grossman School of Medicine",
    "NYU Grossman Long Island School of Medicine",
    "Renaissance School of Medicine at Stony Brook University",
    "State University of New York Upstate Medical University Alan and Marlene Norton College of Medicine",
    "SUNY Downstate Health Sciences University College of Medicine",
    "University of Rochester School of Medicine and Dentistry",
    "Weill Cornell Medicine",
    "Brody School of Medicine at East Carolina University",
    "Duke University School of Medicine",
    "University of North Carolina School of Medicine",
    "Wake Forest University School of Medicine",
    "University of North Dakota School of Medicine and Health Sciences",
    "Boonshoft School of Medicine Wright State University",
    "Case Western Reserve University School of Medicine",
    "Northeast Ohio Medical University College of Medicine",
    "The Ohio State University College of Medicine",
    "University of Cincinnati College of Medicine",
    "University of Toledo College of Medicine and Life Sciences",
    "University of Oklahoma College of Medicine",
    "Oregon Health & Science University School of Medicine",
    "Drexel University College of Medicine",
    "Geisinger Commonwealth School of Medicine",
    "Lewis Katz School of Medicine at Temple University",
    "Penn State College of Medicine",
    "Raymond and Ruth Perelman School of Medicine at the University of Pennsylvania",
    "Sidney Kimmel Medical College at Thomas Jefferson University",
    "University of Pittsburgh School of Medicine",
    "Ponce Health Sciences University School of Medicine",
    "San Juan Bautista School of Medicine",
    "Universidad Central del Caribe School of Medicine",
    "University of Puerto Rico School of Medicine",
    "The Warren Alpert Medical School of Brown University",
    "Medical University of South Carolina College of Medicine",
    "University of South Carolina School of Medicine, Columbia",
    "University of South Carolina School of Medicine, Greenville",
    "University of South Dakota Sanford School of Medicine",
    "East Tennessee State University James H. Quillen College of Medicine",
    "Meharry Medical College School of Medicine",
    "Thomas F. Frist, Jr. College of Medicine at Belmont University",
    "University of Tennessee Health Science Center College of Medicine",
    "Vanderbilt University School of Medicine",
    "Anne Burnett Marion School of Medicine at TCU",
    "Baylor College of Medicine School of Medicine",
    "McGovern Medical School at The University of Texas Health Science Center at Houston",
    "Paul L. Foster School of Medicine Texas Tech University Health Sciences Center",
    "Texas A&M University College of Medicine",
    "Texas Tech University Health Sciences Center School of Medicine",
    "The University of Texas at Tyler School of Medicine",
    "The University of Texas Health Science Center at San Antonio Joe R. and Teresa Lozano Long School of Medicine",
    "University of Houston Tilman J. Fertitta Family College of Medicine",
    "University of Texas at Austin Dell Medical School",
    "University of Texas Medical Branch John Sealy School of Medicine",
    "University of Texas Rio Grande Valley School of Medicine",
    "University of Texas Southwestern Medical School",
    "Spencer Fox Eccles School of Medicine at the University of Utah",
    "Robert Larner, M.D. College of Medicine at the University of Vermont",
    "Eastern Virginia Medical School at Old Dominion University",
    "University of Virginia School of Medicine",
    "Virginia Commonwealth University School of Medicine",
    "Virginia Tech Carilion School of Medicine",
    "University of Washington School of Medicine",
    "Washington State University Elson S. Floyd College of Medicine",
    "Marshall University Joan C. Edwards School of Medicine",
    "West Virginia University School of Medicine",
    "Medical College of Wisconsin",
    "University of Wisconsin School of Medicine and Public Health",
  ],
  "United Kingdom": [
    "Aston Medical School",
    "Anglia Ruskin School of Medicine",
    "St Mary's School of Medicine",
    "Hertfordshire Medical School",
    "University of Greater Manchester Medical School",
    "Barts and The London School of Medicine and Dentistry",
    "University of Birmingham Medical School",
    "Bristol Medical School",
    "Brighton and Sussex Medical School",
    "Brunel Medical School",
    "University of Buckingham Medical School",
    "School of Clinical Medicine, University of Cambridge",
    "UCLan School of Medicine",
    "Edge Hill University Faculty of Health, Social Care & Medicine",
    "University of Exeter Medical School",
    "Hull York Medical School",
    "Imperial College School of Medicine",
    "Keele University School of Medicine",
    "Kent and Medway Medical School",
    "King's College London School of Medicine and Dentistry",
    "Lancaster Medical School",
    "Leeds School of Medicine",
    "Leicester Medical School",
    "Lincoln Medical School",
    "Liverpool Medical School",
    "Manchester Medical School",
    "Newcastle University Medical School",
    "University of Nottingham Medical School",
    "Norwich Medical School",
    "Oxford University Medical School",
    "Peninsula Medical School",
    "UCL Medical School",
    "Sheffield Medical School",
    "Southampton Medical School",
    "University of Sunderland School of Medicine",
    "University of Surrey School of Medicine",
    "City St George's, University of London",
    "Warwick Medical School",
    "Three Counties Medical School",
    "Chester Medical School",
    "Black Country Medical School",
    "Pears Cumbria School of Medicine",
    "University of Aberdeen School of Medicine",
    "University of Dundee, School of Medicine",
    "University of Edinburgh Medical School",
    "Glasgow Medical School",
    "University of St Andrews School of Medicine",
    "Cardiff University School of Medicine",
    "Swansea University Medical School",
    "North Wales Medical School",
    "Queen's University Belfast Medical School",
    "Magee School of Medicine",
  ],
  Spain: [
    "Universidad Autónoma de Barcelona",
    "Universidad Autónoma de Madrid",
    "Universidad Complutense de Madrid",
    "Universidad de Alcalá",
    "Universidad de Alicante",
    "Universidad de Almería",
    "Universidad de Baleares",
    "Universidad de Barcelona (campus del Clínic)",
    "Universidad de Barcelona (campus de Bellvitge)",
    "Universidad de Cantabria",
    "Universidad de Castilla-La Mancha",
    "Universidad de Cádiz",
    "Universidad de Córdoba",
    "Universidad de Extremadura",
    "Universidad de Girona",
    "Universidad de Granada",
    "Universidad de Jaén",
    "Universidad de La Laguna de Tenerife",
    "Universidad de Las Palmas de Gran Canaria",
    "Universidad de Lleida",
    "Universidad de Murcia",
    "Universidad de Málaga",
    "Universidad de Oviedo",
    "Universidad de Salamanca",
    "Universidad de Santiago de Compostela",
    "Universidad de Sevilla",
    "Universidad de Valladolid",
    "Universidad de Valencia",
    "Universidad de Vic-Universidad Central de Cataluña",
    "Universidad de Zaragoza",
    "Universidad del País Vasco",
    "Universidad Jaume I de Castellón",
    "Universidad Miguel Hernández de Elche",
    "Universidad Pompeu Fabra",
    "Universidad Pública de Navarra",
    "Universidad Rey Juan Carlos",
    "Universidad Rovira i Virgili",
  ],
  Canada: [
    "University of Alberta, Faculty of Medicine and Dentistry",
    "University of Calgary, Cumming School of Medicine",
    "University of British Columbia, Faculty of Medicine",
    "University of Manitoba, Max Rady College of Medicine",
    "Memorial University of Newfoundland, Faculty of Medicine",
    "Dalhousie University, Faculty of Medicine",
    "McMaster University, Michael G. DeGroote School of Medicine",
    "Northern Ontario School of Medicine University",
    "University of Ottawa, Faculty of Medicine",
    "Queen’s University, School of Medicine",
    "University of Toronto, Temerty Faculty of Medicine",
    "Western University, Schulich School of Medicine and Dentistry",
    "Université Laval, Faculté de médecine",
    "McGill University, Faculty of Medicine",
    "Université de Montréal, Faculté de médecine",
    "Université de Sherbrooke, Faculté de médecine",
    "University of Saskatchewan, College of Medicine",
  ],
  Germany: [
    "RWTH Aachen University",
    "University of Augsburg",
    "Charité - Universitätsmedizin Berlin",
    "Ruhr-Universität Bochum",
    "University of Bonn",
    "University of Bonn - Medical Campus Bonn/ Siegen",
    "University of Cologne",
    "Technische Universität Dresden - Campus Chemnitz",
    "Technische Universität Dresden - Campus Dresden",
    "Heinrich Heine University Düsseldorf",
    "University of Duisburg-Essen",
    "FAU Erlangen-Nürnberg",
    "FAU Erlangen-Nürnberg/ Bayreuth",
    "Goethe University Frankfurt",
    "University of Freiburg",
    "Justus Liebig University Gießen",
    "University of Göttingen",
    "University of Greifswald",
    "Martin Luther University Halle-Wittenberg",
    "Universität Hamburg",
    "Hannover Medical School",
    "Heidelberg University - Medical Faculty  Heidelberg",
    "Heidelberg University - Medical Faculty Mannheim",
    "Friedrich Schiller University Jena",
    "Kiel University",
    "Leipzig University",
    "Universität zu Lübeck",
    "Otto von Guericke University Magdeburg",
    "Johannes Gutenberg University Mainz",
    "Philipps-Universität Marburg",
    "University of Münster",
    "LMU - Ludwig-Maximilians-Universität München",
    "Technical University of München",
    "University of Oldenburg - European Medical School Oldenburg - Groningen (NL)",
    "Universität Regensburg",
    "University of Rostock",
    "Saarland University",
    "University of Tübingen",
    "Ulm University",
    "Julius-Maximilians-Universität Würzburg",
  ],
};
