export const OutOfCreditsKa = ({}: {}) => {
  return (
    <div className="w-full flex justify-center">
      <svg
        viewBox="0 0 604 269"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        className="w-full h-auto"
      >
        <g clipPath="url(#clip0_7090_44244)">
          <rect width="604" height="269" fill="white" />
          <path
            d="M215.5 311.497V115.204C215.5 111.74 217.499 108.578 220.636 107.012L277.068 79.0049C283.311 75.903 290.692 80.3601 290.692 87.1963V271.323C290.692 274.274 289.246 277.014 286.817 278.731L230.385 318.935C224.203 323.362 215.5 319.025 215.5 311.527V311.497Z"
            fill="#0A0A0A"
            stroke="#0A0A0A"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M226.692 213.354V311.501V311.531C226.692 319.03 235.395 323.366 241.576 318.939L298.008 278.735C300.438 277.018 301.883 274.278 301.883 271.327V173.855L226.692 213.354Z"
            fill="#A78BFA"
          />
          <path
            d="M224.93 320.607H235.711M226.692 311.501V311.531C226.692 319.03 235.395 323.366 241.576 318.939L298.008 278.735C300.438 277.018 301.883 274.278 301.883 271.327V173.855L226.692 213.354V311.501Z"
            stroke="#0A0A0A"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M231.823 107.012C228.686 108.578 226.687 111.74 226.687 115.204V213.35L301.879 173.851V87.1963C301.879 80.3601 294.498 75.903 288.255 79.0049L231.823 107.012Z"
            fill="#F8E980"
          />
          <path
            d="M280.946 78.0311H291.727M226.687 115.204C226.687 111.74 228.686 108.578 231.823 107.012L288.255 79.0049C294.498 75.903 301.879 80.3601 301.879 87.1963V173.851L226.687 213.35V115.204Z"
            stroke="#0A0A0A"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <ellipse
            cx="256.003"
            cy="169.377"
            rx="6.7225"
            ry="7.31453"
            fill="#0A0A0A"
          />
          <ellipse
            cx="273.942"
            cy="162.065"
            rx="6.7225"
            ry="7.31453"
            fill="#0A0A0A"
          />
          <ellipse
            cx="273.741"
            cy="163.194"
            rx="2.24083"
            ry="2.19436"
            fill="#F8E980"
          />
          <ellipse
            cx="255.741"
            cy="169.194"
            rx="2.24083"
            ry="2.19436"
            fill="#F8E980"
          />
          <path
            d="M226.687 214.254L301.879 174.141"
            stroke="#0A0A0A"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M225.383 213.996L197 267.001H260.5"
            stroke="#0A0A0A"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M302.32 173.768L325.5 195L354 172.5"
            stroke="#0A0A0A"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M262.5 175.841C268 174.5 269 173 270.5 172"
            stroke="#0A0A0A"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M244 165.5C246 164.333 250.2 161 251 157"
            stroke="#0A0A0A"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M281.739 152.721C279.444 153.023 274.082 152.959 271.001 150.286"
            stroke="#0A0A0A"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M349.401 124.174C338.296 133.642 349.07 146.003 355.845 151L362.5 143.5C360.282 141.833 355.845 141.5 358 132C360.986 118.834 389 89 355.845 86.5C329.321 84.5 311.229 74.5 311.229 65C308.255 79.2018 335.102 91.555 347 95.5C358.898 99.445 363.281 112.339 349.401 124.174Z"
            fill="#F5F5F5"
          />
          <path
            d="M394.001 122C401.001 135 371.049 154 371.049 151L364.394 143.5C366.612 141.833 381.289 135.5 378.001 121C375.014 107.834 371.104 93.5221 389 65.5C396.983 53 395 39 389 24C402 42 398 60.5 391.501 73C385.718 84.121 383.41 102.331 394.001 122Z"
            fill="#F5F5F5"
          />
          <path
            d="M315.259 61.8866C314.68 63.8901 312.858 64.8356 311.405 64.4155C309.952 63.9955 308.916 62.2234 309.495 60.2199C310.075 58.2164 311.897 57.271 313.35 57.691C314.802 58.1111 315.839 59.8831 315.259 61.8866Z"
            fill="#0A0A0A"
            stroke="#0A0A0A"
            strokeWidth="3"
          />
          <path
            d="M310.154 61.346C305.468 61.0876 295.011 56.2639 296.25 63.5669C297.502 70.9509 305.571 63.085 310.154 61.346Z"
            stroke="#0A0A0A"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M315.006 62.1296C319.448 63.646 330.95 63.0916 327.065 69.3984C323.137 75.7752 318.604 65.4591 315.006 62.1296Z"
            stroke="#0A0A0A"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M387.915 19.0917C389.323 20.6302 389.18 22.6781 388.065 23.699C386.949 24.7199 384.897 24.681 383.489 23.1425C382.081 21.604 382.223 19.5561 383.339 18.5352C384.454 17.5143 386.507 17.5532 387.915 19.0917Z"
            fill="#0A0A0A"
            stroke="#0A0A0A"
            strokeWidth="3"
          />
          <path
            d="M384.793 23.1691C382.13 27.0341 372.564 33.4449 379.443 36.1932C386.398 38.9721 383.889 27.9868 384.793 23.1691Z"
            stroke="#0A0A0A"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M387.99 19.4356C391.599 16.4349 397.12 6.32916 400.478 12.9314C403.874 19.6069 392.707 18.1002 387.99 19.4356Z"
            stroke="#0A0A0A"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M349.413 179.515C350.613 177.915 350.413 170.515 350.413 170.515C350.413 170.515 347.413 163.071 343.913 159.514C340.469 156.013 340.019 151.638 342.969 149.013C346.032 146.287 351.563 151.762 355.469 150.513C359.971 149.073 359.253 142.344 363.969 142.014C369.156 141.651 371.362 148.281 376.413 149.515C379.827 150.349 381.323 145.947 384.469 147.514C391.515 151.023 378.913 160.515 375.413 167.015C372.613 172.215 373.049 176.016 375.413 179.515C377.778 183.014 395.5 200 395.5 227.5C395.5 255 355.438 273.028 338.469 250.014C321.5 227 347.913 181.515 349.413 179.515Z"
            fill="#FDE68A"
            stroke="#0A0A0A"
            strokeWidth="3"
          />
          <path
            d="M371.122 222.828C372.383 223.298 373.504 224.078 374.383 225.096C375.262 226.114 375.87 227.338 376.151 228.653C376.432 229.969 376.377 231.334 375.99 232.622C375.604 233.911 374.899 235.081 373.94 236.025C372.982 236.969 371.801 237.655 370.506 238.022C369.212 238.388 367.846 238.422 366.535 238.121C365.224 237.819 364.01 237.192 363.006 236.297C362.002 235.403 361.239 234.269 360.789 233.001M356.336 217.001H357.669V222.335M369.282 227.508L370.216 228.455L366.456 232.215M365.669 219.668C365.669 224.086 362.087 227.668 357.669 227.668C353.251 227.668 349.669 224.086 349.669 219.668C349.669 215.25 353.251 211.668 357.669 211.668C362.087 211.668 365.669 215.25 365.669 219.668Z"
            stroke="#0A0A0A"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M351 171L374 169" stroke="#0A0A0A" strokeWidth="3" />
          <path d="M351.5 176L372.5 175" stroke="#0A0A0A" strokeWidth="3" />
          <path
            d="M350.653 187.541C349.847 187.733 349.349 188.542 349.541 189.347C349.733 190.153 350.542 190.651 351.347 190.459L350.653 187.541ZM356.577 176.474C357.089 178.01 357.381 180.417 356.663 182.592C355.983 184.651 354.356 186.659 350.653 187.541L351.347 190.459C356.044 189.341 358.5 186.599 359.512 183.533C360.486 180.583 360.078 177.49 359.423 175.526L356.577 176.474Z"
            fill="#0A0A0A"
          />
          <path
            d="M372.753 190.48C373.57 190.616 374.343 190.064 374.479 189.247C374.615 188.429 374.063 187.657 373.246 187.52L372.753 190.48ZM363.001 175.438C362.91 177.612 363.256 180.934 364.623 183.924C366.008 186.953 368.519 189.774 372.753 190.48L373.246 187.52C370.28 187.026 368.458 185.097 367.351 182.676C366.227 180.216 365.922 177.388 365.998 175.562L363.001 175.438Z"
            fill="#0A0A0A"
          />
        </g>
        <defs>
          <clipPath id="clip0_7090_44244">
            <rect width="604" height="269" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
