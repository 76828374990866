import { useTheme } from "@/settings/theme";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const ToasterSonner = ({ ...props }: ToasterProps) => {
  const { theme } = useTheme();

  return (
    <Sonner
      // theme={theme as ToasterProps["theme"]}
      theme={theme}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg px-4 py-2 w-fit",
          description: "group-[.toast]:text-muted-foreground",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
          closeButton: "rounded-md bg-white",
        },
      }}
      richColors
      closeButton
      expand
      {...props}
    />
  );
};

export { ToasterSonner };
