import { useState, useEffect } from "react";

export default function AnimatedStrikethrough({ text }: { text: string }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex items-center justify-center">
      <span
        className={`font-semibold relative ${
          animate ? "animate-strikethrough" : ""
        }`}
      >
        {text}
      </span>
      <style>{`
        @keyframes strikethrough {
          0% {
            width: 0;
          }
          100% {
            width: 100%;
          }
        }
        .animate-strikethrough::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          width: 0;
          height: 1px;
          background-color: #000;
          animation: strikethrough 0.5s ease-in-out forwards;
        }
      `}</style>
    </div>
  );
}
