import { useLocation, useSearchParams } from "react-router";
import { useGetUserStateAndAlign } from "@/lib/decks-utils/utils";
import { useEffect, useRef } from "react";
import { isToday } from "date-fns";

export const useOnboardingEvents = (
  useOnboardingProps?:
    | {
        onDeckCreated?: () => void;
        onFlashcardCreated?: () => void;
        onFlashcardStudied?: () => void;
        onFlashcardStudiedToday?: () => void;
        onFlashcardCreatedToday?: () => void;
      }
    | undefined
) => {
  const pathname = useLocation().pathname;
  const [params] = useSearchParams();
  const deckNameStudyMode = params.get("deck") as string | null;

  const { localDecks } = useGetUserStateAndAlign();

  const prevTotalCardsRef = useRef<number | null>(null);
  const prevTotalCardsCreatedTodayRef = useRef<number | null>(null);

  const prevTotalStudiedCardsRef = useRef<number | null>(null);
  const prevTodayStudiedCardsRef = useRef<number | null>(null);
  const prevTotalDecksRef = useRef<number | null>(null);

  const totalCards = localDecks
    ? localDecks.reduce((sum, deck) => sum + deck.cards.length, 0)
    : -1;

  const totalCardsCreatedToday = localDecks
    ? localDecks.reduce(
        (sum, deck) =>
          sum +
          deck.cards.filter((card) => isToday(card.createdTimeISO)).length,
        0
      )
    : -1;

  const studiedCards = localDecks
    ? localDecks.reduce((sum, deck) => {
        return (
          sum +
          deck.cards.filter((card) =>
            card.studyState.some(
              (state) => state.type === "good" || state.type === "easy"
            )
          ).length
        );
      }, 0)
    : -1;

  const studiedCardsToday = localDecks
    ? localDecks.reduce((sum, deck) => {
        return (
          sum +
          deck.cards.filter((card) =>
            card.studyState.some(
              (state) =>
                (state.type === "good" || state.type === "easy") &&
                isToday(state.ISO)
            )
          ).length
        );
      }, 0)
    : -1;

  const totalDecks = localDecks ? localDecks.length : -1;

  useEffect(() => {
    if (prevTotalDecksRef.current !== null) {
      if (prevTotalDecksRef.current === 0 && totalDecks === 1) {
        useOnboardingProps?.onDeckCreated?.();
      }
    }

    if (prevTotalCardsRef.current !== null) {
      if (
        prevTotalCardsRef.current < 10 &&
        totalCards >= 10 &&
        pathname.split("/")[1] === "deck"
      ) {
        useOnboardingProps?.onFlashcardCreated?.();
      }
    }

    if (prevTotalCardsCreatedTodayRef.current !== null) {
      if (
        prevTotalCardsCreatedTodayRef.current < 10 &&
        totalCardsCreatedToday >= 10 &&
        pathname.split("/")[1] === "deck"
      ) {
        useOnboardingProps?.onFlashcardCreatedToday?.();
      }
    }

    if (prevTotalStudiedCardsRef.current !== null) {
      if (
        prevTotalStudiedCardsRef.current < 10 &&
        studiedCards >= 10 &&
        pathname.split("/")[1] === "memo-ai"
      ) {
        useOnboardingProps?.onFlashcardStudied?.();
      }
    }

    if (prevTodayStudiedCardsRef.current !== null) {
      if (
        prevTodayStudiedCardsRef.current < 10 &&
        studiedCardsToday >= 10 &&
        pathname.split("/")[1] === "memo-ai"
      ) {
        useOnboardingProps?.onFlashcardStudiedToday?.();
      }
    }

    if (totalDecks !== -1) {
      prevTotalDecksRef.current = totalDecks;
    }

    if (totalCards !== -1) {
      prevTotalCardsRef.current = totalCards;
    }

    if (studiedCards !== -1) {
      prevTotalStudiedCardsRef.current = studiedCards;
    }

    if (studiedCardsToday !== -1) {
      prevTodayStudiedCardsRef.current = studiedCardsToday;
    }

    if (totalCardsCreatedToday !== -1) {
      prevTotalCardsCreatedTodayRef.current = totalCardsCreatedToday;
    }
  }, [
    totalDecks,
    totalCards,
    studiedCards,
    studiedCardsToday,
    totalCardsCreatedToday,
    pathname,
    deckNameStudyMode,
  ]);
};
