import punycode from "punycode";

export const formatDeckName = (fileName: string) => {
  try {
    const deckNameArray = fileName.split("-");
    deckNameArray.pop();

    const reformatted = punycode.toUnicode(deckNameArray.join("-"));

    if (reformatted.includes(".txt")) {
      const newDeckNameArray = reformatted.split("-");
      newDeckNameArray.pop();
      return newDeckNameArray.join("-").replace(/_/g, " ");
    }

    return punycode.toUnicode(deckNameArray.join("-"));
  } catch (e) {
    // TODO: add logging for me
    const deckArray = fileName.split("-");
    deckArray.pop();

    if (deckArray[deckArray.length - 1].endsWith(".txt")) {
      deckArray.pop();
    }

    return deckArray.join("-");
  }
};
