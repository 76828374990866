import { Button } from "@/components/ui/button";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { CoinsIcon, InfoIcon, SquareCheckIcon, ZapIcon } from "lucide-react";
import { useEffect, useState } from "react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { cn } from "@/lib/utils";
import CurrencySelector from "@/components/pricing/currency-selector";
import { CURRENCIES, CURRENCY_MULTIPLIER } from "@/utils/pricing";
import { useCurrency } from "@/contexts/currency-context";
import { Spinner } from "@/components/add/utils";
import { useMutation } from "@tanstack/react-query";
import { clientRPC } from "@/utils/hono-client";

const KaProfileImageRight = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3535_6665)">
        <circle
          cx="31"
          cy="31"
          r="31"
          transform="matrix(-1 0 0 1 60 0)"
          fill="white"
        />
        <path
          d="M13.0898 12L46.6147 12V46.8658H13.0898L13.0898 12Z"
          fill="#FAFAFA"
          stroke="#A3A3A3"
          strokeWidth="2.325"
          strokeLinejoin="round"
        />
        <path
          d="M13.0898 43.7373H46.6147V78.6031H13.0898L13.0898 43.7373Z"
          fill="#D4D4D4"
          stroke="#A3A3A3"
          strokeWidth="2.325"
          strokeLinejoin="round"
        />
        <rect
          x="13.7695"
          y="12"
          width="2.68199"
          height="66.6027"
          fill="#A3A3A3"
        />
        <path
          d="M4.59214 51.2787L3.91607 50.333C3.41607 50.6904 3.28072 51.3753 3.60715 51.8961L4.59214 51.2787ZM11.8494 65.0452C12.1904 65.5891 12.9078 65.7537 13.4518 65.4127C13.9958 65.0717 14.1603 64.3543 13.8193 63.8103L11.8494 65.0452ZM14.4645 42.7919L3.91607 50.333L5.26821 52.2244L15.8167 44.6833L14.4645 42.7919ZM3.60715 51.8961L11.8494 65.0452L13.8193 63.8103L5.57713 50.6612L3.60715 51.8961Z"
          fill="#A3A3A3"
        />
        <path
          d="M56.0167 51.5628L56.744 50.6559C57.2042 51.0251 57.315 51.6803 57.0016 52.1802L56.0167 51.5628ZM48.7594 65.3293C48.4184 65.8733 47.701 66.0378 47.157 65.6968C46.613 65.3558 46.4484 64.6384 46.7894 64.0944L48.7594 65.3293ZM47.3438 43.1162L56.744 50.6559L55.2893 52.4696L45.8891 44.9299L47.3438 43.1162ZM57.0016 52.1802L48.7594 65.3293L46.7894 64.0944L55.0317 50.9454L57.0016 52.1802Z"
          fill="#A3A3A3"
        />
        <circle cx="29.6993" cy="32.6978" r="2.69735" fill="#A3A3A3" />
        <ellipse
          cx="37.8067"
          cy="32.6978"
          rx="2.69735"
          ry="2.69735"
          fill="#A3A3A3"
        />
        <circle cx="30.1411" cy="31.1929" r="0.674336" fill="#FAFAFA" />
        <ellipse
          cx="38.2075"
          cy="31.1929"
          rx="0.674337"
          ry="0.674336"
          fill="#FAFAFA"
        />
        <ellipse
          cx="34.123"
          cy="36.5702"
          rx="0.929597"
          ry="0.929599"
          fill="#A3A3A3"
        />
      </g>
      <rect
        x="-1.25"
        y="1.25"
        width="57.5"
        height="57.5"
        rx="28.75"
        transform="matrix(-1 0 0 1 57.5 0)"
        stroke="#E5E5E5"
        strokeWidth="2.5"
      />
      <defs>
        <clipPath id="clip0_3535_6665">
          <rect
            width="60"
            height="60"
            rx="30"
            transform="matrix(-1 0 0 1 60 0)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const BeastSVG = () => {
  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6123_51752)">
        <rect x="0.333496" width="54" height="54" rx="16.2" fill="#FEF3C7" />
        <path
          d="M5.72416 16.9672C5.58688 14.7346 7.28536 12.8126 9.51783 12.6742L30.1144 11.3976C32.3469 11.2592 34.268 12.9568 34.4053 15.1894L35.9048 39.574L7.22365 41.3518L5.72416 16.9672Z"
          fill="#FEF08A"
          stroke="#0A0A0A"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M7.22363 41.2637L35.9047 39.4859L37.5794 66.7194L8.89831 68.4972L7.22363 41.2637Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M6.50201 15.5096C6.46621 14.9275 6.75674 14.4007 7.21583 14.1076L10.5257 67.9073L9.72866 67.9567L6.50201 15.5096Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="1.14928"
        />
        <path
          d="M19.5967 27.7416C19.6616 28.7965 18.8591 29.7046 17.8042 29.77C16.7494 29.8354 15.8417 29.0332 15.7768 27.9784C15.7119 26.9235 16.5145 26.0154 17.5693 25.95C18.6242 25.8846 19.5319 26.6868 19.5967 27.7416Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="1.5"
        />
        <path
          d="M27.5733 27.2475C27.6382 28.3023 26.8356 29.2105 25.7808 29.2759C24.7259 29.3412 23.8182 28.5391 23.7534 27.4843C23.6885 26.4294 24.491 25.5213 25.5459 25.4559C26.6007 25.3905 27.5084 26.1926 27.5733 27.2475Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="1.5"
        />
        <ellipse
          cx="0.663061"
          cy="0.663054"
          rx="0.663061"
          ry="0.663054"
          transform="matrix(0.998084 -0.0618654 0.0613778 0.998115 17.8804 25.7314)"
          fill="#FEF08A"
        />
        <ellipse
          cx="0.661112"
          cy="0.661104"
          rx="0.661112"
          ry="0.661104"
          transform="matrix(0.998084 -0.0618654 0.0613778 0.998115 25.9819 25.7314)"
          fill="#FEF08A"
        />
        <path
          d="M12.4815 26.3241C13.4714 24.5534 15.116 22.4574 19.9076 23.6231"
          stroke="#0A0A0A"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M23.2834 22.7889C24.6332 21.5997 27.3324 20.9244 30.1546 23.8095"
          stroke="#0A0A0A"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M7.08428 44.5504L4.38428 41.8504L7.08428 39.1504V44.5504Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="1.5"
        />
        <path
          d="M7.08428 50.6246V44.5496L4.38428 41.8496L1.00928 45.2246L7.08428 50.6246Z"
          fill="#FEF08A"
          stroke="black"
          strokeWidth="1.5"
        />
        <path
          d="M7.76006 37.7998L1.68506 44.5498"
          stroke="black"
          strokeWidth="1.5"
        />
        <path
          d="M36.1089 35.0998V42.5248L38.1339 42.738L37.4589 33.8601C36.7968 34.1114 36.7927 34.5137 36.1089 35.0998Z"
          fill="#A78BFA"
        />
        <path
          d="M46.4823 43.074C48.1296 43.1403 49.4512 41.7277 49.2755 40.0885L47.5839 24.2998L44.8839 24.9748V38.4748C44.6914 36.1656 41.3718 32.375 37.4589 33.8601L38.1339 42.738L46.4823 43.074Z"
          fill="#FEF08A"
        />
        <path
          d="M37.4589 33.8601C41.3718 32.375 44.6914 36.1656 44.8839 38.4748V24.9748L47.5839 24.2998L49.2755 40.0885C49.4512 41.7277 48.1296 43.1403 46.4823 43.074L38.1339 42.738M37.4589 33.8601C36.7968 34.1114 36.7927 34.5137 36.1089 35.0998V42.5248L38.1339 42.738M37.4589 33.8601L38.1339 42.738"
          stroke="#0A0A0A"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <g style={{ mixBlendMode: "color" }}>
          <path
            d="M26.793 20.4524L40.1597 18.2246L49.6097 23.6246L35.1256 34.7662C33.2504 36.2086 30.716 36.4528 28.6 35.3948L27.0942 34.6419C23.0333 32.6114 21.2067 27.8071 22.8929 23.5916C23.5552 21.9357 25.0338 20.7456 26.793 20.4524Z"
            fill="url(#paint0_linear_6123_51752)"
          />
        </g>
        <path
          d="M40.5186 18.1269C40.444 17.8041 40.6452 17.4819 40.9681 17.4073L47.5577 15.8845C47.8806 15.8099 48.2028 16.0111 48.2774 16.334L49.0388 19.6288L49.8002 22.9236C49.8748 23.2465 49.6735 23.5687 49.3507 23.6433L42.761 25.1661C42.4382 25.2407 42.116 25.0394 42.0414 24.7166L41.28 21.4217L40.5186 18.1269Z"
          fill="#C4B5FD"
          stroke="#0A0A0A"
          strokeWidth="1.5"
        />
        <ellipse
          cx="22.6254"
          cy="31.7433"
          rx="0.674382"
          ry="0.674842"
          transform="rotate(1.60425 22.6254 31.7433)"
          fill="#0A0A0A"
        />
      </g>
      <rect
        x="1.3335"
        y="1"
        width="52"
        height="52"
        rx="15.2"
        stroke="#FACC15"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6123_51752"
          x1="47.5847"
          y1="18.8996"
          x2="23.2847"
          y2="29.0246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A78BFA" />
          <stop offset="1" stopColor="#A78BFA" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_6123_51752">
          <rect x="0.333496" width="54" height="54" rx="16.2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const WizardSVG = () => {
  return (
    <svg
      width="55"
      height="54"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6123_51818)">
        <rect x="0.666504" width="54" height="54" rx="16.2" fill="#C4B5FD" />
        <path
          d="M16.5359 34.4254C10.5959 43.0654 17.2109 53.7754 21.2609 58.0504C25.5362 58.7254 34.0869 59.6704 34.0869 58.0504C34.0869 56.0254 29.3619 49.2754 25.9869 45.9004C22.6119 42.5254 28.0119 31.7254 32.0619 27.6754C36.1119 23.6254 34.0869 18.9004 32.0619 12.1504C30.4419 6.75039 35.4369 0.000390649 38.1369 -1.34961H30.7119C21.9369 4.05039 25.3119 6.07539 23.9619 18.2254C22.6119 30.3754 23.9609 23.6254 16.5359 34.4254Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M18.3402 36.8448C21.1014 46.9596 11.3267 54.8917 6.09421 57.5934C1.83523 56.8219 -6.54928 54.8965 -6.01547 53.367C-5.34819 51.4551 1.33716 46.639 5.63578 44.5647C9.93441 42.4903 8.39479 30.5141 5.90553 25.3557C3.41628 20.1974 6.88515 16.4035 11.0213 10.6978C14.3302 6.13321 11.8384 -1.88574 9.73407 -4.05004L16.7444 -1.60337C23.2499 6.38655 19.3961 7.18633 16.6671 19.1026C13.938 31.0189 14.8887 24.2013 18.3402 36.8448Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M38.9928 36.8448C36.2316 46.9596 46.0063 54.8917 51.2388 57.5934C55.4978 56.8219 63.8823 54.8965 63.3485 53.367C62.6812 51.4551 55.9959 46.639 51.6972 44.5647C47.3986 42.4903 48.9382 30.5141 51.4275 25.3557C53.9167 20.1974 50.4479 16.4035 46.3117 10.6978C43.0028 6.13321 45.4946 -1.88574 47.5989 -4.05004L40.5886 -1.60337C34.0831 6.38655 37.9369 7.18633 40.6659 19.1026C43.395 31.0189 42.4444 24.2013 38.9928 36.8448Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <g>
          <path
            d="M1.69385 5.48535L3.5128 43.9017L4.29811 52.9365L50.8696 52.3682L52.99 4.85931L1.69385 5.48535Z"
            fill="url(#paint0_linear_6123_51818)"
          />
        </g>
        <path
          d="M14.0814 26.8253C14.0701 24.5886 15.8743 22.7654 18.111 22.7531L36.4517 22.6519C38.6884 22.6396 40.5107 24.4428 40.522 26.6795L40.6333 48.8352L14.1927 48.9809L14.0814 26.8253Z"
          fill="#FEF08A"
          stroke="#0A0A0A"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M14.1938 48.9004L40.6344 48.7546L40.7606 73.86L14.32 74.0058L14.1938 48.9004Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M14.9272 25.2046C14.9248 24.7278 15.1671 24.3067 15.5364 24.0606L15.7861 73.4901L15.1712 73.4935L14.9272 25.2046Z"
          fill="#0A0A0A"
          stroke="#0A0A0A"
          strokeWidth="1.05749"
        />
        <path
          d="M35.0923 35.0996C33.7423 36.7914 31.0423 36.9025 29.0173 35.7746"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M20.9165 35.0996C22.2665 36.7914 24.9665 36.9025 26.9915 35.7746"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M13.6628 25.575L7.4165 29.2364C8.91561 32.1655 16.7859 30.8637 20.5337 29.8466C24.2915 31.7248 30.3598 30.4569 33.0665 29.2364C34.1075 28.6262 34.9001 27.8939 37.3986 29.8466C39.8971 31.7994 46.3516 30.2535 49.2665 29.2364L41.1665 24.9748L37.3986 17.5498L41.1665 19.5748L47.2415 20.2498L34.4165 10.7998L17.4105 15.8112L13.6628 25.575Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5165 21.5998L14.1665 25.6498H41.1665L39.1415 20.9248L15.5165 21.5998Z"
          fill="#FEF08A"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M25.7173 20.2494C25.7173 19.918 25.9859 19.6494 26.3173 19.6494H30.3673C30.6987 19.6494 30.9673 19.918 30.9673 20.2494V22.2744V24.2994C30.9673 24.6308 30.6987 24.8994 30.3673 24.8994H26.3173C25.9859 24.8994 25.7173 24.6308 25.7173 24.2994V22.2744V20.2494Z"
          fill="#A78BFA"
          stroke="#0A0A0A"
          strokeWidth="1.5"
        />
        <path
          d="M53.9919 39.1494L39.8169 43.8744V51.9744L54.6669 56.6994L53.9919 39.1494Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M-0.00849628 39.1494L14.1665 43.8744V51.9744L-0.683496 56.6994L-0.00849628 39.1494Z"
          fill="#A78BFA"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M16.8675 46.5748C12.0075 46.5748 13.9831 41.4838 15.1081 39.4588C15.7844 35.4093 20.5068 40.1333 21.8568 39.4583C22.9368 38.9183 25.6425 38.4748 27.9318 39.4583C30.3675 38.4748 31.7568 38.7839 33.3318 39.4588C34.6818 38.2331 36.9318 38.3338 38.7318 38.7838C41.9718 39.3238 41.4318 42.6088 40.7568 44.1838C43.4568 44.1838 40.7568 48.2338 41.4318 50.2588C42.1068 52.2838 34.6755 54.15 34.0005 55.5C33.3255 56.85 27.9255 53.475 27.2505 54.825C26.5755 56.175 16.1938 52.2838 15.5175 50.2588C14.9764 48.6388 16.4166 47.0248 16.8675 46.5748Z"
          fill="#8B5CF6"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.6411 40.5C26.9911 41.175 29.0161 41.175 30.3661 40.5"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <rect
        x="1.6665"
        y="1"
        width="52"
        height="52"
        rx="15.2"
        stroke="#8B5CF6"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6123_51818"
          x1="27.3419"
          y1="5.17233"
          x2="27.9214"
          y2="52.6482"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEF08A" stopOpacity="0" />
          <stop offset="1" stopColor="#FEF08A" />
        </linearGradient>
        <clipPath id="clip0_6123_51818">
          <rect x="0.666504" width="54" height="54" rx="16.2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const InfoButtonAICredits = () => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger className="rounded-full">
        <InfoIcon className="text-body-600" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="text-body-600 flex flex-col gap-2 max-w-xs p-3">
        <div className="flex items-center font-semibold">
          <CoinsIcon className="mr-1" /> 1 Credit = 1 AI Use
        </div>
        <div>
          We wanted to keep it simple, so if you generate a{" "}
          <span className="font-semibold">Flashcard</span>, a{" "}
          <span className="font-semibold">Quiz</span> or use the{" "}
          <span className="font-semibold">AI helps</span>, it's always{" "}
          <span className="font-semibold">1 credit</span> :)
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const CheckIconLevel = ({
  type,
}: {
  type: "gray" | "red" | "yellow" | "green" | "amber";
}) => {
  return (
    <SquareCheckIcon
      className={cn(
        "h-6 w-6 p-0 m-0 rounded-sm",
        type === "gray"
          ? "text-body-400 bg-secondary-100"
          : type === "red"
          ? "text-alert-400 bg-alert-100"
          : type === "yellow"
          ? "text-hard-400 bg-hard-100"
          : type === "green"
          ? "text-valid-400 bg-valid-100"
          : type === "amber"
          ? "text-hard-400 bg-hard-100"
          : ""
      )}
    />
  );
};

const FreeOption = () => {
  return (
    <div className="w-[350px] md:w-[380px] border-secondary-200 rounded-3xl border-2 flex flex-col gap-4 p-5">
      <div className="flex flex-col gap-1">
        <KaProfileImageRight />
        <div className="font-semibold text-body-600">Rookie</div>
      </div>
      <div className="w-full text-center mt-4 mb-6">
        <div className="text-body-600 text-6xl font-bold">Free</div>
        <div className="text-body-600 text-xl">Forever</div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="gray" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">50</span> AI credits / day{" "}
            </div>
            <InfoButtonAICredits />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="gray" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">Full mode</span> PDF generation not
              supported
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="gray" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">Export</span> flashcards not supported
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="gray" />
          <div>
            <span className="font-semibold">1</span> hour saved/day
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div>
            <span className="font-semibold">Unlimited</span> spaced repetition
            studying
          </div>
        </div>

        <div className="mt-2">
          <Button
            variant="primary"
            className="w-full h-10 rounded-full disabled:bg-secondary-200"
            disabled
          >
            Current Plan
          </Button>
        </div>
      </div>
    </div>
  );
};

// declare var rewardful: (event: string, callback: () => void) => void;
// declare var Rewardful: { referral: string | null };

const BeastOption = ({
  isYearly,
  currency,
}: {
  isYearly: boolean;
  currency: string;
}) => {
  const [isLoading] = useState(false);
  const [referral] = useState<string | null>(null);
  const symbol = CURRENCIES.find((c) => c.code === currency)?.symbol || "$";

  const checkoutMutation = useMutation({
    mutationFn: async () => {
      const response = await clientRPC.api.checkout.$post({
        json: {
          currency,
          type: "BEAST",
          isYearly,
          referral: referral || "",
        },
      });

      if (!response.ok) {
        throw new Error("Checkout failed");
      }

      return response.json();
    },
    onSuccess: (data) => {
      // Redirect to Stripe checkout or handle success
      if (data.url) {
        window.location.href = data.url;
      }
    },
  });

  // useEffect(() => {
  //   rewardful("ready", function () {
  //     setReferral(Rewardful.referral);
  //   });
  // }, []);

  const CURRENT_MONTLY_PRICE = Number(
    (7.9 * CURRENCY_MULTIPLIER[currency]).toFixed(2)
  );
  const CURRENT_YEARLY_PRICE = Number(
    (48.0 * CURRENCY_MULTIPLIER[currency]).toFixed(2)
  );
  const CURRENTLY_YEARLY_PRICE_IN_MONTH = Number(
    (CURRENT_YEARLY_PRICE / 12).toFixed(2)
  );

  return (
    <div className="w-[350px] md:w-[380px]  border-hard-400 rounded-3xl border-2 flex flex-col gap-4 p-5">
      <div className="flex w-full justify-between">
        <div className="flex flex-col gap-1 items-center">
          <BeastSVG />
          <div className="font-semibold text-body-600">Beast</div>
        </div>
      </div>
      <div className="w-full text-center mt-4 my-6">
        <div className="flex gap-2 items-end justify-center">
          <div className="text-title-950 text-6xl font-bold">
            {symbol}
            {(isYearly
              ? CURRENTLY_YEARLY_PRICE_IN_MONTH
              : CURRENT_MONTLY_PRICE
            ).toFixed(2)}
          </div>

          {isYearly ? (
            <div className="rounded-lg bg-valid-300 text-subtitle-800 h-7 flex items-center justify-center px-3 text-sm mb-2">
              Saving <span className="font-semibold  ml-1">50%</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="text-body-600">
          per month
          {isYearly ? `, billed at ${symbol}${CURRENT_YEARLY_PRICE}/year` : ""}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="amber" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">3000</span> AI credits / month
            </div>

            <InfoButtonAICredits />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">Full mode</span> PDF generation
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">Export</span> flashcards
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="amber" />
          <div>
            <span className="font-semibold">60</span> hours saved/month
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div>
            <span className="font-semibold">Unlimited</span> spaced repetition
            studying
          </div>
        </div>
        <div className="mt-2">
          <Button
            variant="hard"
            type="submit"
            className="w-full"
            disabled={isLoading || checkoutMutation.isPending}
            onClick={() => checkoutMutation.mutate()}
          >
            {isLoading ? <Spinner /> : <></>}
            <ZapIcon className="mr-1 h-4 w-4" />
            <span>Upgrade</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

const WizardOption = ({
  isYearly,
  currency,
}: {
  isYearly: boolean;
  currency: string;
}) => {
  const [isLoading] = useState(false);
  const [referral] = useState<string | null>(null);
  const symbol = CURRENCIES.find((c) => c.code === currency)?.symbol || "$";

  const checkoutMutation = useMutation({
    mutationFn: async () => {
      const response = await clientRPC.api.checkout.$post({
        json: {
          currency,
          type: "WIZARD",
          isYearly,
          referral: referral || "",
        },
      });

      if (!response.ok) {
        throw new Error("Checkout failed");
      }

      return response.json();
    },
    onSuccess: (data) => {
      // Redirect to Stripe checkout or handle success
      if (data.url) {
        window.location.href = data.url;
      }
    },
  });

  // useEffect(() => {
  //   rewardful("ready", function () {
  //     setReferral(Rewardful.referral);
  //   });
  // }, []);

  const CURRENT_MONTLY_PRICE = Number(
    (11.9 * CURRENCY_MULTIPLIER[currency]).toFixed(2)
  );
  const CURRENT_YEARLY_PRICE = Number(
    (72.0 * CURRENCY_MULTIPLIER[currency]).toFixed(2)
  );
  const CURRENTLY_YEARLY_PRICE_IN_MONTH = Number(
    (CURRENT_YEARLY_PRICE / 12).toFixed(2)
  );

  return (
    <div className="w-[350px] md:w-[380px] border-violet-400 rounded-3xl border-2 flex flex-col gap-4 p-5">
      <div className="flex w-full justify-between">
        <div className="flex flex-col gap-1 items-center">
          <WizardSVG />
          <div className="font-semibold text-body-600">Wizard</div>
        </div>
      </div>
      <div className="w-full text-center mt-4 my-6">
        <div className="flex gap-2 items-end justify-center">
          <div className="text-title-950 text-6xl font-bold">
            {symbol}
            {(isYearly
              ? CURRENTLY_YEARLY_PRICE_IN_MONTH
              : CURRENT_MONTLY_PRICE
            ).toFixed(2)}
          </div>
          {isYearly ? (
            <div className="rounded-lg bg-valid-300 text-subtitle-800 h-7 flex items-center justify-center px-3 text-sm mb-2">
              Saving <span className="font-semibold  ml-1">50%</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="text-body-400">
          per month
          {isYearly ? `, billed at ${symbol}${CURRENT_YEARLY_PRICE}/year` : ""}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">Unlimited</span> AI credits
            </div>

            <InfoButtonAICredits />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">Full mode</span> PDF generation
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div className="flex items-center justify-between gap-2 w-full">
            <div>
              <span className="font-bold">Export</span> flashcards
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div>
            <span className="font-semibold">120</span> hours saved/month
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <CheckIconLevel type="green" />
          <div>
            <span className="font-semibold">Unlimited</span> spaced repetition
            studying
          </div>
        </div>
        <div className="mt-2">
          <Button
            variant="primary"
            type="submit"
            className="w-full h-10 rounded-2xl disabled:bg-secondary-200 font-semibold"
            disabled={isLoading || checkoutMutation.isPending}
            onClick={() => checkoutMutation.mutate()}
          >
            {isLoading ? <Spinner /> : <></>}
            <ZapIcon className="mr-1 h-4 w-4" />
            <span>Upgrade</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SubscriptionOptions = () => {
  const { currency } = useCurrency();
  return (
    <div className="flex items-center justify-center w-full flex-col pb-20">
      <Tabs defaultValue="yearly" className="w-full">
        <div className="flex w-full justify-center">
          <div className="w-full items-center flex justify-center relative flex-col max-w-6xl">
            <TabsList className="grid grid-cols-2 w-[220px] bg-secondary-100 rounded-2xl mb-4">
              <TabsTrigger
                value="yearly"
                className="rounded-xl hover:scale-100 data-[state=active]:font-semibold data-[state=active]:bg-primary-400"
              >
                Yearly
              </TabsTrigger>
              <TabsTrigger
                value="monthly"
                className="rounded-xl hover:scale-100 data-[state=active]:font-semibold data-[state=active]:bg-primary-400"
              >
                Monthly
              </TabsTrigger>
            </TabsList>
            <div className="md:absolute md:top-0 md:right-0 md:mr-4">
              <CurrencySelector />
            </div>
          </div>
        </div>
        <TabsContent value="yearly">
          <div className="w-full flex items-center justify-center gap-5 flex-wrap">
            <FreeOption />
            <BeastOption isYearly currency={currency} />
            <WizardOption isYearly currency={currency} />
          </div>
        </TabsContent>
        <TabsContent value="monthly">
          <div className="w-full flex items-center justify-center gap-5 flex-wrap">
            <FreeOption />
            <BeastOption isYearly={false} currency={currency} />
            <WizardOption isYearly={false} currency={currency} />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export function Countdown({ targetDate }: { targetDate: Date }) {
  const [countdown, setCountdown] = useState<string>("Loading...");

  useEffect(() => {
    // This function calculates the countdown time
    const calculateCountdown = () => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      let days = Math.floor(difference / (1000 * 60 * 60 * 24));
      let hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((difference / 1000 / 60) % 60);
      let seconds = Math.floor((difference / 1000) % 60);

      return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    };

    // Immediately update countdown on client-side mount
    setCountdown(calculateCountdown());

    // Then, set an interval to update the countdown every second
    const interval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [targetDate]);

  return <span className="text-red-500">{countdown}</span>;
}
