import { toast } from "sonner";
import { cn } from "@/lib/utils";
import { CoinsIcon } from "lucide-react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { AICreditDisplay } from "../folder/ai-credits";
import { useEffect, useState } from "react";

export const RewardDialog = ({
  isOpen,
  setIsOpen,
  lottieSrc,
  title,
  rewardDescription,
  noRewardDescription,
  noRewardCta,
  nCreditsEarned,
  onRewardTaskAccept,
  onRewardTaskSkip,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  lottieSrc: string;
  title: React.ReactNode;
  rewardDescription: React.ReactNode;
  noRewardDescription: React.ReactNode;
  noRewardCta: React.ReactNode;
  nCreditsEarned: number;
  onRewardTaskAccept?: () => void;
  onRewardTaskSkip?: () => void;
}) => {
  const [showIncreaseBar, setShowIncreaseBar] = useState(false);

  useEffect(() => {
    if (!showIncreaseBar) {
      const timeout = setTimeout(() => {
        setShowIncreaseBar(true);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showIncreaseBar, setShowIncreaseBar]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onRewardTaskSkip?.();
        }
        setIsOpen(false);
      }}
    >
      <DialogContent className="max-w-2xl lg:max-w-3xl max-h-[100vh - 120px]">
        <div className="pb-20">
          <div className="flex flex-col items-center justify-center gap-6">
            <DotLottieReact autoplay loop src={lottieSrc} />
            <div className="flex flex-col items-center justify-center py-6 gap-4">
              <h2 className="inline text-title-950 text-[28px] font-bold font-['Mona Sans']">
                {title}
              </h2>
              <span className="text-subtitle-800 text-base font-medium font-['Mona Sans'] break-words">
                {nCreditsEarned > 0 ? rewardDescription : noRewardDescription}
              </span>
            </div>
          </div>
        </div>
        <div className="flex gap-3 w-full items-end py-6 absolute bottom-0 left-0 right-0 px-6">
          <Button
            variant="secondary"
            size="lg"
            className="flex-1"
            onClick={() => {
              onRewardTaskSkip?.();
              setIsOpen(false);
            }}
          >
            Not Now
          </Button>
          <div className="flex w-full flex-col flex-1">
            <AICreditDisplay
              isCollapsed={false}
              isShowWhenPremium={false}
              showUpgradeButton={false}
              creditsIncrease={showIncreaseBar ? nCreditsEarned : 0}
            />
            <Button
              variant="primary"
              size="lg"
              className={cn("flex-1")}
              onClick={() => {
                onRewardTaskAccept?.();
                setIsOpen(false);
              }}
            >
              {nCreditsEarned > 0 ? (
                <>
                  {" "}
                  <CoinsIcon />{" "}
                  <span>
                    Earn <b>+{nCreditsEarned} Credits</b>
                  </span>
                </>
              ) : (
                <>{noRewardCta}</>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export function triggerRewardToast(rewardCredits: number) {
  toast.success(
    <div className="flex items-center text-subtitle-800 group gap-1 bg-valid-100">
      <div
        className={cn(
          "h-6 w-6 border-2  rounded-md flex items-center justify-center mr-1",
          "border-valid-400 bg-amber-50 text-valid-950"
        )}
      >
        <CoinsIcon className="h-icon-sm w-icon-sm text-valid-950" />
      </div>
      You have earned <b>+{rewardCredits}</b> credits
    </div>,
    {
      position: "bottom-left",
      closeButton: true,
    }
  );
}
