import React from "react";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@clerk/react-router";
import { useMutation } from "@tanstack/react-query";
import {
  HelpCircleIcon,
  LinkIcon,
  MessageCircleIcon,
  SquareArrowOutUpRight,
  XIcon,
} from "lucide-react";
import { Link } from "react-router";
import { useState } from "react";
import { Spinner } from "../add/utils";
import { clientRPC } from "@/utils/hono-client";
import { usePostHog } from "posthog-js/react";

export const LeaveFeedbackDialog = ({
  isCollapsed,
}: {
  isCollapsed?: boolean;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { userId } = useAuth();
  const { toast } = useToast();
  const [feedback, setFeedback] = useState("");
  const posthog = usePostHog();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (newFeedback: string) => {
      return clientRPC.api["feedback"].$post({
        json: { feedback: newFeedback },
      });
    },
    onSuccess: () => {
      toast({
        title: `Thank you for your feedback! Successfully submitted.`,
        variant: "default",
      });
      setDialogOpen(false);
    },
    onError: () => {
      toast({
        title: `Something went wrong. Please report the issue on Discord.`,
        variant: "destructive",
      });
    },
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    posthog.capture("Sidebar-Submit-Feedback");
    mutate(`Sidebar\nUser: ${userId}\n${feedback}`);
  };

  return (
    <>
      <Tooltip open={isCollapsed && undefined}>
        <TooltipTrigger asChild>
          <Button
            variant="strokeSecondarySidebar"
            size={isCollapsed ? "iconSm" : "sm"}
            onClick={() => setDialogOpen(true)}
            className="justify-start text-xs"
          >
            <MessageCircleIcon className="w-icon-md h-icon-md" />
            {isCollapsed ? "" : "Feedback"}
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">Feedback</TooltipContent>
      </Tooltip>
      <Dialog open={dialogOpen} onOpenChange={(open) => setDialogOpen(open)}>
        <DialogContent className="sm:max-w-[900px]">
          <p className="font-semibold">
            Thank you for considering leaving us a feedback!
          </p>

          <form onSubmit={onSubmit}>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Your feedback"
              className="w-full h-32 p-2 border-2"
            />
            <p className="font-semibold">
              Encountering an issue? Email us at stefandjokovic@flashka.ai !
            </p>

            <div className="flex justify-end gap-2">
              <Button type="submit" disabled={isLoading} variant="primary">
                {isLoading ? <Spinner /> : "Submit"}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const HelpDialog = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  return (
    <Tooltip open={isCollapsed && undefined}>
      <TooltipTrigger asChild>
        <Link to="/help">
          <Button
            variant="strokeSecondarySidebar"
            className="text-xs"
            size={isCollapsed ? "iconSm" : "sm"}
          >
            <HelpCircleIcon className="h-icon-md w-icon-md" />
            {isCollapsed ? "" : "Help"}
          </Button>
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">Help</TooltipContent>
    </Tooltip>
  );
};

export const ShareWithFriendsDialog = ({
  isCollapsed,
}: {
  isCollapsed?: boolean;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { toast } = useToast();
  const posthog = usePostHog();

  const handleCopyLink = () => {
    posthog.capture("Sidebar-Share-With-Friend-Link-Copied");
    navigator.clipboard
      .writeText("https://www.flashka.ai")
      .then(() => {
        toast({
          title: "Link copied to clipboard!",
          variant: "default",
        });
        setDialogOpen(false);
      })
      .catch(() => {
        toast({
          title: "Failed to copy link. Please try again.",
          variant: "destructive",
        });
      });
  };

  return (
    <>
      <Tooltip open={isCollapsed && undefined}>
        <TooltipTrigger asChild>
          <Button
            variant="strokeSecondarySidebar"
            size={isCollapsed ? "iconSm" : "sm"}
            onClick={() => {
              setDialogOpen(true);
              posthog.capture("Sidebar-Share-With-Friend-Open-Dialog");
            }}
            className="justify-start text-xs"
          >
            <SquareArrowOutUpRight className="h-icon-md w-icon-md" />
            {isCollapsed ? "" : "Share with a friend"}
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">Share</TooltipContent>
      </Tooltip>
      <Dialog open={dialogOpen} onOpenChange={(open) => setDialogOpen(open)}>
        <DialogContent className="sm:max-w-[500px]">
          <p className="font-semibold text-2xl text-center">
            Share Flashka with your friends 📚
          </p>
          <p className="text-center">
            Share Flashka with your study buddy or group and study together!
          </p>
          <div className="w-full flex items-center justify-center gap-2">
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => setDialogOpen(false)}
            >
              <XIcon className="mr-1" /> Cancel
            </Button>
            <Button
              className="w-full"
              variant="primary"
              onClick={handleCopyLink}
            >
              <LinkIcon className="mr-1" /> Copy Link
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export function AllDecksIcon({ className = "" }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      role="img"
      aria-label="View all decks"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 562">
        <g id="Group 445">
          <g id="Vector">
            <path
              d="M3 6.62963L3 15.7037C3 16.4196 3.58037 17 4.2963 17L13.3704 17C14.0863 17 14.6667 16.4196 14.6667 15.7037L14.6667 6.62963C14.6667 5.91371 14.0863 5.33333 13.3704 5.33333L4.2963 5.33333C3.58037 5.33333 3 5.91371 3 6.62963Z"
              fill="#F5F5F5"
            />
            <path d="M3 11.1667L14.6667 11.1667L3 11.1667Z" fill="#F5F5F5" />
            <path
              d="M3 11.1667L14.6667 11.1667M3 15.7037L3 6.62963C3 5.91371 3.58037 5.33333 4.2963 5.33333L13.3704 5.33333C14.0863 5.33333 14.6667 5.91371 14.6667 6.62963L14.6667 15.7037C14.6667 16.4196 14.0863 17 13.3704 17L4.2963 17C3.58037 17 3 16.4196 3 15.7037Z"
              stroke="currentColor"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g id="Vector_2">
            <path
              d="M5.33398 4.29662L5.33398 13.3707C5.33398 14.0866 5.91436 14.667 6.63028 14.667L15.7044 14.667C16.4203 14.667 17.0006 14.0866 17.0006 13.3707L17.0006 4.29662C17.0006 3.5807 16.4203 3.00033 15.7044 3.00033L6.63028 3.00033C5.91436 3.00033 5.33398 3.5807 5.33398 4.29662Z"
              fill="#F5F5F5"
            />
            <path
              d="M5.33398 8.83366L17.0006 8.83366L5.33398 8.83366Z"
              fill="#F5F5F5"
            />
            <path
              d="M5.33398 8.83366L17.0006 8.83366M5.33398 13.3707L5.33398 4.29662C5.33398 3.5807 5.91436 3.00033 6.63028 3.00033L15.7044 3.00033C16.4203 3.00033 17.0006 3.5807 17.0006 4.29662L17.0006 13.3707C17.0006 14.0866 16.4203 14.667 15.7044 14.667L6.63028 14.667C5.91436 14.667 5.33398 14.0866 5.33398 13.3707Z"
              stroke="currentColor"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
