import { useLocalStorage } from "../hooks/use-local-storage";

import { useMutation, useQuery } from "@tanstack/react-query";

import { ReactNode, useEffect, useState } from "react";

import { Input } from "@/components/ui/input";

import { Button } from "@/components/ui/button";
import { InstagramLogoIcon } from "@radix-ui/react-icons";
import { useAuth } from "@clerk/react-router";
import { useToast } from "@/components/ui/use-toast";
import {
  ChevronLeft,
  CircleEllipsis,
  ContactIcon,
  GraduationCapIcon,
  SparklesIcon,
  Wand2Icon,
  YoutubeIcon,
} from "lucide-react";

import {
  COUNTRY_LIST,
  FIELDS_OF_STUDY,
  LANGUAGE_LIST,
  MED_UNIVERSITIES,
} from "./utils";
import { TypewriterEffectSmooth } from "./typewriter";
import { GoogleIcon, RedditIcon } from "../icons/google";
import { TikTokIcon } from "../icons/tiktok";
import { cn } from "@/lib/utils";

import { useTheme } from "@/settings/theme";
import { useMediaQuery } from "usehooks-ts";
import { ComboBox } from "@/components/ui/combobox";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { hc } from "hono/client";
import { AppType } from "api";
import { clientRPC } from "@/utils/hono-client";
import { usePostHog } from "posthog-js/react";

function calculateBarStyle(currStep: number, barPosZeroIndexed: number) {
  if (currStep >= barPosZeroIndexed + 1) {
    return "bg-primary-300 border border-primary-500";
  } else {
    return "bg-secondary-100";
  }
}

const ONBOARDING_STEPS = Object.freeze([
  "welcome",
  "heardAboutUs",
  "aboutYou",
  "language",
] as const);

type OnboardingStep = (typeof ONBOARDING_STEPS)[number];

const CONTINUE_BUTTON_CONTENT_BY_STEP: Record<string, ReactNode> =
  Object.freeze({
    welcome: (
      <>
        <Wand2Icon className="w-icon-md h-icon-md flex-none" />
        <span>Let's get started!</span>
      </>
    ),
    language: (
      <>
        <SparklesIcon className="w-icon-md h-icon-md flex-none" />
        <span>Jump In!</span>
      </>
    ),
  });

const ALL_ORIGINS = {
  "friends-family": {
    icon: <ContactIcon />,
    text: "Friends / Family",
  },
  "professor-school": {
    icon: <GraduationCapIcon />,
    text: "Professor / School",
  },
  google: {
    icon: <GoogleIcon />,
    text: "Search Engine",
  },
  tiktok: {
    icon: <TikTokIcon />,
    text: "TikTok",
  },
  reddit: {
    icon: <RedditIcon />,
    text: "Reddit",
  },
  instagram: {
    icon: <InstagramLogoIcon className="h-icon-md w-icon-md" />,
    text: "Instagram",
  },
  youtube: {
    icon: <YoutubeIcon />,
    text: "YouTube",
  },
  other: {
    icon: <CircleEllipsis />,
    text: "Other",
  },
} as const;

type UserOrigin = keyof typeof ALL_ORIGINS;

function canAdvanceToNextStep(currStep: OnboardingStep, userInfo?: UserInfo) {
  if (currStep === "welcome") {
    return true;
  } else if (currStep === "heardAboutUs") {
    return false;
  } else if (currStep === "aboutYou") {
    return !!(
      userInfo &&
      userInfo.country.length > 0 &&
      userInfo.role.length > 0 &&
      userInfo.school.length > 0 &&
      userInfo.fieldOfStudy.length > 0
    );
  } else if (currStep === "language") {
    return !!(
      userInfo &&
      userInfo.country.length > 0 &&
      userInfo.role.length > 0 &&
      userInfo.school.length > 0 &&
      userInfo.fieldOfStudy.length > 0 &&
      userInfo.language.length > 0
    );
  }
  return false;
}

function flashkaToLottieTheme(string: string) {
  if (!string) return "Default";
  if (string === "light") return "Default";
  if (string === "dark") return "Dark";
  else return "Default";
}

export const OnboardingSteps = () => {
  const { toast } = useToast();
  const posthog = usePostHog();
  const { userId } = useAuth();
  const [origin, setOrigin] = useState<UserOrigin | undefined>(undefined);

  const [step, setStep] = useState<OnboardingStep>("welcome");

  const [userInfo, setUserInfo] = useState<UserInfo>({
    country: "",
    role: "",
    school: "",
    language: "",
    fieldOfStudy: "",
  });

  const [, setLocalStorage] = useLocalStorage<Record<string, UserInfo>>(
    "user-info",
    {}
  );

  const prevStep = () => {
    const index = ONBOARDING_STEPS.indexOf(step);
    if (index > 0) {
      setStep(ONBOARDING_STEPS[index - 1]);
    }
  };

  const nextStep = () => {
    const index = ONBOARDING_STEPS.indexOf(step);
    if (index < ONBOARDING_STEPS.length - 1) {
      setStep(ONBOARDING_STEPS[index + 1]);
    }
  };

  const { mutate: uploadUserInfo } = useMutation({
    mutationFn: async (info: {
      country: string;
      profession: string;
      school: string;
      language: string;
      fieldOfStudy: string;
    }) => {
      posthog.capture("onboarding-set-user-info", {
        $set: {
          country: info.country,
          profession: info.profession,
          schoolOrJob: info.school,
          fieldOfStudy: info.fieldOfStudy,
          langauge: info.language,
        },
      });
      const res = await clientRPC.api["user-info"].$post({
        json: info,
      });

      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      toast({ title: "Info updated! Enjoy Flashka" });

      // return "updated";
    },
  });

  return (
    <div className="h-full md:h-[calc(100vh-96px)] flex flex-col items-center justify-center">
      <div className="flex gap-4 items-center flex-col bg-secondary-50 justify-start w-full h-full md:h-fit min-h-[70dvh] md:max-w-5xl p-4 md:p-14 border-2 border-secondary-100 rounded-3xl text-center">
        <TopBar
          onBackClicked={prevStep}
          backDisabled={step === "welcome" || step === "heardAboutUs"}
          currStep={ONBOARDING_STEPS.indexOf(step)}
          maxSteps={3}
        />
        {step === "welcome" ? <WelcomeStep /> : <></>}
        {step === "heardAboutUs" ? (
          <SelectOrigin
            confirm={(opt) => {
              setOrigin(opt);
              posthog.capture("onboarding-set-heard-about-us", {
                $set: { heardAboutUs: opt },
              });
              nextStep();
            }}
          />
        ) : (
          <></>
        )}
        {step === "aboutYou" ? (
          <FullUserInfo
            confirm={(opt) => {
              setUserInfo(opt);
            }}
            key={origin}
          />
        ) : (
          <></>
        )}
        {step === "language" ? (
          <SelectLanguage
            confirm={(language) =>
              setUserInfo((original) => {
                return { ...original, language: language };
              })
            }
          />
        ) : (
          <></>
        )}
        <Button
          variant="primary"
          className={cn(
            "max-w-md w-full flex-none",
            step === "heardAboutUs" ? "hidden md:flex" : ""
          )}
          disabled={!canAdvanceToNextStep(step, userInfo)}
          onClick={() => {
            if (step === ONBOARDING_STEPS[ONBOARDING_STEPS.length - 1]) {
              uploadUserInfo({
                country: userInfo.country,
                profession: userInfo.role,
                school: userInfo.school,
                fieldOfStudy: userInfo.fieldOfStudy,
                language: userInfo.language,
              });
              const user = userId as string;
              posthog.capture("onboarding-finish");
              setLocalStorage((previous) => {
                return {
                  ...previous,
                  ...{ [user]: { ...userInfo, role: userInfo.role } },
                };
              });
            } else {
              nextStep();
            }
          }}
        >
          {CONTINUE_BUTTON_CONTENT_BY_STEP[step] ?? <span>Continue</span>}
        </Button>
      </div>
    </div>
  );
};

export const useUserInfoCollected = () => {
  const [localStorage] = useLocalStorage<Record<string, UserInfo>>(
    "user-info",
    {}
  );

  if (Object.keys(localStorage).length) {
    return true;
  }

  return false;
};
export type UserInfo = {
  role: string;
  school: string;
  country: string;
  language: string;
  fieldOfStudy: string;
};
const client = hc<AppType>(
  import.meta.env.PROD ? "/" : "http://localhost:5173"
);
export const useUserInfoUploaded = () => {
  const { userId } = useAuth();
  const [localStorage, setLocalStorage] = useLocalStorage<
    Record<string, UserInfo>
  >("user-info", {});
  const { data, isLoading, isError } = useQuery({
    queryFn: async () => {
      const res = await client.api["user-info"].$get();
      const data = (await res.json()) as { data: UserInfo; success: boolean };
      return data.data;
    },
    queryKey: ["user-info"],
    gcTime: Infinity,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (userId && data) {
      if (!(userId in localStorage)) {
        setLocalStorage({ ...localStorage, ...{ [userId]: data } });
      } else {
        if (localStorage[userId].school !== data.school) {
          setLocalStorage({ ...localStorage, ...{ [userId]: data } });
        }
      }
    }
  }, [userId, data]);

  if (!userId) {
    return undefined;
  }

  if (userId in localStorage) {
    return localStorage[userId];
  }

  if (isLoading || isError) {
    return undefined;
  }

  if (!data) {
    return false;
  }

  return data;
};

export const KaWritingTextBubble = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => {
  const { theme } = useTheme();
  const lottieTheme = flashkaToLottieTheme(theme);

  const md = useMediaQuery("(min-width: 768px)"); // md: '768px'

  return (
    <div
      className={cn(
        "flex flex-row items-center justify-start w-full md:w-fit border rounded-lg md:border-0 border-secondary-300 p-1.5 pe-3 md:p-0",
        className
      )}
    >
      <div className="w-10 h-10 md:h-24 md:w-24 p-0.5 md:p-0 border md:border-0 rounded-lg border-secondary-300 overflow-hidden flex-none">
        <div
          className="transform md:transform-none origin-center flex-none"
          style={{ transform: md ? "none" : "scale(1.25) translateY(6px)" }}
        >
          <DotLottieReact
            src="https://lottie.host/49dd20a8-301a-4a0f-b0bb-e032f4605f4d/ua3XnQZ9lw.lottie"
            loop
            autoplay
            themeId={lottieTheme}
            width={60}
            height={60}
          />
        </div>
      </div>
      <div className="flex flex-col items-start border-0 md:border border-secondary-300 rounded-xl rounded-bl-lg  p-1.5 pe-3 md:py-2 md:px-4 gap-1 md:gap-2">
        <span className="text-secondary-500 text-sm">Professor Ka</span>
        <span className="text-title-950 text-sm md:text-base text-left">
          <TypewriterEffectSmooth
            words={text.split(" ").map((word) => {
              return { text: word };
            })}
          />
        </span>
      </div>
    </div>
  );
};

export const TopBar = ({
  onBackClicked,
  backDisabled = false,
  currStep,
  maxSteps,
}: {
  onBackClicked?: () => void;
  backDisabled?: boolean;
  currStep: number;
  maxSteps: number;
}) => {
  return (
    <div className="flex flex-row gap-2 w-full items-center">
      <Button
        variant="secondary"
        disabled={backDisabled}
        size="md"
        className="flex-none"
        onClick={onBackClicked}
      >
        <ChevronLeft className="h-icon-m w-icon-md flex-none" />
        <span>Back</span>
      </Button>
      {[...Array(maxSteps).keys()].map((barPosZeroIndexed) => (
        <div
          key={barPosZeroIndexed + 1}
          className={cn(
            "h-2 flex-1 rounded-full",
            calculateBarStyle(currStep, barPosZeroIndexed)
          )}
        ></div>
      ))}
    </div>
  );
};

export const WelcomeStep = () => {
  const { theme } = useTheme();
  const lottieTheme = flashkaToLottieTheme(theme);
  const text = `Welcome, I’m Professor Ka!
Let me ask you couple of question to improve your experience✨`;

  return (
    <div className="flex flex-col-reverse md:flex-col flex-grow items-center max-w-md gap-5 md:justify-center justify-between h-full md:h-fit">
      <div className="block md:flex flex-row items-start justify-center">
        <div className="flex-none">
          <DotLottieReact
            src="https://lottie.host/ff6956dd-8611-4fff-ba9a-996cffc749ab/jqpWJIFIPS.lottie"
            loop
            autoplay
            themeId={lottieTheme}
            style={{ width: "256px", height: "256px" }}
          />
        </div>
        <div className="flex flex-col items-start border border-secondary-300 rounded-xl rounded-bl-lg  px-3 md:px-4 py-2 gap-2 flex-shrink">
          <span className="text-secondary-500 text-sm">Professor Ka</span>{" "}
          <span className="text-title-950 text-sm md:text-base text-left">
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

function SelectOrigin({ confirm }: { confirm: (opt: UserOrigin) => void }) {
  return (
    <>
      <KaWritingTextBubble
        text={"How did you hear about Flashka?"}
        className="self-start flex-none"
      />
      <div className="flex flex-col items-center justify-around flex-grow w-full mt-2 md:mt-10">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2 sm:gap-6 max-w-lg">
          {Object.entries(ALL_ORIGINS).map(([originKey, originData]) => {
            return (
              <Button
                variant="secondary"
                size="xl"
                onClick={() => confirm(originKey as UserOrigin)}
                className="justify-start"
                key={`origin-${originKey}`}
              >
                {originData.icon}
                <span>{originData.text}</span>
              </Button>
            );
          })}
        </div>
        <div className="flex-grow"></div>
      </div>
    </>
  );
}

function FullUserInfo({ confirm }: { confirm: (opt: UserInfo) => void }) {
  const [role, setRole] = useState<"student" | "teacher" | "other" | "">("");
  const [country, setCountry] = useState("");
  const [school, setSchool] = useState("");
  const [fieldOfStudy, setFieldOfStudy] = useState("");

  const hasHardcodedUniversityDropdown =
    fieldOfStudy === "medicine" && country in MED_UNIVERSITIES;

  useEffect(() => {
    setSchool("");
  }, [
    hasHardcodedUniversityDropdown,
    hasHardcodedUniversityDropdown && country,
    hasHardcodedUniversityDropdown && fieldOfStudy,
  ]);

  return (
    <>
      <KaWritingTextBubble
        text="Awesome, tell me more about yourself... "
        className="self-start flex-none"
      />
      <div className="flex flex-col items-center justify-around flex-grow w-full mt-6 sm:mt-4">
        <div className="flex flex-col gap-6 max-w-sm md:max-w-md items-start">
          <div className="flex flex-col gap-1 w-full">
            <h3 className="px-2 self-start text-secondary-500 text-sm font-bold">
              Country
            </h3>
            <ComboBox
              value={country}
              onChange={(val) => {
                confirm({
                  country: val ?? "",
                  role,
                  school,
                  fieldOfStudy,
                  language: "",
                });
                setCountry(val ?? "");
              }}
              options={Object.fromEntries(
                COUNTRY_LIST.map((country) => {
                  return [country, country];
                })
              )}
              notFoundText="No country found"
              searchText="Search country"
              selectText="Select country"
            />
          </div>

          <div className="flex flex-col gap-1 w-full">
            <h3 className="px-2 self-start text-secondary-500 text-sm font-bold">
              Role
            </h3>
            <div className="flex gap-2">
              <Button
                variant={role === "student" ? "primary" : "secondary"}
                onClick={() => {
                  confirm({
                    country,
                    role: "student",
                    school,
                    fieldOfStudy,
                    language: "",
                  });
                  setRole("student");
                }}
              >
                📚 Student
              </Button>
              <Button
                variant={role === "teacher" ? "primary" : "secondary"}
                onClick={() => {
                  confirm({
                    country,
                    role: "teacher",
                    school,
                    fieldOfStudy,
                    language: "",
                  });
                  setRole("teacher");
                }}
              >
                🧑‍🏫 Teacher
              </Button>
              <Button
                variant={role === "other" ? "primary" : "secondary"}
                onClick={() => {
                  confirm({
                    country,
                    role: "other",
                    school,
                    fieldOfStudy,
                    language: "",
                  });
                  setRole("other");
                }}
              >
                🧑‍💼 Other
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-1 w-full">
            <h3 className="px-2 self-start text-secondary-500 text-sm font-bold">
              Field of Study
            </h3>
            <ComboBox
              value={fieldOfStudy}
              onChange={(val) => {
                confirm({
                  country: val ?? "",
                  role,
                  school,
                  fieldOfStudy,
                  language: "",
                });
                setFieldOfStudy(val ?? "");
              }}
              options={Object.fromEntries(
                FIELDS_OF_STUDY.map(({ key, display }) => [key, display])
              )}
              notFoundText="No field found"
              searchText="Search Field of Study"
              selectText="Select Field of Study"
            />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <h3 className="px-2 self-start text-secondary-500 text-sm font-bold">
              University / School / Profession
            </h3>
            {hasHardcodedUniversityDropdown ? (
              <ComboBox
                value={school}
                onChange={(val) => {
                  confirm({
                    country,
                    role,
                    school: val ?? "",
                    fieldOfStudy,
                    language: "",
                  });
                  setSchool(val ?? "");
                }}
                options={Object.fromEntries([
                  ...MED_UNIVERSITIES[
                    country as keyof typeof MED_UNIVERSITIES
                  ].map((uni) => [uni, uni]),
                  ["Other", "Other"],
                ])}
                notFoundText="No school found"
                searchText="Search School of Study"
                selectText="Select School of Study"
              />
            ) : (
              <Input
                className="w-full"
                value={school}
                onChange={(e) => {
                  confirm({
                    country,
                    role,
                    school: e.target.value,
                    fieldOfStudy,
                    language: "",
                  });
                  setSchool(e.target.value);
                }}
                placeholder={"e.g. Harvard, Hogwarts, ..."}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function SelectLanguage({ confirm }: { confirm: (opt: string) => void }) {
  const [language, setLanguage] = useState(LANGUAGE_LIST[0]);

  useEffect(() => confirm(LANGUAGE_LIST[0]), []);

  return (
    <>
      <KaWritingTextBubble
        text={"Tell me what language should I speak 🌎"}
        className="self-start flex-none"
      />
      <div className="flex flex-col items-center justify-around flex-grow w-full mt-6 sm:mt-4">
        <div className="flex flex-col gap-3 w-full max-w-md">
          <h3 className="px-2 self-start text-subtitle-800 text-sm font-bold hidden md:block">
            Language of Professor Ka
          </h3>

          <ComboBox
            value={language}
            onChange={(val) => {
              confirm(val ?? "");
              setLanguage(val ?? "");
            }}
            options={Object.fromEntries(
              LANGUAGE_LIST.map((language) => {
                return [language, language];
              })
            )}
            notFoundText="No language found"
            searchText="Search language"
            selectText="Select language"
          />
          <div className="font-sm text-secondary-500 flex jusify-center items-center ">
            <span className="text-center text-xs">
              This will set the language of every AI generated content, not the
              language of the platform
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
