import { Button } from "@/components/ui/button";

import { SubscriptionOptions } from "./components";
import { ChevronLeftIcon } from "lucide-react";
import { useNavigate } from "react-router";

const UpgradePage = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <Button
        variant="secondary"
        size="md"
        className="md:absolute top-6 left-6 m-2 md:m-0 text-neutral-600"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="h-icon-md w-icon-md" /> <span>Back</span>
      </Button>

      <CheckoutForm />
    </div>
  );
};

export default UpgradePage;

function CheckoutForm() {
  return (
    <div className="flex items-center justify-center w-full pt-6 flex-col gap-1">
      <div className="text-center text-4xl font-bold">
        Unlock your <span className="text-primary-500">full potential 🥇</span>
      </div>
      <div className="font-medium max-w-xl text-center text-base mb-4 md:mb-4">
        Forget endless flashcard writing. Focus on learning while the AI does
        the rest for you. Upgrade Flashka now and start studying effectively!
      </div>
      <SubscriptionOptions />
    </div>
  );
}
