import { CardProps } from "../memo/study-session";

export const shouldStudyCardFromTime = (
  dateStr: string,
  days: number
): boolean => {
  // Create date object from the input string
  let inputDate = new Date(dateStr);

  // Decrease the hours by 4
  inputDate.setHours(inputDate.getHours() - 4);

  // Create a new date object for the 4am of the adjusted day
  let adjustedDate = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate(),
    4,
    0,
    0,
    0
  );

  // Add the number of days to the adjusted date
  adjustedDate.setDate(adjustedDate.getDate() + days);

  // Get the current date and time
  const currentDateTime = new Date();

  // Return true if the adjusted date is less than the current date and time
  return adjustedDate.getTime() < currentDateTime.getTime();
};

export const isDateToday = (dateStr: string): boolean => {
  // Create date object from the input string
  let inputDate = new Date(dateStr);

  // Get the current date
  const currentDate = new Date();

  // Check if the input date is today
  return (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  );
};

export const isCardStudiedToday = (card: CardProps): boolean => {
  const goodActionsSlice = getLastSliceGood(card.actions);

  if (!goodActionsSlice || goodActionsSlice.length === 0) {
    return false; // return false if there are no good actions
  }
  const lastAction = goodActionsSlice[goodActionsSlice.length - 1];
  return isDateToday(lastAction.ISO); // check if the last action was today
};

export const getLastStudiedTimestamp = (cards: CardProps[]): Date | null => {
  let lastStudiedTimestamp: string | null = null;

  for (const card of cards) {
    const actions = card.actions;

    if (actions.length > 0) {
      const lastAction = actions[actions.length - 1];

      const lastActionISO = lastAction.good?.ISO || lastAction.bad?.ISO;

      if (lastActionISO) {
        if (!lastStudiedTimestamp || lastActionISO > lastStudiedTimestamp) {
          lastStudiedTimestamp = lastActionISO;
        }
      }
    }
  }

  return lastStudiedTimestamp ? new Date(lastStudiedTimestamp) : null;
};

export const shouldStudyCard = (card: CardProps) => {
  const goodActionsSlice = getLastSliceGood(card.actions);

  if (!goodActionsSlice || goodActionsSlice.length === 0) {
    return true;
  }
  const lastAction = goodActionsSlice[goodActionsSlice.length - 1];
  return shouldStudyCardFromTime(lastAction.ISO, getDays(card.actions));
};

export const getLastSliceGood = (actions: CardProps["actions"]) => {
  let hasGood = false;
  let lastActionSlice = [];
  for (const action of actions) {
    if (action.bad) {
      lastActionSlice = [];
      continue;
    }

    if (action.good) {
      lastActionSlice.push(action.good);
      hasGood = true;
      continue;
    }
  }

  return hasGood ? lastActionSlice : undefined;
};

export const getDays = (actions: CardProps["actions"]) => {
  const goodActionsSlice = getLastSliceGood(actions);

  if (!goodActionsSlice || goodActionsSlice.length === 0) {
    return 0;
  }

  let containsBad = false;
  let countGood = 0;
  for (const action of actions) {
    if (action.good) {
      countGood += 1;
    }
    if (action.bad) {
      containsBad = true;
    }
  }

  if (!containsBad || goodActionsSlice.length === countGood) {
    return numGoodActions[goodActionsSlice.length];
  }

  return numGoodActions[goodActionsSlice.length + 1];
};

export const numGoodActions: Record<number, number> = {
  0: 0,
  1: 1,
  2: 3,
  3: 7,
  4: 16,
  5: 35,
  6: 70,
  7: 200,
  8: 200,
};

export const countCardsStudiedToReview = (localCards: CardProps[]) => {
  let count = 0;
  for (const card of localCards) {
    const goodActionsSlice = getLastSliceGood(card.actions);

    if (!goodActionsSlice) {
      continue;
    }

    if (shouldStudyCard(card)) {
      count += 1;
    }
  }

  return count;
};

export const countCardsToReview = (
  localCards: CardProps[],
  multiply: boolean,
  force?: boolean
) => {
  const indexTerminator = localCards.findIndex(
    (card) => card.front === "Thank you for trying text-to-deck generation!"
  );
  const sliceSize =
    indexTerminator === -1 ? localCards.length : indexTerminator;

  let count = 0;
  for (const card of localCards.slice(0, sliceSize)) {
    const goodActionsSlice = getLastSliceGood(card.actions);

    if (!goodActionsSlice) {
      if (multiply) {
        count += 2;
      } else {
        count += 1;
      }
      continue;
    }

    if (force || shouldStudyCard(card)) {
      count += 1;
    }
  }

  return count;
};

export const countNewCards = (localCards: CardProps[]) => {
  let count = 0;
  for (const card of localCards) {
    if (card.actions.length === 0) {
      count += 1;
    }
  }

  return count;
};

export const countCardsReviewedToday = (localCards: CardProps[]) => {
  let count = 0;
  for (const card of localCards) {
    if (isCardStudiedToday(card)) {
      count += 1;
    }
  }

  return count;
};

// Util funciton that gives me the cards that have been reviewed today for the first time
export const countCardsReviewedTodayForTheFirstTime = (
  localCards: CardProps[]
) => {
  let count = 0;
  for (const card of localCards) {
    const firstAction = card.actions[0];

    if (!firstAction) {
      continue;
    }

    const timestamp = firstAction.bad?.ISO || firstAction.good?.ISO;

    if (timestamp && isDateToday(timestamp)) {
      count += 1;
    }
  }

  return count;
};

export const isCardDeleted = (card: CardProps) => {
  if (card.deletedTimeISO) {
    return true;
  }

  const actions = card.actions;
  for (const action of actions) {
    if (action.delete) {
      return true;
    }
  }

  return false;
};

export type FlashcardLabelType = "new" | "to-review" | "again" | "in-memory";
const getCardLabelType = (card: CardProps) => {
  const isStudied = !shouldStudyCard(card);

  if (isStudied) {
    return "in-memory";
  }

  if (card.actions.length === 0) {
    return "new";
  }

  if (card.actions[card.actions.length - 1].bad) {
    return "again";
  }

  return "to-review";
};

export const countUtils = {
  countNewCards,
  countCardsToReview,
  countCardsReviewedToday,
  countCardsReviewedTodayForTheFirstTime,
  getLastStudiedTimestamp,
  countCardsStudiedToReview,
  isCardDeleted,
  getCardLabelType,
};
