import { useState } from "react";
import { Check, ChevronDown } from "lucide-react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useCurrency } from "@/contexts/currency-context";
import { CURRENCIES } from "@/utils/pricing";

export default function CurrencySelector() {
  const { currency: selectedCurrency, setCurrency } = useCurrency();

  const [open, setOpen] = useState(false);
  const selected =
    CURRENCIES.find((c) => c.code === selectedCurrency) || CURRENCIES[0];

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors">
        <div className="flex items-center gap-2">
          <span className="text-xl">{selected.flag}</span>
          <span className="font-medium">{selected.code.toUpperCase()}</span>
        </div>
        <ChevronDown
          className={`w-4 h-4 transition-transform ${open ? "rotate-180" : ""}`}
        />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="bg-white rounded-lg shadow-lg border border-gray-200 w-[220px] animate-in fade-in-0 zoom-in-95 z-[200]"
          align="start"
          style={{ zIndex: 200 }}
        >
          <div className="py-1">
            {CURRENCIES.map((currency) => (
              <DropdownMenu.Item
                key={currency.code}
                className="flex items-center justify-between px-3 py-2 hover:bg-gray-50 cursor-pointer outline-none"
                onSelect={() => setCurrency(currency.code)}
              >
                <div className="flex items-center gap-2">
                  <span className="text-xl">{currency.flag}</span>
                  <span>{currency.name}</span>
                </div>
                {currency.code === selectedCurrency && (
                  <Check className="w-4 h-4 text-blue-600" />
                )}
              </DropdownMenu.Item>
            ))}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
