import { createContext, useContext, useState, ReactNode } from "react";
import { CURRENCIES } from "../utils/pricing";

type CurrencyContextType = {
  currency: (typeof CURRENCIES)[number]["code"];
  setCurrency: (currency: (typeof CURRENCIES)[number]["code"]) => void;
};

const CurrencyContext = createContext<CurrencyContextType | undefined>(
  undefined
);

export function CurrencyProvider({ children }: { children: ReactNode }) {
  const [currency, setCurrency] = useState("usd");

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
}

export function useCurrency() {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error("useCurrency must be used within a CurrencyProvider");
  }
  return context;
}
