import { RewardAction } from "@/lib/kv-utils/utils";
import { clientRPC } from "@/utils/hono-client";

export const requestReward = async (rewardType: RewardAction) => {
  const response = await clientRPC.api["rewards"].$post({
    json: {
      rewardType,
    },
  });

  if (!response.ok) {
    throw new Error("Reward not available");
  }

  const data = await response.json();

  return {
    success: data.success,
    rewardType: data.reward,
    creditsAwarded: data.creditsAwarded,
  };
};

export const getAvailableRewards = async () => {
  const response = await clientRPC.api["rewards"].$get();

  if (!response.ok) {
    throw new Error("Error fetching Rewards");
  }

  const data = await response.json();

  return { availableRewards: data.availableRewards } as {
    availableRewards: Partial<Record<RewardAction, { credits: number }>>;
  };
};
