const colorMap = {
  orange: `deck-orange`,
  amber: `deck-amber`,
  lime: `deck-lime`,
  green: `deck-green`,
  emerald: `deck-emerald`,
  teal: `deck-teal`,
  cyan: `deck-cyan`,
  sky: `deck-sky`,
  blue: `deck-blue`,
  fuchsia: `deck-fuchsia`,
  pink: `deck-pink`,
  rose: `deck-rose`,
  // Remap legacy color to the closest ones
  violet: `deck-fuchsia`,
  yellow: `deck-amber`,
};

// These classes need to exist somewhere for Tailwind to detect them
export const _unusedClasses = [
  "hover:bg-deck-orange",
  "hover:bg-deck-amber",
  "hover:bg-deck-lime",
  "hover:bg-deck-green",
  "hover:bg-deck-emerald",
  "hover:bg-deck-teal",
  "hover:bg-deck-cyan",
  "hover:bg-deck-sky",
  "hover:bg-deck-blue",
  "hover:bg-deck-fuchsia",
  "hover:bg-deck-pink",
  "hover:bg-deck-rose",
  "hover:bg-deck-orange-100",
  "hover:bg-deck-amber-100",
  "hover:bg-deck-lime-100",
  "hover:bg-deck-green-100",
  "hover:bg-deck-emerald-100",
  "hover:bg-deck-teal-100",
  "hover:bg-deck-cyan-100",
  "hover:bg-deck-sky-100",
  "hover:bg-deck-blue-100",
  "hover:bg-deck-fuchsia-100",
  "hover:bg-deck-pink-100",
  "hover:bg-deck-rose-100",
  "active:bg-deck-orange",
  "active:bg-deck-amber",
  "active:bg-deck-lime",
  "active:bg-deck-green",
  "active:bg-deck-emerald",
  "active:bg-deck-teal",
  "active:bg-deck-cyan",
  "active:bg-deck-sky",
  "active:bg-deck-blue",
  "active:bg-deck-fuchsia",
  "active:bg-deck-pink",
  "active:bg-deck-rose",
  "active:bg-deck-orange-100",
  "active:bg-deck-amber-100",
  "active:bg-deck-lime-100",
  "active:bg-deck-green-100",
  "active:bg-deck-emerald-100",
  "active:bg-deck-teal-100",
  "active:bg-deck-cyan-100",
  "active:bg-deck-sky-100",
  "active:bg-deck-blue-100",
  "active:bg-deck-fuchsia-100",
  "active:bg-deck-pink-100",
  "active:bg-deck-rose-100",
  "focus:bg-deck-orange",
  "focus:bg-deck-amber",
  "focus:bg-deck-lime",
  "focus:bg-deck-green",
  "focus:bg-deck-emerald",
  "focus:bg-deck-teal",
  "focus:bg-deck-cyan",
  "focus:bg-deck-sky",
  "focus:bg-deck-blue",
  "focus:bg-deck-fuchsia",
  "focus:bg-deck-pink",
  "focus:bg-deck-rose",
  "focus:bg-deck-orange-100",
  "focus:bg-deck-amber-100",
  "focus:bg-deck-lime-100",
  "focus:bg-deck-green-100",
  "focus:bg-deck-emerald-100",
  "focus:bg-deck-teal-100",
  "focus:bg-deck-cyan-100",
  "focus:bg-deck-sky-100",
  "focus:bg-deck-blue-100",
  "focus:bg-deck-fuchsia-100",
  "focus:bg-deck-pink-100",
  "focus:bg-deck-rose-100",
  "bg-deck-orange",
  "bg-deck-amber",
  "bg-deck-lime",
  "bg-deck-green",
  "bg-deck-emerald",
  "bg-deck-teal",
  "bg-deck-cyan",
  "bg-deck-sky",
  "bg-deck-blue",
  "bg-deck-fuchsia",
  "bg-deck-pink",
  "bg-deck-rose",
  "bg-deck-orange-100",
  "bg-deck-amber-100",
  "bg-deck-lime-100",
  "bg-deck-green-100",
  "bg-deck-emerald-100",
  "bg-deck-teal-100",
  "bg-deck-cyan-100",
  "bg-deck-sky-100",
  "bg-deck-blue-100",
  "bg-deck-fuchsia-100",
  "bg-deck-pink-100",
  "bg-deck-rose-100",
  "border-deck-orange",
  "border-deck-amber",
  "border-deck-lime",
  "border-deck-green",
  "border-deck-emerald",
  "border-deck-teal",
  "border-deck-cyan",
  "border-deck-sky",
  "border-deck-blue",
  "border-deck-fuchsia",
  "border-deck-pink",
  "border-deck-rose",
  "hover:border-deck-orange",
  "hover:border-deck-amber",
  "hover:border-deck-lime",
  "hover:border-deck-green",
  "hover:border-deck-emerald",
  "hover:border-deck-teal",
  "hover:border-deck-cyan",
  "hover:border-deck-sky",
  "hover:border-deck-blue",
  "hover:border-deck-fuchsia",
  "hover:border-deck-pink",
  "hover:border-deck-rose",
  "border-secondary-100",
  "border-secondary-400",
  "hover:border-secondary-100",
  "hover:border-secondary-400",
  "bg-secondary-100",
  "bg-secondary-400",
  "hover:bg-secondary-100",
  "hover:bg-secondary-400",
];

export function getCustomizedColor(
  color: string | undefined | null,
  modifiers: {
    pseudoClass?: "hover" | "active" | "focus";
    weight?: 100;
  } = {},
  type: "bg" | "border" = "bg"
) {
  if (!color) {
    let colorClass = `${type}-secondary`;
    if (modifiers.pseudoClass) {
      colorClass = colorClass.replace(
        `${type}-`,
        `${modifiers.pseudoClass}:${type}-`
      );
    }

    if (modifiers.weight === 100) {
      colorClass = colorClass + "-100";
    } else {
      colorClass = colorClass + "-400";
    }
    return colorClass;
  }

  const baseColor = colorMap[color as keyof typeof colorMap]
    ? `${type}-${colorMap[color as keyof typeof colorMap]}`
    : `${type}-secondary-400`;

  let colorClass = baseColor;

  if (modifiers.pseudoClass) {
    colorClass = colorClass.replace(
      `${type}-`,
      `${modifiers.pseudoClass}:${type}-`
    );
  }

  if (modifiers.weight === 100) {
    colorClass = colorClass + "-100";
  }

  return colorClass;
}
