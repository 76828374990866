import { LocalDeck, useCanTakeActions } from "@/lib/decks-utils/utils";
import { useAuth } from "@clerk/react-router";
import { useEffect, useState } from "react";

import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Folder, localDB } from "@/database/db";

import { ArrowUpDown, EditIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { useLocation, useSearchParams } from "react-router";
import { TooltipWithShortcuts } from "@/components/tooltips";
import { getCustomizedColor } from "../folder/folder-color-utils";
import { Spinner } from "../add/utils";
import { usePostHog } from "posthog-js/react";

export const COLOR_CLASSES = {
  violet: "bg-violet-400 text-violet-950 border-violet-950",
  yellow: "bg-yellow-400 text-yellow-950 border-yellow-950",
  orange: "bg-orange-400 text-orange-950 border-orange-950",
  blue: "bg-blue-400 text-blue-950 border-blue-950",
  sky: "bg-sky-400 text-sky-950 border-sky-950",
  teal: "bg-teal-400 text-teal-950 border-teal-950",
  green: "bg-green-400 text-green-950 border-green-950",
  lime: "bg-lime-400 text-lime-950 border-lime-950",
};

export const COLORS = [
  "orange",
  "amber",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "fuchsia",
  "pink",
  "rose",
  "gray", // Special color for decks without folders!
] as const;

export const EditDialog = ({ deck }: { deck: LocalDeck }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);

  return (
    <>
      <Button
        size="md"
        variant="secondary"
        onClick={() => setShowEditDialog(true)}
      >
        <EditIcon className="w-icon-sm h-icon-sm" />
        <span className="hidden md:flex">Edit</span>
      </Button>
      {showEditDialog ? (
        <DeckEditingDialog
          deckToEdit={deck}
          close={() => setShowEditDialog(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const MAX_SIZE_THUMBAILTEXT = 8;
export const DeckEditingDialog = ({
  deckToEdit,
  close,
}: {
  deckToEdit?: LocalDeck;
  close: () => void;
}) => {
  const canTakeAction = useCanTakeActions();
  const { userId } = useAuth();
  const posthog = usePostHog();

  return (
    <Dialog open={!!deckToEdit} onOpenChange={() => close()}>
      {deckToEdit ? (
        <DialogContent className="max-w-[388px]">
          <div className="text-lg font-semibold">Edit deck</div>

          <DeckEditingForm
            disabled={!canTakeAction}
            initalThumbnailText={
              deckToEdit.thumbnailText ??
              (deckToEdit.displayName?.slice(0, 8) || "")
            }
            initialName={deckToEdit ? deckToEdit.displayName || "" : ""}
            onSubmit={async (values) => {
              await localDB.updateDeckProperties({
                name: deckToEdit.name,
                displayName: values.displayName,
                // imageBackground: values.imageBackground,
                color: values.color,
                thumbnailText: values.thumbnailText,
                languageSettings: deckToEdit.languageSettings,
                folder: deckToEdit.folder,
              });
              posthog.capture("Deck-Edit-Properties-Confirm", {
                userId: userId || "not-loaded",
                deckName: deckToEdit.name,
              });
              close();
            }}
            initColor={deckToEdit?.color || COLORS[0]}
            confirmLabel={"Save Changes"}
          />
        </DialogContent>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export const MoveDeckFolderDialog = ({
  deckToMove,
  folders,
  close,
}: {
  deckToMove?: LocalDeck;
  folders: Folder[];
  close: () => void;
}) => {
  const canTakeAction = useCanTakeActions();
  const [selectedFolder, setSelectedFolder] = useState(
    deckToMove?.folder || ""
  );
  const colorSelected = "bg-neutral-200 hover:bg-neutral-300";

  return (
    <Dialog open={!!deckToMove} onOpenChange={() => close()}>
      {deckToMove ? (
        <DialogContent className="max-w-[388px] gap-0">
          <DialogHeader className="text-lg font-semibold flex gap-1 flex-row -mt-5 items-center pb-2">
            <ArrowUpDown className="h-4 w-4 mt-[6px] mr-1" />
            <div>Move to another folder</div>
          </DialogHeader>
          <div className="-ml-10 w-[120%] border border-neutral-200"></div>
          {folders.length ? (
            <div className="-ml-10 w-[120%]">
              {folders.map((folder) => {
                return (
                  <Button
                    key={folder.id}
                    className={cn(
                      "flex gap-1 items-center w-full justify-start border-t-0 border-neutral-200",
                      selectedFolder === folder.id ? colorSelected : ""
                    )}
                    variant="strokeSecondary"
                    onClick={() => setSelectedFolder(folder.id)}
                  >
                    <div
                      className={cn(
                        "h-6 w-6 rounded-full mr-1 ml-4",
                        getCustomizedColor(folder.color)
                      )}
                    ></div>
                    <div>{folder.name}</div>
                  </Button>
                );
              })}
            </div>
          ) : (
            <div className="mt-2">
              Looks like you have no folders yet
              <br />
              Create them from the menu on the left.
            </div>
          )}
          {folders.length ? (
            <div className="w-full mt-2 flex justify-center">
              <Button
                variant="primary"
                className=""
                disabled={
                  (deckToMove.folder || "") === selectedFolder || !canTakeAction
                }
                onClick={async () => {
                  await localDB.updateDeckProperties({
                    name: deckToMove.name,
                    displayName: deckToMove.displayName,
                    color: deckToMove.color,
                    thumbnailText: deckToMove.thumbnailText,
                    folder: selectedFolder,
                    languageSettings: deckToMove.languageSettings,
                  });

                  close();
                }}
              >
                Move
              </Button>
            </div>
          ) : (
            <></>
          )}
        </DialogContent>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export const DeckEditingForm = ({
  initialName,
  initalThumbnailText,
  initColor,
  disabled,
  onSubmit,
  onChange,
  isLoading,
  confirmLabel,
}: {
  initialName: string;
  initalThumbnailText: string;
  initColor?: (typeof COLORS)[number];
  disabled: boolean;
  onSubmit: (value: {
    displayName: string;
    color: (typeof COLORS)[number];
    thumbnailText: string;
  }) => void;
  onChange?: (value: {
    displayName: string;
    color: (typeof COLORS)[number];
    thumbnailText: string;
  }) => void;
  isLoading?: boolean;
  confirmLabel: string;
}) => {
  const [newName, setNewName] = useState(initialName);
  const [thumbnailText, setThumbnailText] = useState(initalThumbnailText);
  const pathname = useLocation().pathname;
  // Exception: on mobile we don't show tag personalization in the /add page to reduce confusion
  const stylingCoverTag = pathname === "/add" ? "hidden md:flex" : "flex";

  const [selectedColor, setSelectedColor] = useState<(typeof COLORS)[number]>(
    initColor || COLORS[0]
  );

  useEffect(() => {
    if (onChange) {
      onChange({
        displayName: newName,
        color: selectedColor,
        thumbnailText: thumbnailText,
      });
    }
  }, [newName, thumbnailText, selectedColor]);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        onSubmit({
          displayName: newName,
          color: selectedColor,
          thumbnailText: thumbnailText,
        });
      }}
      className="max-w-[410px] flex flex-col gap-8"
    >
      <div className="flex flex-col gap-2">
        <Label htmlFor="name" className="font-semibold">
          Name
        </Label>
        <Input
          id="name"
          value={newName}
          onChange={(e) => {
            setNewName(e.target.value);
          }}
          placeholder="My new Deck! ⚡️"
          className="max-w-md text-base"
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="thumbnail" className="font-semibold">
          Color
        </Label>
        <div className="grid grid-cols-6 gap-3">
          {COLORS.slice(0, -1).map((color, index) => (
            <TooltipWithShortcuts
              key={index}
              text={color}
              className="capitalize"
            >
              <div
                key={index}
                className={cn(
                  `w-8 h-8 rounded-xl cursor-pointer`,
                  getCustomizedColor(color),
                  selectedColor === color ? `border-2 border-secondary-700` : ""
                )}
                onClick={() => {
                  setSelectedColor(color);
                }}
              />
            </TooltipWithShortcuts>
          ))}
        </div>
      </div>
      <div className={cn("flex flex-col gap-2", stylingCoverTag)}>
        <Label htmlFor="customization" className="font-semibold">
          Cover tag
        </Label>
        <Input
          id="customization"
          value={thumbnailText}
          onChange={(e) => {
            setThumbnailText(e.target.value.slice(0, MAX_SIZE_THUMBAILTEXT));
          }}
          className="max-w-md"
          maxLength={MAX_SIZE_THUMBAILTEXT}
          placeholder="⚡️ Deck"
        />
        <p className="text-sm text-gray-500 text-right font-semibold">
          {thumbnailText.length}/{MAX_SIZE_THUMBAILTEXT}
        </p>
      </div>
      <div className="w-full flex justify-end">
        <Button
          type="submit"
          disabled={disabled || isLoading}
          variant="primary"
        >
          {isLoading ? <Spinner /> : <></>} {confirmLabel}
        </Button>
      </div>
    </form>
  );
};

export type DeckCreationEditingFormProps = {
  displayName: string;
  color: (typeof COLORS)[number];
  thumbnailText: string;
  folder: string;
};
export const DeckCreationEditingForm = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: (params: DeckCreationEditingFormProps) => void;
  isLoading: boolean;
}) => {
  const canTakeAction = useCanTakeActions();

  const [colorValue, setColorValue] = useState<(typeof COLORS)[number]>(
    COLORS[0]
  );
  const [displayName, setDisplayName] = useState<string>("");
  const [thumbnailText, setThumbnailText] = useState<string>("");
  const [params] = useSearchParams();
  const folderId = params.get("folder") as string | null;

  return (
    <div className="space-y-4 w-full">
      <div className="flex gap-2 md:gap-16 md:flex-row flex-col items-center md:items-start justify-center">
        <div
          className={cn(
            `hidden md:block w-64 h-64 relative border-2 border-secondary-200 transition-all rounded-3xl overflow-hidden group`,
            getCustomizedColor(colorValue)
          )}
        >
          <div className="md:h-40 w-full flex items-center justify-center text-title-950 text-4xl font-bold">
            {thumbnailText}
          </div>
          <div className="md:h-24 bg-secondary-50 text-subtitle-800 w-full p-3 flex flex-col gap-3 px-4">
            <div className="whitespace-nowrap">{displayName}</div>
          </div>
        </div>

        <DeckEditingForm
          disabled={!canTakeAction || !displayName}
          initalThumbnailText={thumbnailText}
          initialName={displayName}
          onSubmit={() =>
            onSubmit({
              color: colorValue,
              displayName: displayName,
              thumbnailText: thumbnailText,
              folder: folderId || "",
            })
          }
          onChange={(value) => {
            setColorValue(value.color);
            setThumbnailText(value.thumbnailText);
            setDisplayName(value.displayName);
          }}
          initColor={colorValue}
          isLoading={isLoading}
          confirmLabel={"Create Deck"}
        />
      </div>
    </div>
  );
};
