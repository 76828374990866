import { useCallback, useEffect, useState } from "react";
import { useOnboardingEvents } from "./onboarding-events";
import { RewardDialog, triggerRewardToast } from "./reward-components";
import { getAvailableRewards, requestReward } from "./requests";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getPosthogHandleForRewardAction,
  RewardAction,
} from "@/lib/kv-utils/utils";
import { produce } from "immer";
import { useNavigate } from "react-router";
import {
  triggerCreatedFlashcards,
  triggerDeckCreatedToast,
  triggerStudiedFlashcards,
} from "./onboarding-toasts";
import { usePostHog } from "posthog-js/react";

function nextRewardAction(reward: RewardAction): RewardAction | null {
  if (reward === "study-flashcard-reward") {
    return "do-quiz-reward";
  }
  if (reward === "do-quiz-reward") {
    return "share-deck-reward";
  }
  return null;
}

export function acceptTaskAction(
  navigate: ReturnType<typeof useNavigate>,
  action: RewardAction,
  deckName: string | undefined
) {
  if (action === "do-quiz-reward") {
    if (deckName) {
      navigate(`/quiz/${encodeURIComponent(deckName)}`);
    }
  } else if (action === "study-flashcard-reward") {
    if (deckName) {
      navigate(`/memo-ai?deck=${encodeURIComponent(deckName)}`);
    }
  } else if (action === "share-deck-reward") {
    if (deckName) {
      navigate(`/deck/${encodeURIComponent(deckName)}`);
    }
  }
}

function useTaskAndRewardManager(
  deckName: string | undefined,
  quizFinished: boolean,
  deckShared: boolean
) {
  const queryClient = useQueryClient();
  const posthog = usePostHog();
  const [showRewardDialog, setShowRewardDialog] = useState<RewardAction | null>(
    null
  );

  const { data: rewards, isSuccess } = useQuery({
    queryKey: ["available-rewards"],
    queryFn: async () => {
      const data = await getAvailableRewards();

      return data;
    },
  });

  const showRewardToastAndNextRewardDialog = useCallback(
    (creditsAwarded: number, rewardAction: RewardAction) => {
      if (creditsAwarded > 0) {
        triggerRewardToast(creditsAwarded);

        const nextReward = nextRewardAction(rewardAction);
        const availableRewards = rewards?.availableRewards;

        if (
          nextReward &&
          availableRewards &&
          (availableRewards[nextReward]?.credits ?? 0) > 0
        ) {
          setTimeout(() => setShowRewardDialog(nextReward), 1000);
        }
      }
    },
    [nextRewardAction, rewards, setShowRewardDialog]
  );

  const { mutate: requestRewardMutation } = useMutation({
    mutationKey: ["request-reward"],

    mutationFn: async (reward: RewardAction) => {
      const { creditsAwarded } = await requestReward(reward);

      return creditsAwarded;
    },
    onSuccess: async (creditsAwarded, rewardAction) => {
      if (creditsAwarded > 0) {
        posthog.capture(getPosthogHandleForRewardAction(rewardAction), {
          creditsAwarded,
        });
      }
    },
    onMutate: async (reward: RewardAction) => {
      await queryClient.cancelQueries({ queryKey: ["available-rewards"] });

      const previousRewards = queryClient.getQueryData<
        Awaited<ReturnType<typeof getAvailableRewards>>
      >(["available-rewards"]);

      queryClient.setQueryData<Awaited<ReturnType<typeof getAvailableRewards>>>(
        ["available-rewards"],
        (old) => {
          return old
            ? produce(old, (draft) => {
                delete draft.availableRewards[reward];
              })
            : undefined;
        }
      );

      return { previousRewards };
    },
    onError: async (_, __, context) => {
      queryClient.setQueryData<Awaited<ReturnType<typeof getAvailableRewards>>>(
        ["available-rewards"],
        context?.previousRewards
      );
    },
    onSettled: () => {
      // When the update arrives refetch usage (to update credit view) and the rewards
      queryClient.invalidateQueries({ queryKey: ["user-usage"] });
      queryClient.invalidateQueries({ queryKey: ["available-rewards"] });
    },
  });

  // Check that a quiz is finished and its reward is still available
  useEffect(() => {
    if (
      quizFinished &&
      isSuccess &&
      (rewards.availableRewards["do-quiz-reward"]?.credits ?? 0) > 0
    ) {
      requestRewardMutation("do-quiz-reward", {
        onSuccess: showRewardToastAndNextRewardDialog,
      });
    }
  }, [
    quizFinished,
    isSuccess,
    rewards,
    requestRewardMutation,
    showRewardToastAndNextRewardDialog,
  ]);

  // Check that a deck was shared is available and its reward is still available
  useEffect(() => {
    if (
      deckShared &&
      isSuccess &&
      (rewards.availableRewards["share-deck-reward"]?.credits ?? 0) > 0
    ) {
      requestRewardMutation("share-deck-reward", {
        onSuccess: showRewardToastAndNextRewardDialog,
      });
    }
  }, [
    deckShared,
    isSuccess,
    rewards,
    requestRewardMutation,
    showRewardToastAndNextRewardDialog,
  ]);

  useOnboardingEvents({
    onDeckCreated() {
      posthog.capture("Onboarding-Checklist-show-deck-created");
      triggerDeckCreatedToast();
    },
    onFlashcardCreated() {
      // Show the invitation to study the first 10 cards anyways
      posthog.capture("Onboarding-Checklist-show-flashcards-created");
      triggerCreatedFlashcards({ path: deckName || "", posthog });
    },
    onFlashcardCreatedToday() {
      posthog.capture("Onboarding-Checklist-10-cards-created-today");
      if ((rewards?.availableRewards["share-deck-reward"]?.credits ?? 0) > 0) {
        setShowRewardDialog("study-flashcard-reward");
      }
    },
    onFlashcardStudied() {
      posthog.capture("Onboarding-Checklist-show-flashcards-studied");
      triggerStudiedFlashcards({ deckName: deckName || "", posthog });
    },
    onFlashcardStudiedToday() {
      posthog.capture("Onboarding-Checklist-10-cards-studied-today");
      requestRewardMutation("study-flashcard-reward", {
        onSuccess: showRewardToastAndNextRewardDialog,
      });
    },
  });

  return {
    showRewardDialog,
    setShowRewardDialog,
    rewardsLoaded: isSuccess,
    availableRewards: rewards,
  };
}

export function TasksAndRewardsManager({
  deckName,
  quizFinished = false,
  deckShared = false,
}: {
  deckName?: string;
  quizFinished?: boolean;
  deckShared?: boolean;
}) {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const {
    showRewardDialog,
    setShowRewardDialog,
    availableRewards: rewards,
    rewardsLoaded,
  } = useTaskAndRewardManager(deckName, quizFinished, deckShared);

  if (!rewardsLoaded || !rewards) {
    return <></>;
  }

  const { availableRewards } = rewards;

  return (
    <>
      <RewardDialog
        isOpen={false} // DISABLED WITH NEW FLOW GENERATION //showRewardDialog === "study-flashcard-reward"}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            setShowRewardDialog("study-flashcard-reward");
          } else {
            setShowRewardDialog(null);
          }
        }}
        lottieSrc="https://lottie.host/83fff7f4-ae07-485e-9ad9-de026ba5441f/CrC5MTTy9V.lottie"
        title="Time to study! 🧠"
        rewardDescription={
          "You’ve already generated 10 cards.\nStudy them to earn 15 credits."
        }
        noRewardDescription={
          "You’ve already generated 10 cards.\nNow it's time to study!"
        }
        noRewardCta={"Go to study"}
        nCreditsEarned={
          availableRewards["study-flashcard-reward"]?.credits ?? 0
        }
        onRewardTaskAccept={() => {
          posthog.capture("Reward-Task-accept-study-flashcard");
          acceptTaskAction(navigate, "study-flashcard-reward", deckName);
        }}
        onRewardTaskSkip={() =>
          posthog.capture("Reward-Task-skip-study-flashcard")
        }
      />
      <RewardDialog
        isOpen={showRewardDialog === "do-quiz-reward"}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            setShowRewardDialog("do-quiz-reward");
          } else {
            setShowRewardDialog(null);
          }
        }}
        lottieSrc="https://lottie.host/02ad726a-86ba-40b6-aa06-b180962b2ae6/nponNVA4oa.lottie"
        title="Ready for a test? ☑️"
        rewardDescription={
          "You’ve studied 10 cards!\nTest yourself with a quiz to earn 15 credits!"
        }
        noRewardDescription={
          "You’ve studied 10 cards!\nTest yourself with a quiz!"
        }
        noRewardCta={"Go to quizzes"}
        nCreditsEarned={availableRewards["do-quiz-reward"]?.credits ?? 0}
        onRewardTaskAccept={() => {
          posthog.capture("Reward-Task-accept-do-quiz");
          acceptTaskAction(navigate, "do-quiz-reward", deckName);
        }}
        onRewardTaskSkip={() => posthog.capture("Reward-Task-skip-do-quiz")}
      />
      <RewardDialog
        isOpen={showRewardDialog === "share-deck-reward"}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            setShowRewardDialog("share-deck-reward");
          } else {
            setShowRewardDialog(null);
          }
        }}
        lottieSrc="https://lottie.host/080e1933-f534-4e3e-b468-3368d4d5f028/2S3O0pmdUP.lottie"
        title="Sharing is caring 👯"
        rewardDescription={
          "Studying is more fun with a friend! Share your material to earn 20 credits!"
        }
        noRewardDescription={
          "Studying is more fun with a friend! Share your material to spread the knowledge!"
        }
        noRewardCta={"Share a deck"}
        nCreditsEarned={availableRewards["share-deck-reward"]?.credits ?? 0}
        onRewardTaskAccept={() => {
          posthog.capture("Reward-Task-accept-share-deck");
          acceptTaskAction(navigate, "share-deck-reward", deckName);
        }}
        onRewardTaskSkip={() => posthog.capture("Reward-Task-skip-share-deck")}
      />
    </>
  );
}
