import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { clientRPC } from "@/utils/hono-client";
import { useUser } from "@clerk/react-router";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addDays,
  addMonths,
  intervalToDuration,
  Duration,
  endOfMonth,
  differenceInMinutes,
  endOfDay,
} from "date-fns";
import { LucideCoins, ZapIcon } from "lucide-react";
import { Link } from "react-router";

export const fetchUsage = async (): Promise<{ data: number }> => {
  const response = await clientRPC.api["user-usage"].$get();
  if (!response.ok) {
    throw new Error("Failed to fetch usage");
  }
  return response.json();
};

export function getMissingTimeToCreditsRenewal(isBeast: boolean) {
  const now = new Date();
  const utcNow = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  );

  let nextRenewal;

  if (isBeast) {
    nextRenewal = addMonths(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth()),
      1
    );
  } else {
    nextRenewal = addDays(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()),
      1
    );
  }

  const duration = intervalToDuration({ start: utcNow, end: nextRenewal });

  return duration;
}

export function formatDurationRemainingToCreditsRenewal(
  duration: Duration,
  numberOfUnits: number
) {
  const unitOrder = ["years", "months", "days", "hours", "minutes"] as const;

  let unitCount = 0;
  let outputString = "";

  for (const unit of unitOrder) {
    if ((duration[unit] ?? 0) > 0) {
      unitCount += 1;
      outputString = outputString.concat(`${duration[unit]}${unit[0]}`);
    }
    if (unitCount >= numberOfUnits) {
      break;
    } else {
      outputString = outputString.concat(" ");
    }
  }
  return outputString;
}

function timeProgressPercentToCreditsRenewal(isBeast: boolean) {
  const now = new Date();
  const utcNow = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  );

  if (isBeast) {
    const endMonth = endOfMonth(utcNow);

    const minutesInMonth = endMonth.getDay() * 24 * 60;
    const minutesRemaining = differenceInMinutes(endMonth, utcNow);

    return ((minutesInMonth - minutesRemaining) / minutesInMonth) * 100;
  } else {
    const endDay = endOfDay(utcNow);
    const minutesInDay = 24 * 60;

    const minutesRemaining = differenceInMinutes(endDay, utcNow);
    return ((minutesInDay - minutesRemaining) / minutesInDay) * 100;
  }
}

export const AICreditDisplay = ({
  isCollapsed,
  isShowWhenPremium,
  showUpgradeButton = true,
  creditsIncrease = 0,
}: {
  isCollapsed: boolean;
  isShowWhenPremium: boolean;
  showUpgradeButton?: boolean;
  creditsIncrease?: number;
}) => {
  const userInfo = useUser();
  const isVeteran = userInfo.user?.publicMetadata["status"] === "veteran";
  const isMaster = userInfo.user?.publicMetadata["status"] === "master";
  const isWizard = userInfo.user?.publicMetadata["status"] === "wizard";
  const isBeast = userInfo.user?.publicMetadata["status"] === "beast";

  if (!isShowWhenPremium && (isVeteran || isMaster || isBeast || isWizard)) {
    return <></>;
  }

  if (!userInfo.isSignedIn || !userInfo.isLoaded) {
    return <></>;
  }

  return (
    <DisplayAICredits
      isCollapsed={isCollapsed}
      userInfo={userInfo}
      creditsIncrease={creditsIncrease}
      showUpgradeButton={showUpgradeButton}
    />
  );
};

const DisplayAICredits = ({
  isCollapsed,
  userInfo,
  showUpgradeButton = true,
  creditsIncrease = 0,
}: {
  isCollapsed: boolean;
  userInfo: ReturnType<typeof useUser>;
  showUpgradeButton?: boolean;
  creditsIncrease?: number;
}) => {
  const { user, isLoaded, isSignedIn } = userInfo;

  const { data, isError, isLoading } = useQuery({
    queryKey: ["user-usage"],
    queryFn: fetchUsage,
    staleTime: 5 * 60 * 1000,
    gcTime: 5 * 60 * 1000,
  });

  const isUnlimited =
    user?.publicMetadata["status"] === "wizard" ||
    user?.publicMetadata["status"] === "veteran" ||
    user?.publicMetadata["status"] === "master";
  const isPremium = user?.publicMetadata["status"] === "beast";
  const totalCredits = isPremium ? 3000 : 50;

  if (!isSignedIn) {
    return <></>;
  }

  if (isError) {
    return <div>Error loading credits</div>;
  }

  if (!isLoaded) {
    return <></>;
  }

  if (isUnlimited) {
    if (isCollapsed) {
      return <></>;
    }
    return (
      <div className="w-full flex flex-col gap-2">
        <div className="flex justify-between items-center text-body-600 pt-2">
          <div className="flex items-center gap-1 font-medium">
            <LucideCoins className="mr-1 h-5 w-5" />
            Unlimited AI credits
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="w-full flex flex-col gap-2">
        {isCollapsed ? (
          <div className="flex flex-col items-center justify-center my-2 text-subtitle-800">
            <LucideCoins className="mr-1 h-5 w-5" />
            <div>
              <div className="text-sm text-right flex items-center justify-center mt-1">
                <span className="font-semibold">
                  <Skeleton className="h-3 w-4" />
                </span>
                /{totalCredits}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center text-subtitle-800 pt-2">
              <div className="flex items-center gap-1 font-medium">
                <LucideCoins className="mr-1 h-5 w-5" />
                {!isPremium ? "Daily " : ""}AI credits
              </div>
              <div className="text-sm text-right flex items-center">
                <Skeleton className="w-[30px] h-3 mr-1" />/ {totalCredits}
              </div>
            </div>
            <div className="w-full bg-secondary-200 rounded-xl h-2 mb-4">
              <Skeleton className="h-full w-full" />
            </div>
          </>
        )}
        {!isPremium && showUpgradeButton ? (
          <Link to="/upgrade">
            <Button variant="primary" size={isCollapsed ? "iconMd" : "md"}>
              <ZapIcon className={cn("h-icon-md w-icon-md")} />
              {isCollapsed ? "" : "Upgrade"}
            </Button>
          </Link>
        ) : (
          <></>
        )}
      </div>
    );
  }

  const remainingCredits =
    totalCredits - (data?.data ?? 0) >= 0
      ? totalCredits - (data?.data ?? 0)
      : 0;

  const increasedCredits = remainingCredits + creditsIncrease;

  const creditsIncreaseExceedsTotalCredits =
    remainingCredits <= totalCredits && increasedCredits > totalCredits;

  const usagePercentage = Math.max(
    0,
    Math.min(100, (remainingCredits / totalCredits) * 100)
  );
  const usageIncreasedPercentage = Math.max(
    0,
    Math.min(100, ((remainingCredits + creditsIncrease) / totalCredits) * 100)
  );
  const supplementaryUsagePercentage = Math.max(
    0,
    Math.min(100, (remainingCredits / totalCredits - 1) * 100)
  );
  const supplementaryIncreasedUsagePercentage = Math.max(
    0,
    creditsIncreaseExceedsTotalCredits
      ? Math.min(100, (creditsIncrease / totalCredits) * 100)
      : 0,
    Math.min(
      100,
      ((remainingCredits + creditsIncrease) / totalCredits - 1) * 100
    )
  );

  const progressUntilCreditsRenew =
    timeProgressPercentToCreditsRenewal(isPremium);

  return (
    <div className="w-full flex flex-col gap-2 text-body-600">
      {!isCollapsed ? (
        <div className="flex justify-between items-center text-body-600 pt-2">
          <div className="flex items-center gap-1 font-medium">
            <LucideCoins className="mr-1 h-5 w-5" />
            {!isPremium ? "Daily " : "Monthly "}AI credits
          </div>
          <div className="text-sm text-right">
            {remainingCredits > 0 ? (
              <>
                <span className="text-sm font-semibold">
                  {remainingCredits}
                </span>
                /{totalCredits}
              </>
            ) : (
              <span className="text-sm font-semibold">
                {formatDurationRemainingToCreditsRenewal(
                  getMissingTimeToCreditsRenewal(isPremium),
                  2
                )}
              </span>
            )}
          </div>
        </div>
      ) : isPremium ? (
        <></>
      ) : (
        <div className="flex flex-col items-center justify-center my-2">
          <LucideCoins className="mr-1 h-5 w-5 text-subtitle-800" />
          <div>
            {remainingCredits > 0 ? (
              <div className="text-sm">
                <span className="font-semibold">{remainingCredits}</span>/
                {totalCredits}
              </div>
            ) : (
              <span className="text-sm font-semibold">
                {formatDurationRemainingToCreditsRenewal(
                  getMissingTimeToCreditsRenewal(isPremium),
                  1
                )}
              </span>
            )}
          </div>
        </div>
      )}
      {isCollapsed ? (
        <></>
      ) : (
        <div className="w-full bg-secondary-200 rounded-xl h-2 mb-4 relative">
          {remainingCredits <= 0 ? (
            <div
              className="absolute top-0 left-0 bg-secondary-300 h-full rounded-xl transition-all duration-500 ease-in-out"
              style={{ width: `${progressUntilCreditsRenew}%` }}
            />
          ) : (
            <></>
          )}
          {!creditsIncreaseExceedsTotalCredits ? (
            <div
              className="absolute top-0 left-0 bg-primary-700 h-full rounded-xl transition-all duration-500 ease-in-out"
              style={{ width: `${usageIncreasedPercentage}%` }}
            />
          ) : (
            <></>
          )}
          <div
            className={cn(
              "absolute top-0 left-0 bg-primary-400 h-full rounded-xl transition-all duration-500 ease-in-out"
            )}
            style={{
              width: `${
                creditsIncreaseExceedsTotalCredits ? 100 : usagePercentage
              }%`,
            }}
          />
          <div
            className="absolute top-0 left-0 bg-primary-700 h-full rounded-xl transition-all duration-500 ease-in-out"
            style={{ width: `${supplementaryIncreasedUsagePercentage}%` }}
          />
          <div
            className="absolute top-0 left-0 bg-primary-500 h-full rounded-xl transition-all duration-500 ease-in-out"
            style={{ width: `${supplementaryUsagePercentage}%` }}
          />
        </div>
      )}
      {!isPremium && showUpgradeButton ? (
        <Link to="/upgrade" className="w-full">
          <Button
            variant="primary"
            size={isCollapsed ? "iconMd" : "md"}
            className={cn(isCollapsed ? "" : "w-full")}
          >
            <ZapIcon
              className={cn("h-icon-md w-icon-md", isCollapsed ? "" : "mr-1")}
            />
            {isCollapsed ? "" : "Upgrade"}
          </Button>
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
};

export const useAICredits = () => {
  const queryClient = useQueryClient();

  const updateCredits = (newUsage: number) => {
    queryClient.setQueryData(["user-usage"], { data: newUsage });
  };

  const reduceCredits = (usedCredits: number) => {
    queryClient.setQueryData(
      ["user-usage"],
      (oldData: { data: number } | undefined) => {
        if (!oldData) {
          return undefined;
        }

        return { data: oldData.data + usedCredits };
      }
    );
  };

  return {
    updateCredits,
    reduceCredits,
    refetchCredits: () =>
      queryClient.invalidateQueries({ queryKey: ["user-usage"] }),
  };
};
