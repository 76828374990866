import {
  SetValue,
  useLocalStorage,
} from "@/components/hooks/use-local-storage";
import { CardProps } from "@/components/memo/study-session";
import { useDebounceSync } from "@/components/memo/sync-utils";
import { COLORS } from "@/components/personalization/deck-editing";
import { formatDeckName } from "@/components/utils/formatter";
import { countUtils } from "@/components/utils/study-utils";
import { Deck, Folder, localDB } from "@/database/db";
import { localDBUtils } from "@/database/db-utils";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@clerk/react-router";
import { useQuery } from "@tanstack/react-query";
import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useMemo, useState } from "react";
import { migrateCardFromLegacyToAnki } from "../spaced-repetition/migrations";
import { SpacedRepetitionSettings } from "../spaced-repetition/spacedRepetitionTypes";

import { decompressData } from "../compression/utils";
import { clientRPC } from "@/utils/hono-client";

const getVisibleDeck = (cards: CardProps[]) => {
  return cards.filter(
    (card) =>
      !countUtils.isCardDeleted(card) &&
      card.front !== "Loading..." &&
      !card.deletedTimeISO
  );
};

export type AdditionalProperites = {
  displayName?: string;
  imageBackround?: string;
  imageBackground?: string;
  color?: (typeof COLORS)[number];
  thumbnailText?: string;
  folder?: string;
  languageSettings?: {
    front: string | undefined;
    back: string | undefined;
    quiz: string | undefined;
  };
};

type UserState =
  | {
      isLoading: boolean;
      addedDecks: string[];
      aiDecks: string[];
      setAddedDecks: SetValue<string[]>;
      deleteDeck: (deckName: string) => void;
      localDecks: LocalDeck[];
      folders: Folder[];
      settings: UserSettings;
    }
  | {
      isLoading: true;
      addedDecks: undefined;
      aiDecks: undefined;
      setAddedDecks: undefined;
      deleteDeck: undefined;
      localDecks: undefined;
      folders: undefined;
      settings: undefined;
    };

export type UserData = {
  success: boolean;
  data: {
    decks: {
      decks: Record<string, CardProps[]>;
      addedAiDecks: string[];
      additionalProperites: Record<string, AdditionalProperites>;
      folders: Folder[];
      settings?: UserSettings;
    };
    nonSync?: true;
    deckCount?: number;
    updated_at: string;
  };
};

export type UserSettings = { spacedRepetition?: SpacedRepetitionSettings };

const fetchDeck = async (): Promise<UserData> => {
  const latestUpdate = await localDB.getLatestUpdateTimestamp();

  const response = await clientRPC.api["user-status"]["sync-user"].$get({
    query: latestUpdate ? { latestUpdate } : undefined,
  });

  if (!response.ok) {
    console.log("❌ RESPONSE WAS NOT OK!!!!", response);
    throw new Error("Network response was not ok");
  }

  console.log("✅ RESPONSE WAS OK!!!!", response);

  const result = await response.json();

  // Handle compressed data
  if (result.compressed) {
    return {
      success: result.success,
      data: decompressData(result.data),
    };
  }

  return result;
};

export const useGetRemoteUserDecks = (userId: string | undefined | null) => {
  return useQuery({
    queryKey: ["decks-sync", userId],
    queryFn: fetchDeck,
    staleTime: 1_800_000, // 30 minute in ms
    gcTime: 1_800_000, // 30 minute in ms
    refetchOnWindowFocus: false,
    enabled: !!userId,
  });
};

export const getDeckName = (fileName: string) => {
  return fileName.slice(fileName.length - 11).startsWith("share-")
    ? formatDeckName(fileName).slice(0, -11)
    : formatDeckName(fileName).slice(0, -2);
};

export type FolderLocal = Folder & { decks: NonNullable<DeckLocal>[] };

export const useGetFolderLocal = (
  folderId: string
): FolderLocal | undefined | null => {
  const localAllDecks = useGetDecksLocal();
  if (localAllDecks !== undefined && folderId === "unassigned") {
    return {
      decks: localAllDecks.decks.filter(
        (deck) =>
          deck.folder === "" ||
          deck.folder === "unassigned" ||
          deck.folder === undefined
      ),
      color: "gray",
      id: "unassigned",
      name: "Decks without folder",
    };
  }

  // Returning undefined when we are still loading
  if (!localAllDecks) {
    return undefined;
  }

  const thisFolder = localAllDecks.foldersArray.find(
    (folder) => folder.id === folderId
  );

  // Returning null when we are sure it doesn't exist
  if (thisFolder === undefined) {
    return null;
  }

  return {
    decks: localAllDecks.decks.filter((deck) => deck.folder === folderId),
    ...thisFolder,
  };
};

export const useGetDecksLocal = () => {
  const deckPropertiesArray = useLiveQuery(() =>
    localDB.deckProperties.toArray()
  );

  const foldersArray = useLiveQuery(() => localDB.folders.toArray());

  const data = useLiveQuery(() => localDB.decks.toArray());

  const memoizedResult = useMemo(() => {
    if (
      data === undefined ||
      deckPropertiesArray === undefined ||
      foldersArray === undefined
    ) {
      return undefined;
    }

    const deckPropertiesObject: Record<
      string,
      Omit<(typeof deckPropertiesArray)[number], "name">
    > = {};

    for (const item of deckPropertiesArray) {
      const { name, ...rest } = item;
      deckPropertiesObject[name] = rest;
    }

    const decks = data.map((deck) => {
      const displayName =
        deckPropertiesObject[deck.name]?.displayName || getDeckName(deck.name);

      deck.cards = deck.cards.map(migrateCardFromLegacyToAnki);

      return {
        ...deck,
        ...deckPropertiesObject[deck.name],
        displayName: displayName,
      };
    });

    return {
      decks: decks,
      properties: deckPropertiesObject,
      foldersArray: foldersArray,
    };
  }, [data, deckPropertiesArray, foldersArray]); // Dependencies array

  return memoizedResult;
};

export type DeckLocal = ReturnType<typeof useGetDeckLocal>;
export const useGetDeckLocal = (name: string) => {
  return useLiveQuery(async () => {
    const deck = await localDB.decks.get(name);

    if (!deck) {
      return null;
    }

    const deckProperties = await localDB.deckProperties.get(name);
    return {
      ...deck,
      ...deckProperties,
      displayName: deckProperties?.displayName || getDeckName(deck.name),
      // Hack: fixing old users with Loading flashcards
      cards: deck.cards.filter((card) => card.front !== "Loading..."),
    };
  }, [name]);
};

export const getLocalDecksAsRecord = (decksArray: Deck[]) => {
  const decks = {} as Record<string, CardProps[]>;

  for (const deck of decksArray) {
    const deckValue = deck.cards;

    if (deckValue) {
      decks[deck.name] = deckValue;
    }
  }

  return decks;
};

export type LocalDeck = {
  name: string;
  cards: CardProps[];
  displayName?: string | undefined;
  imageBackground?: string | undefined;
  folder?: string | undefined;
  color?: (typeof COLORS)[number];
  thumbnailText?: string;
  languageSettings?: {
    front: string | undefined;
    back: string | undefined;
    quiz: string | undefined;
  };
};

export function shuffleInPlace<T>(array: Array<T>) {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const useSpacedRepetitionSettings = ():
  | {
      isLoading: true;
      spacedRepetitionSettings: undefined;
      setSpacedRepetitionSettings: (
        settings: SpacedRepetitionSettings
      ) => Promise<SpacedRepetitionSettings>;
    }
  | {
      isLoading: false;
      spacedRepetitionSettings: SpacedRepetitionSettings;
      setSpacedRepetitionSettings: (
        settings: SpacedRepetitionSettings
      ) => Promise<SpacedRepetitionSettings>;
    } => {
  const setSpacedRepetitionSettings = async (
    settings: SpacedRepetitionSettings
  ) => {
    return await localDB.setGlobalSRSettings(settings);
  };

  const spacedRepetitionSettings = useLiveQuery(async () => {
    return localDB.getGlobalSRSettings();
  });

  if (spacedRepetitionSettings === undefined) {
    return {
      isLoading: true,
      spacedRepetitionSettings: undefined,
      setSpacedRepetitionSettings,
    };
  } else {
    return {
      isLoading: false,
      spacedRepetitionSettings,
      setSpacedRepetitionSettings,
    };
  }
};

export const useGetUserStateAndAlign = (): UserState => {
  const [isLoadingRemote, setIsLoadingRemote] = useState(true);
  const { toast } = useToast();
  const { isSignedIn, isLoaded, userId } = useAuth();

  const debouncedSync = useDebounceSync(toast);

  const latestLocalUpdate = useLiveQuery(async () => {
    return localDB.getLatestUpdateTimestamp();
  });

  const {
    data: remoteData,
    isFetching,
    isError,
    isLoading,
  } = useGetRemoteUserDecks(userId);

  const globalSrSettings = useLiveQuery(async () => {
    return localDB.getGlobalSRSettings();
  });

  // Expectional case: Plugin Decks
  const [localAddedDecks, setAddedDecks] = useLocalStorage(
    "added-decks",
    [] as string[]
  );

  const localDecks = useGetDecksLocal();

  const decks = useMemo(() => {
    if (!localDecks) return undefined;

    return localDecks.decks.map((deck) => ({
      ...deck,
      name: deck.name,
      cards: deck.cards,
    }));
  }, [localDecks]);

  //console.log(`Deps${localDecks} ${remoteData} ${isFetching} ${isLoaded} ${isSignedIn}`)

  useEffect(() => {
    if (!isSignedIn || !isLoaded || !userId) {
      setIsLoadingRemote(true);

      if (
        isLoaded &&
        isSignedIn === false &&
        localDecks &&
        typeof window === "undefined"
      ) {
        console.warn("clearall triggered");
        localDB.clearAll();
      }

      return;
    }

    console.log("Sync state debug:", {
      localDecksAvailable: !!localDecks,
      remoteDataAvailable: !!remoteData,
      isFetching,
      isLoading,
      isError,
      latestLocalUpdateDefined: latestLocalUpdate !== undefined,
      isSignedIn,
      isLoaded,
      globalSrSettingsAvailable: !!globalSrSettings,
    });

    if (
      !localDecks ||
      !remoteData ||
      isFetching ||
      isLoading ||
      isError ||
      latestLocalUpdate === undefined ||
      !isSignedIn ||
      !isLoaded ||
      !globalSrSettings
    ) {
      return;
    }

    // This was never active... activate to reduce load, I guess!
    // if (new Date(lastLocalUpdate.getTime() + 500) > new Date()) {
    //   // setIsLoadingRemote(false);
    //   return;
    // }

    const localDecksAsRecord = getLocalDecksAsRecord(localDecks.decks);

    // If there is no entry in the DB and we updated data locally, update remote and consider local the correct state
    if (!remoteData.data || !remoteData.data.decks) {
      // Safeguard: if the user doesn't have locally any decks yet or properties, don't push to remote
      if (
        Object.keys(localDecksAsRecord).length === 0 &&
        Object.keys(localDecks.properties).length === 0
      ) {
        console.log(
          "remote data doesn't exist but also no local data, not pushing"
        );
        setIsLoadingRemote(false);
        return;
      }

      console.log("remote data doesn't exist, pushing");
      debouncedSync({
        localDeck: localDecksAsRecord,
        additionalProperites: localDecks.properties,
        latestLocalUpdate: latestLocalUpdate || new Date().toISOString(),
        folders: localDecks.foldersArray,
        settings: { spacedRepetition: globalSrSettings },
        userId: userId,
      });
      setIsLoadingRemote(false);
      return;
    }

    // If the states are the same we don't need to update anything
    const latestRemoteUpdate = remoteData.data.updated_at;
    if (
      (latestLocalUpdate &&
        new Date(latestRemoteUpdate).toISOString() ===
          new Date(latestLocalUpdate).toISOString()) ||
      remoteData?.data?.nonSync
    ) {
      console.log("local and remote are the same");
      setIsLoadingRemote(false);
      return;
    }

    const remoteAiDecks = remoteData.data.decks?.addedAiDecks || [];
    const remoteDecks = remoteData.data.decks.decks;

    // If locally we have newer data, push it to the DB
    if (latestLocalUpdate !== null && latestRemoteUpdate < latestLocalUpdate) {
      // pushLocalToDB with the newest local data
      console.log("local more recent, push");

      debouncedSync({
        localDeck: localDecksAsRecord,
        additionalProperites: localDecks.properties,
        latestLocalUpdate: latestLocalUpdate,
        folders: localDecks.foldersArray,
        settings: { spacedRepetition: globalSrSettings },
        userId: userId,
      });
      setIsLoadingRemote(false);
      return;
    }

    // We are updating local based on remote data
    console.log("remote more recent, pull");

    localDB.setLatestUpdateTimestamp(latestRemoteUpdate);
    localDBUtils.syncLocalWithRemote({
      remoteDecks,
      deckNames: localDecks.decks.map((deck) => deck.name),
      remoteAiDecks,
      remoteData,
      localFolders: localDecks.foldersArray,
      remoteFolders: remoteData.data.decks.folders,
      remoteSettings: {
        spacedRepetition: remoteData.data.decks.settings?.spacedRepetition,
      },
    });

    setIsLoadingRemote(false);
  }, [
    localDecks,
    remoteData,
    isFetching,
    isError,
    isLoaded,
    isSignedIn,
    globalSrSettings,
  ]);

  if (!decks || !localDecks) {
    return {
      isLoading: true,
      addedDecks: undefined,
      aiDecks: undefined,
      setAddedDecks: undefined,
      deleteDeck: undefined,
      localDecks: undefined,
      folders: undefined,
      settings: undefined,
    };
  }

  return {
    addedDecks: localAddedDecks,
    aiDecks: localDecks.decks.map((deck) => deck.name),
    setAddedDecks,
    isLoading: isLoadingRemote || isFetching,
    deleteDeck: (deckName: string) => localDB.deleteDeck(deckName),
    localDecks: decks,
    folders: localDecks.foldersArray,
    settings: { spacedRepetition: globalSrSettings },
  };
};

/***This function return handles issues with syncronization: if the platform is fetching data from remote and we haven't
synced with local yet we should not allow the user to take any actions that could modify the state to avoid
issues with old product states***/
export const useCanTakeActions = () => {
  const { userId } = useAuth();
  const { isFetching } = useGetRemoteUserDecks(userId);

  return !isFetching;
};

export const decksUtils = { getVisibleDeck };
