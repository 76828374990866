import { useSearchParams } from "react-router";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FoldersSidebar } from "@/components/folder/sidebar";
import { useWindowSize } from "usehooks-ts";
import { useLocation } from "react-router";
import { useAuth } from "@clerk/react-router";
import { useLocalStorage } from "@/components/hooks/use-local-storage";
import { cn } from "@/lib/utils";

// Create a context that will hold the ref
const PanelContext =
  createContext<React.RefObject<HTMLDivElement | null> | null>(null);

export const usePanelRef = () => useContext(PanelContext);

export const SidebarWrapper = ({ children }: { children: React.ReactNode }) => {
  const { width } = useWindowSize();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const { userId } = useAuth();

  const [isCollapsed, setIsCollapsed] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userInfo] = useLocalStorage<Record<string, any>>("user-info", {});
  const isMobile = width < 1024;

  useEffect(() => {
    const viewType = params.get("view") as string | null;

    if (
      pathname.startsWith("/memo-ai") ||
      pathname.startsWith("/quiz/") ||
      pathname.startsWith("/add") ||
      (pathname === "/dashboard" && !params.get("folder"))
    ) {
      setIsCollapsed(true);
    }

    if (viewType === "pdf" || viewType === "flashcards") {
      setIsCollapsed(true);
    }
  }, [pathname, params]);

  const panelRef = useRef<HTMLDivElement>(null);

  let isHidden: boolean;
  if (
    ["/home", "/", "/updates", "/settings"].includes(pathname) ||
    isMobile ||
    ((pathname === "/dashboard" || pathname === "/welcome") &&
      (userId ? userInfo[userId] : undefined) == undefined)
  ) {
    isHidden = true;
  } else {
    isHidden = false;
  }

  return (
    <>
      <div className="flex flex-row h-full w-full">
        {!isHidden && (
          <div
            className={cn(
              "flex flex-col gap-1 py-3 justify-between h-screen pt-12 min-w-[50px] fixed border-r-2 border-r-secondary-100 z-[40] bg-secondary-50",
              isCollapsed ? "w-16" : "w-64"
            )}
          >
            <FoldersSidebar
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              className="p-4"
            />
          </div>
        )}
        <div
          className={cn(
            "w-full mt-12",
            !isCollapsed && !isHidden && "pl-64",
            isCollapsed && !isHidden && "pl-16"
          )}
          ref={panelRef}
        >
          <PanelContext.Provider value={panelRef}>
            {children}
          </PanelContext.Provider>
        </div>
      </div>
    </>
  );
};
