import { cn } from "@/lib/utils";
import { CheckIcon } from "lucide-react";
import { toast } from "sonner";
import AnimatedStrikethrough from "@/components/dashboard/animated-strikethrough";
import { Button } from "@/components/ui/button";
import { Link } from "react-router";
import { PostHog } from "posthog-js/react";

export const triggerDeckCreatedToast = () => {
  toast.success(
    <div className="flex items-center text-valid-950 group gap-1">
      <div
        className={cn(
          "h-6 w-6 border-2  rounded-md flex items-center justify-center mr-1",
          "border-valid-400 bg-valid-200"
        )}
      >
        {true ? <CheckIcon className="h-4 w-4 text-valid-400" /> : <></>}
      </div>
      You have
      <span className="font-semibold relative">
        <AnimatedStrikethrough text="created your first deck" />
      </span>
    </div>,
    {
      position: "bottom-left",
      closeButton: true,
    }
  );

  toast.message(
    <div className="flex items-center text-valid-950 group gap-1">
      <div
        className={cn(
          "h-6 w-6 border-2  rounded-md flex items-center justify-center mr-1",
          "border-valid-400 bg-valid-200"
        )}
      ></div>
      ✨New task: <span className="font-semibold">Generate 10 flashcards</span>
    </div>,
    {
      position: "bottom-left",
      closeButton: true,
    }
  );
};

export const triggerCreatedFlashcards = ({
  path,
  posthog,
}: {
  path: string;
  posthog: PostHog;
}) => {
  // The path will be /deck/...
  const deckUrl = path.split("/")[2];

  toast.success(
    <div className="flex items-center text-valid-950 group gap-1">
      <div
        className={cn(
          "h-6 w-6 border-2  rounded-md flex items-center justify-center mr-1",
          "border-valid-400 bg-valid-200"
        )}
      >
        {true ? <CheckIcon className="h-4 w-4 text-valid-400" /> : <></>}
      </div>
      You have
      <span className="font-semibold relative">
        <AnimatedStrikethrough text="generated 10 flashcards" />
      </span>
    </div>,
    {
      position: "bottom-left",
      closeButton: true,
    }
  );

  toast.message(
    <div className="flex items-center text-valid-950 group gap-1 w-full">
      <div className="flex items-center flex-grow">
        <div
          className={cn(
            "h-6 w-6 border-2 rounded-md flex items-center justify-center mr-2",
            "border-valid-400 bg-valid-200"
          )}
        ></div>
        ✨New task:
        <span className="font-semibold ml-1">Study 10 flashcards</span>
      </div>
      <Link
        to={`/memo-ai?deck=${deckUrl}`}
        onClick={() => {
          posthog.capture(
            "Onboarding-Checklist-flashcards-created-click-on-action"
          );
          toast.dismiss();
        }}
      >
        <Button className="bg-valid-300 hover:bg-valid-400 active:bg-valid-500 h-8  px-3 rounded-xl">
          Study
        </Button>
      </Link>
    </div>,
    {
      position: "bottom-left",
      closeButton: true,
    }
  );
};

export const triggerStudiedFlashcards = ({
  deckName,
  posthog,
}: {
  deckName: string;
  rewardCredits?: number | null;
  posthog: PostHog;
}) => {
  toast.success(
    <div className="flex items-center text-valid-950  group gap-1">
      <div
        className={cn(
          "h-6 w-6 border-2  rounded-md flex items-center justify-center mr-1",
          "border-valid-400 bg-valid-200"
        )}
      >
        <CheckIcon className="h-4 w-4 text-valid-400" />
      </div>
      You have
      <span className="font-semibold relative">
        <AnimatedStrikethrough text="studied 10 flashcards" />
      </span>
    </div>,
    {
      position: "bottom-left",
      closeButton: true,
    }
  );

  toast.message(
    <div className="flex items-center text-subtitle-800 group gap-1 w-full">
      <div className="flex items-center flex-grow">
        <div
          className={cn(
            "h-6 w-6 border-2 rounded-md flex items-center justify-center mr-2",
            "border-valid-400 bg-valid-200"
          )}
        ></div>
        <span>
          ✨ New task: <span className="font-semibold">Take a quiz</span>
        </span>
      </div>
      <Link
        to={`/quiz/${encodeURIComponent(deckName)}`}
        onClick={() => {
          posthog.capture(
            "Onboarding-Checklist-flashcards-studied-click-on-action"
          );
          toast.dismiss();
        }}
      >
        <Button className="bg-valid-300 hover:bg-valid-400 active:bg-valid-500 h-8 text-valid-950 px-3 rounded-xl">
          Start quiz
        </Button>
      </Link>
    </div>,
    {
      position: "bottom-left",
      closeButton: true,
    }
  );
};
