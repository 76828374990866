import { CardAction, CardProps } from "@/components/memo/study-session";
import { CardStudyState } from "./spacedRepetitionTypes";
import { add } from "date-fns";
import { adjustedStartOfDay } from "./adjustedTimeUtils";
import { Word } from "@/components/pdf/types";

export function filterWordsDataImageOcclusion(words: Word[]) {
  return words.map((word) => ({
    polygon: word.polygon.map((point) => ({
      x: Number(point.x.toFixed(1)),
      y: Number(point.y.toFixed(1)),
    })),
  }));
}

/**
 *  Migrates a card from legacy version to anki mantaining the old actions field.
 *
 *  NOTE: THIS METHOD MUST BE:
 *  * IDEMPOTENT. IF A CARD IS ALREADY MIGRATED NO CHANGES MUST OCCOUR
 *  * INCREMENTAL. IF SOME FILEDS ARE NOT MIGRATED AND OTHER ARE, MIGRATE ONLY THE ONES THAT ARE NOT MIGRATED
 */
export function migrateCardFromLegacyToAnki(card: CardProps): CardProps {
  // Migration strategy for very heavy image occlusion flashcards to save on memory
  const { front } = card;
  let { back } = card;
  // Adding more redundancy into the extraction of the image occlusion data
  if (
    front.startsWith("<img src") &&
    front.endsWith(">") &&
    front.split("<").length === 2
  ) {
    const data = JSON.parse(back!);

    back = JSON.stringify({
      words: filterWordsDataImageOcclusion(data.words),
      position: data.position,
    });
  }

  return {
    ...card,
    id: card.id ? card.id : Math.random().toString(),
    front: front,
    back: back,
    // NOTE: important removal of visualization: when one card is studied there
    // is no need to continue showing more visualization, so they are removed
    aiNotes: card.aiNotes
      ? card.aiNotes.map((note) => {
          if (note.type !== "visualize") {
            return note;
          }

          return { ...note, note: [note.note[0]] };
        })
      : [],
    // Another migration to reduce the memory: if only one chat is present it's probably the default one that is not necessary
    chat: card.chat ? (card.chat.length !== 1 ? card.chat : []) : [],
    createdTimeISO:
      card.createdTimeISO ?? findCreatedDateISOFromActions(card.actions),
    deletedTimeISO:
      card.deletedTimeISO !== undefined
        ? card.deletedTimeISO
        : convertDeleteActionInDeleteISO(card.actions),
    studyState:
      card.studyState ??
      card.actions
        .map(convertCardActionInCardState)
        .filter((x): x is CardStudyState => x !== undefined),
  };
}

export function findCreatedDateISOFromActions(actions: CardAction[]): string {
  if (actions.length === 0) {
    return new Date().toISOString();
  }

  const minDateISO =
    actions
      .map(
        (action) =>
          action.bad?.ISO ||
          action.delete?.ISO ||
          action.good?.ISO ||
          new Date().toISOString()
      )
      .sort((a, b) => a.localeCompare(b))[0] ?? new Date().toISOString();

  return minDateISO;
}

export function convertDeleteActionInDeleteISO(
  actions: CardAction[]
): string | null {
  const z = actions.findLast((x) => x.delete);
  return z?.delete?.ISO ?? null;
}

export function convertCardActionInCardState(
  action: CardAction
): CardStudyState | undefined {
  const entries = Object.entries(action);
  if (!entries) {
    throw new Error("Malformed CardAction. No action specified");
  }

  const [type, { ISO }]: [string, { ISO: string }] = entries[0];

  if (type === "bad") {
    const nextDueISO = add(new Date(ISO), { minutes: 1 }).toISOString();

    return {
      type,
      ISO,
      algoInfo: {
        type: "legacy",
        nextDueISO: nextDueISO,
        interval: { minutes: 1 },
        studyPhase: "lapsed",
      },
    };
  } else if (type === "good") {
    const nextDueISO = adjustedStartOfDay(add(new Date(ISO), { days: 1 }), {
      hours: 4,
    }).toISOString();
    return {
      type,
      ISO,
      algoInfo: {
        type: "legacy",
        nextDueISO: nextDueISO,
        interval: { days: 1 },
        studyPhase: "review",
      },
    };
  }
}
