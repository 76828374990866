declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const clickSignupButtonDataLayer = () => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      event: "sign-up",
    });
  } else {
    console.warn("dataLayer is not defined on window");
  }
};
