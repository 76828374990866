import { Button } from "./ui/button";
import { Sheet, SheetContent, SheetTrigger } from "../components/ui/sheet";
import { Disclosure } from "@headlessui/react";
import { TextAlignJustifyIcon } from "@radix-ui/react-icons";
import { cn } from "../lib/utils";
import { useState } from "react";
import { clickSignupButtonDataLayer } from "@/lib/data-layer/utils";
import { FoldersSidebar } from "./folder/sidebar";
import { GuidedTourProvider } from "./guided-tour/guided-tour-provider";

import { Link, useLocation } from "react-router";

import { ThemeSwitch, useTheme } from "../settings/theme";
import { SignUpButton, useUser } from "@clerk/react-router";
import { AvatarWithDropdown } from "./avatar-with-dropdown";
import { localDB } from "@/database/db";

export const ThemedLogo = ({ className }: { className?: string }) => {
  const { theme } = useTheme();

  return theme === "light" ? (
    <LightLogo className={className} />
  ) : (
    <DarkLogo className={className} />
  );
};

export const LightLogo = ({ className }: { className?: string }) => {
  return (
    <div>
      <svg
        width="74"
        height="14"
        viewBox="0 0 74 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <rect width="12.9463" height="14" rx="2" fill="#262626" />
        <rect
          x="2.25781"
          y="1.2041"
          width="9.48391"
          height="5.21236"
          rx="1"
          fill="#FCD34D"
        />
        <rect
          x="2.25781"
          y="7.58301"
          width="9.48391"
          height="5.21236"
          rx="1"
          fill="#A78BFA"
        />
        <path
          d="M67.987 13.2664C65.7903 13.2664 64.3145 11.6361 64.3145 8.54706C64.3145 5.44087 65.7903 3.81055 67.987 3.81055C69.4629 3.81055 70.5612 4.54848 71.0074 5.66397H71.1275L71.1842 4.55363C71.2005 4.23341 71.4649 3.98216 71.7855 3.98216H72.6705C73.0031 3.98216 73.2727 4.25175 73.2727 4.58431V12.5098C73.2727 12.8424 73.0031 13.112 72.6705 13.112H71.7297C71.3971 13.112 71.1275 12.8424 71.1275 12.5098V11.413H71.0074C70.5612 12.5285 69.4629 13.2664 67.987 13.2664ZM68.7936 11.516C70.3209 11.516 71.0932 10.2975 71.0932 9.01042V8.06655C71.0932 6.76229 70.3209 5.561 68.7936 5.561C67.4207 5.561 66.4939 6.53919 66.4939 8.54706C66.4939 10.5378 67.4207 11.516 68.7936 11.516Z"
          fill="#262626"
        />
        <path
          d="M56.8053 13.1121C56.4727 13.1121 56.2031 12.8426 56.2031 12.51V1.20371C56.2031 0.871154 56.4727 0.601562 56.8053 0.601562H57.7805C58.113 0.601562 58.3826 0.871155 58.3826 1.20371V8.04956L59.1549 7.17434L62.0318 4.16816C62.1454 4.04947 62.3025 3.98234 62.4668 3.98234H63.4306C63.965 3.98234 64.2346 4.62661 63.8595 5.00719L61.3344 7.56905L64.3469 12.1807C64.6086 12.5812 64.3212 13.1121 63.8428 13.1121H62.7599C62.5558 13.1121 62.3657 13.0088 62.2547 12.8377L60.3733 9.9373L59.8242 8.99343L58.3826 10.4178V12.51C58.3826 12.8425 58.113 13.1121 57.7805 13.1121H56.8053Z"
          fill="#262626"
        />
        <path
          d="M46.9537 13.1121C46.6212 13.1121 46.3516 12.8426 46.3516 12.51V1.20371C46.3516 0.871154 46.6212 0.601562 46.9537 0.601562H47.8946C48.2271 0.601562 48.4967 0.871155 48.4967 1.20371V5.71563H48.5997C49.1145 4.46285 50.2815 3.81072 51.6029 3.81072C53.4564 3.81072 54.8464 4.87472 54.8464 7.63769V12.51C54.8464 12.8426 54.5768 13.1121 54.2443 13.1121H53.3034C52.9709 13.1121 52.7013 12.8426 52.7013 12.51V8.10105C52.7013 6.38492 52.1178 5.57834 50.7792 5.57834C49.3376 5.57834 48.4967 6.71098 48.4967 8.08389V12.51C48.4967 12.8426 48.2271 13.1121 47.8946 13.1121H46.9537Z"
          fill="#262626"
        />
        <path
          d="M41.5555 13.2836C39.392 13.2836 37.9724 12.3232 37.6932 10.8227C37.6327 10.4979 37.9089 10.2289 38.2393 10.2289H39.1612C39.4899 10.2289 39.7401 10.5019 39.8556 10.8097C40.0956 11.4491 40.7417 11.7391 41.6242 11.7391C42.7053 11.7391 43.2717 11.3272 43.2717 10.6064C43.2717 9.76551 42.7397 9.55958 41.3324 9.35364C38.9985 9.01042 37.883 8.39261 37.883 6.64216C37.883 4.94319 39.376 3.81055 41.6242 3.81055C43.5543 3.81055 44.8882 4.68505 45.2068 6.08478C45.2816 6.41355 45.0026 6.69364 44.6655 6.69364H43.76C43.4317 6.69364 43.1843 6.41954 43.0607 6.11542C42.8426 5.57913 42.3082 5.3379 41.5727 5.3379C40.5945 5.3379 40.0282 5.74977 40.0282 6.45339C40.0282 7.22564 40.646 7.4659 42.1733 7.67184C44.3528 7.96358 45.4683 8.61571 45.4683 10.4176C45.4683 12.2539 43.9066 13.2836 41.5555 13.2836Z"
          fill="#262626"
        />
        <path
          d="M31.4987 13.2664C29.302 13.2664 27.8262 11.6361 27.8262 8.54706C27.8262 5.44087 29.302 3.81055 31.4987 3.81055C32.9746 3.81055 34.0729 4.54848 34.5191 5.66397H34.6392L34.6959 4.55363C34.7122 4.23341 34.9766 3.98216 35.2973 3.98216H36.1823C36.5148 3.98216 36.7844 4.25175 36.7844 4.58431V12.5098C36.7844 12.8424 36.5148 13.112 36.1823 13.112H35.2414C34.9088 13.112 34.6392 12.8424 34.6392 12.5098V11.413H34.5191C34.0729 12.5285 32.9746 13.2664 31.4987 13.2664ZM32.3053 11.516C33.8326 11.516 34.6049 10.2975 34.6049 9.01042V8.06655C34.6049 6.76229 33.8326 5.561 32.3053 5.561C30.9324 5.561 30.0057 6.53919 30.0057 8.54706C30.0057 10.5378 30.9324 11.516 32.3053 11.516Z"
          fill="#262626"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1921 5.71563V12.51C19.1921 12.8426 19.4617 13.1121 19.7943 13.1121H20.7351C21.0677 13.1121 21.3373 12.8426 21.3373 12.51V5.71563H23.9729V3.98234H21.3373V2.9355C21.3373 2.42066 21.5432 2.06027 22.2983 2.06027H22.846C23.1785 2.06027 23.4481 1.79068 23.4481 1.45812V1.20371C23.4481 0.871154 23.1785 0.601562 22.846 0.601562H21.6977C20.1188 0.601562 19.1921 1.37382 19.1921 2.81537V3.98234H18.2154C17.8829 3.98234 17.6133 4.25193 17.6133 4.58449V5.11348C17.6133 5.44603 17.8829 5.71563 18.2154 5.71563H19.1921ZM24.8039 5.71563V12.51C24.8039 12.8426 25.0735 13.1121 25.406 13.1121H26.3469C26.6795 13.1121 26.9491 12.8425 26.9491 12.51V1.20371C26.9491 0.871154 26.6795 0.601562 26.3469 0.601562H25.406C25.0735 0.601562 24.8039 0.871154 24.8039 1.20371V3.98234V5.71563Z"
          fill="#262626"
        />
      </svg>
    </div>
  );
};

export const DarkLogo = ({ className }: { className?: string }) => {
  return (
    <div>
      <svg
        width="74"
        height="14"
        viewBox="0 0 74 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <rect width="12.9463" height="14" rx="2" fill="#D4D4D4" />
        <rect
          x="2.25781"
          y="1.2041"
          width="9.48391"
          height="5.21236"
          rx="1"
          fill="#171717"
        />
        <rect
          x="2.25781"
          y="7.58301"
          width="9.48391"
          height="5.21236"
          rx="1"
          fill="#171717"
        />
        <path
          d="M67.987 13.2664C65.7903 13.2664 64.3145 11.6361 64.3145 8.54706C64.3145 5.44087 65.7903 3.81055 67.987 3.81055C69.4629 3.81055 70.5612 4.54848 71.0074 5.66397H71.1275L71.1842 4.55363C71.2005 4.23341 71.4649 3.98216 71.7855 3.98216H72.6705C73.0031 3.98216 73.2727 4.25175 73.2727 4.58431V12.5098C73.2727 12.8424 73.0031 13.112 72.6705 13.112H71.7297C71.3971 13.112 71.1275 12.8424 71.1275 12.5098V11.413H71.0074C70.5612 12.5285 69.4629 13.2664 67.987 13.2664ZM68.7936 11.516C70.3209 11.516 71.0932 10.2975 71.0932 9.01042V8.06655C71.0932 6.76229 70.3209 5.561 68.7936 5.561C67.4207 5.561 66.4939 6.53919 66.4939 8.54706C66.4939 10.5378 67.4207 11.516 68.7936 11.516Z"
          fill="#D4D4D4"
        />
        <path
          d="M56.8053 13.1121C56.4727 13.1121 56.2031 12.8426 56.2031 12.51V1.20371C56.2031 0.871154 56.4727 0.601562 56.8053 0.601562H57.7805C58.113 0.601562 58.3826 0.871155 58.3826 1.20371V8.04956L59.1549 7.17434L62.0318 4.16816C62.1454 4.04947 62.3025 3.98234 62.4668 3.98234H63.4306C63.965 3.98234 64.2346 4.62661 63.8595 5.00719L61.3344 7.56905L64.3469 12.1807C64.6086 12.5812 64.3212 13.1121 63.8428 13.1121H62.7599C62.5558 13.1121 62.3657 13.0088 62.2547 12.8377L60.3733 9.9373L59.8242 8.99343L58.3826 10.4178V12.51C58.3826 12.8425 58.113 13.1121 57.7805 13.1121H56.8053Z"
          fill="#D4D4D4"
        />
        <path
          d="M46.9537 13.1121C46.6212 13.1121 46.3516 12.8426 46.3516 12.51V1.20371C46.3516 0.871154 46.6212 0.601562 46.9537 0.601562H47.8946C48.2271 0.601562 48.4967 0.871155 48.4967 1.20371V5.71563H48.5997C49.1145 4.46285 50.2815 3.81072 51.6029 3.81072C53.4564 3.81072 54.8464 4.87472 54.8464 7.63769V12.51C54.8464 12.8426 54.5768 13.1121 54.2443 13.1121H53.3034C52.9709 13.1121 52.7013 12.8426 52.7013 12.51V8.10105C52.7013 6.38492 52.1178 5.57834 50.7792 5.57834C49.3376 5.57834 48.4967 6.71098 48.4967 8.08389V12.51C48.4967 12.8426 48.2271 13.1121 47.8946 13.1121H46.9537Z"
          fill="#D4D4D4"
        />
        <path
          d="M41.5555 13.2836C39.392 13.2836 37.9724 12.3232 37.6932 10.8227C37.6327 10.4979 37.9089 10.2289 38.2393 10.2289H39.1612C39.4899 10.2289 39.7401 10.5019 39.8556 10.8097C40.0956 11.4491 40.7417 11.7391 41.6242 11.7391C42.7053 11.7391 43.2717 11.3272 43.2717 10.6064C43.2717 9.76551 42.7397 9.55958 41.3324 9.35364C38.9985 9.01042 37.883 8.39261 37.883 6.64216C37.883 4.94319 39.376 3.81055 41.6242 3.81055C43.5543 3.81055 44.8882 4.68505 45.2068 6.08478C45.2816 6.41355 45.0026 6.69364 44.6655 6.69364H43.76C43.4317 6.69364 43.1843 6.41954 43.0607 6.11542C42.8426 5.57913 42.3082 5.3379 41.5727 5.3379C40.5945 5.3379 40.0282 5.74977 40.0282 6.45339C40.0282 7.22564 40.646 7.4659 42.1733 7.67184C44.3528 7.96358 45.4683 8.61571 45.4683 10.4176C45.4683 12.2539 43.9066 13.2836 41.5555 13.2836Z"
          fill="#D4D4D4"
        />
        <path
          d="M31.4987 13.2664C29.302 13.2664 27.8262 11.6361 27.8262 8.54706C27.8262 5.44087 29.302 3.81055 31.4987 3.81055C32.9746 3.81055 34.0729 4.54848 34.5191 5.66397H34.6392L34.6959 4.55363C34.7122 4.23341 34.9766 3.98216 35.2973 3.98216H36.1823C36.5148 3.98216 36.7844 4.25175 36.7844 4.58431V12.5098C36.7844 12.8424 36.5148 13.112 36.1823 13.112H35.2414C34.9088 13.112 34.6392 12.8424 34.6392 12.5098V11.413H34.5191C34.0729 12.5285 32.9746 13.2664 31.4987 13.2664ZM32.3053 11.516C33.8326 11.516 34.6049 10.2975 34.6049 9.01042V8.06655C34.6049 6.76229 33.8326 5.561 32.3053 5.561C30.9324 5.561 30.0057 6.53919 30.0057 8.54706C30.0057 10.5378 30.9324 11.516 32.3053 11.516Z"
          fill="#D4D4D4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1921 5.71563V12.51C19.1921 12.8426 19.4617 13.1121 19.7943 13.1121H20.7351C21.0677 13.1121 21.3373 12.8426 21.3373 12.51V5.71563H23.9729V3.98234H21.3373V2.9355C21.3373 2.42066 21.5432 2.06027 22.2983 2.06027H22.846C23.1785 2.06027 23.4481 1.79068 23.4481 1.45812V1.20371C23.4481 0.871154 23.1785 0.601562 22.846 0.601562H21.6977C20.1188 0.601562 19.1921 1.37382 19.1921 2.81537V3.98234H18.2154C17.8829 3.98234 17.6133 4.25193 17.6133 4.58449V5.11348C17.6133 5.44603 17.8829 5.71563 18.2154 5.71563H19.1921ZM24.8039 5.71563V12.51C24.8039 12.8426 25.0735 13.1121 25.406 13.1121H26.3469C26.6795 13.1121 26.9491 12.8425 26.9491 12.51V1.20371C26.9491 0.871154 26.6795 0.601562 26.3469 0.601562H25.406C25.0735 0.601562 24.8039 0.871154 24.8039 1.20371V3.98234V5.71563Z"
          fill="#D4D4D4"
        />
      </svg>
    </div>
  );
};

const getNavigation = (path: string) => {
  return [
    { name: "Home", href: "/", current: path === "/" },
    {
      name: "Your Decks",
      href: "/dashboard",
      current: path.startsWith("/dashboard"),
    },
    { name: "Updates", href: "/updates", current: path.startsWith("/updates") },
  ];
};

function classNames(...classes: unknown[]) {
  return classes.filter(Boolean).join(" ");
}

const isPageWithSign = (pathname: string) => {
  console.log(pathname);
  return true;
};

export const NavBar = () => {
  // const authData = useAuth();
  const authData = useUser();
  const { isSignedIn } = authData;

  // const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  // const { theme, setTheme } = useTheme();
  const { pathname } = useLocation();

  return (
    <>
      <Disclosure
        as="nav"
        className={cn(
          "bg-secondary-50 w-full z-[200] h-12 fixed",
          pathname === "/" || pathname === "/home" ? "fixed" : ""
        )}
      >
        {() => (
          <>
            <div className="mx-auto px-2 sm:px-6 lg:px-8 border-b-2 bg-secondary-50 border-secondary-100 h-12">
              <div className="relative flex h-12 items-center justify-between">
                {isSignedIn ? (
                  <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                    <Sheet
                      open={isOpen}
                      onOpenChange={(change) => setIsOpen(change)}
                    >
                      <SheetTrigger asChild>
                        <Button
                          variant="secondary"
                          size="iconSm"
                          className="p-0"
                        >
                          <TextAlignJustifyIcon className="h-icon-md w-icon-md" />
                        </Button>
                      </SheetTrigger>
                      <SheetContent
                        side="left"
                        className="justify-between h-full flex-col flex w-72 pt-16 overflow-auto border-r-2 border-r-secondary-200 bg-secondary-50"
                      >
                        <FoldersSidebar
                          isCollapsed={false}
                          setIsCollapsed={() => setIsOpen(false)}
                          onClickFolder={() => setIsOpen(false)}
                          className="p-1"
                        />
                      </SheetContent>
                    </Sheet>
                  </div>
                ) : (
                  <div className="sm:hidden flex">
                    <ThemedLogo className="h-5" />
                  </div>
                )}
                <div className="flex sm:flex-1 items-center sm:items-stretch justify-between w-full">
                  <div
                    className={cn(
                      isSignedIn ? "w-[120px] lg:hidden" : "hidden"
                    )}
                  ></div>
                  <div className="hidden sm:flex flex-shrink-0 items-center sm:w-[120px] ml-0">
                    <Link
                      to={isSignedIn ? "/dashboard" : "/"}
                      className="flex gap-2 items-center justify-center z-50"
                    >
                      <ThemedLogo className="h-5" />
                    </Link>
                  </div>
                  <div className="hidden lg:block">
                    <div className="flex space-x-4 lg:gap-x-14"></div>
                  </div>

                  {isPageWithSign(pathname) ? (
                    <div className=" h-[40px] text-right flex justify-end items-center gap-4">
                      {pathname !== "/" && pathname !== "/home" ? (
                        <ThemeSwitch />
                      ) : (
                        <></>
                      )}

                      {!authData.isLoaded ? (
                        <></>
                      ) : !authData.isSignedIn ? (
                        <SignUpButton
                          mode="modal"
                          forceRedirectUrl="/welcome"
                          signInFallbackRedirectUrl="/welcome-back"
                          signInForceRedirectUrl="/welcome-back"
                          fallbackRedirectUrl="/welcome"
                        >
                          <Button
                            variant="primary"
                            size="md"
                            onClick={() => {
                              console.warn("clearall triggered");
                              clickSignupButtonDataLayer();
                              localDB.clearAll();
                            }}
                          >
                            Get started
                          </Button>
                        </SignUpButton>
                      ) : (
                        <div className="flex items-center justify-center gap-1">
                          <GuidedTourProvider>
                            <AvatarWithDropdown />
                          </GuidedTourProvider>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="w-[120px] h-[40px]"></div>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden z-50">
              <div className="space-y-1 px-2 pb-3 pt-2 z-50">
                {getNavigation(pathname).map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-secondary-300 hover:bg-secondary-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium z-50"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};
