import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../../lib/utils";

const buttonVariants = cva(
  "inline-flex items-center text-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors duration-300 reduce-motion:transition-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 whitespace-nowrap overflow-hidden text-ellipsis",
  {
    variants: {
      variant: {
        link: "text-primary underline-offset-4 hover:underline",
        toolbar:
          "bg-white text-neutral-600 hover:bg-neutral-300 hover:text-neutral-950 transition-colors duration-300",
        sidebar:
          "bg-white text-neutral-600 font-semibold justify-start rounded-md hover:text-neutral-950 transition-colors duration-300",
        primary: cn(
          "border",
          "border-primary-400 bg-primary-300 text-primary-950",
          "hover:border-primary-500 hover:bg-primary-400",
          "focus:border-primary-700 focus:bg-primary-500",
          "disabled:border-primary-400 disabled:bg-primary-300 disabled:opacity-20"
        ),

        secondary: cn(
          "border",
          "border-secondary-200 bg-secondary-50 text-title-950",
          "hover:border-secondary-300 hover:bg-secondary-200",
          "focus:border-secondary-400 focus:bg-secondary-300",
          "disabled:border-secondary-200 disabled:bg-secondary-100 disabled:text-body-600 disabled:opacity-30"
        ),

        alert: cn(
          "border",
          "border-alert-300 bg-alert-400 text-alert-950",
          "hover:border-alert-500 hover:bg-alert-400",
          "focus:border-alert-500 focus:bg-alert-500",
          "disabled:border-alert-400 disabled:bg-alert-300 disabled:opacity-20"
        ),
        valid: cn(
          "border",
          "border-valid-200 bg-valid-100 text-valid-950",
          "hover:border-valid-300 hover:bg-valid-200",
          "focus:border-valid-400 focus:bg-valid-300",
          "disabled:border-valid-300 disabled:bg-valid-200 disabled:opacity-20"
        ),

        again: cn(
          "border",
          "border-again-200 bg-again-100 text-again-950",
          "hover:border-again-300 hover:bg-again-200",
          "focus:border-again-400 focus:bg-again-300",
          "disabled:border-again-200 disabled:bg-again-100 disabled:opacity-20"
        ),

        hard: cn(
          "border",
          "border-hard-200 bg-hard-100 text-hard-950",
          "hover:border-hard-300 hover:bg-hard-200",
          "focus:border-hard-400 focus:bg-hard-300",
          "disabled:border-hard-200 disabled:bg-hard-100 disabled:opacity-20"
        ),

        good: cn(
          "border",
          "border-good-200 bg-good-100 text-good-950",
          "hover:border-good-300 hover:bg-good-200",
          "focus:border-good-400 focus:bg-good-300",
          "disabled:border-good-200 disabled:bg-good-100 disabled:opacity-20"
        ),

        easy: cn(
          "border",
          "border-valid-200 bg-valid-100 text-valid-950",
          "hover:border-valid-300 hover:bg-valid-200",
          "focus:border-valid-400 focus:bg-valid-300",
          "disabled:border-valid-200 disabled:bg-valid-100 disabled:opacity-20"
        ),

        strokePrimary: cn(
          "border-none text-primary-950",
          "hover:border hover:bg-primary-100 hover:border-primary-200",
          "focus:border focus:bg-primary-300 focus:border-primary-400",
          "disabled:border-none disabled:opacity-20"
        ),
        strokeSecondary: cn(
          "border-none text-subtitle-800",
          "hover:border hover:bg-secondary-100 hover:border-secondary-200",
          "focus:border focus:bg-secondary-200 focus:border-secondary-300",
          "disabled:border-none disabled:opacity-20"
        ),
        strokeSecondarySidebar: cn(
          "border-none text-body-600 text-xs font-bold",
          "hover:border hover:bg-secondary-100 hover:border-secondary-200",
          "focus:border focus:bg-secondary-200 focus:border-secondary-300",
          "disabled:border-none disabled:opacity-20"
        ),
        tilePrimary: cn(
          "border border-secondary-200 bg-secondary-50 text-subtitle-800",
          "hover:border hover:bg-primary-50 hover:border-primary-400 hover:text-primary-950",
          "focus:border focus:bg-primary-100 focus:border-primary-700  focus:text-primary-950",
          "data-[active=true]:border data-[active=true]:bg-primary-100 data-[active=true]:border-primary-700 data-[active=true]:text-primary-950",
          "disabled:border-secondary-200 disabled:bg-secondary-50 disabled:opacity-30"
        ),
        tileSecondary: cn(
          "border border-secondary-200 bg-secondary-50 text-subtitle-800",
          "hover:border hover:bg-secondary-50 hover:border-secondary-400 hover:text-secondary-950",
          "focus:border focus:bg-secondary-100 focus:border-secondary-500  focus:text-secondary-950",
          "data-[active=true]:border data-[active=true]:bg-secondary-100 data-[active=true]:border-secondary-500 data-[active=true]:text-secondary-950",
          "disabled:border-secondary-200 disabled:bg-secondary-50 disabled:opacity-30"
        ),
        breadcrumb: cn(
          "border-none text-subtitle-800",
          "hover:border hover:bg-secondary-100 hover:border-secondary-200",
          "focus:border focus:bg-secondary-200 focus:border-secondary-300",
          "disabled:border-none disabled:opacity-20"
        ),
        switch: cn(
          "border",
          "border-secondary-200 bg-secondary-50 text-title-950",
          "disabled:opacity-20"
        ),
      },
      size: {
        xl: cn(
          "h-11 px-4 py-2 rounded-2xl gap-2 text-base font-medium",
          "sm:h-[3.25rem]"
        ),

        lg: cn(
          "h-9 px-4 py-2 rounded-[0.875rem] gap-2 text-base font-medium",
          "sm:h-11 sm:rounded-2xl"
        ),
        md: cn(
          "h-8 px-3 py-2 rounded-xl gap-1.5 text-base font-sm",
          "sm:h-9 sm:rounded-2xl sm:px-4 sm:py-2 sm:rounded-[0.875rem] sm:gap-2 sm:font-medium"
        ),
        sm: cn("h-8 px-3 py-2 rounded-xl gap-1.5 text-base font-sm"),

        iconLg: cn(
          "text-base w-9 h-9 p-2 rounded-xl",
          "sm:w-[2.875rem] sm:h-[2.875rem] sm:p-3"
        ),
        iconMd: cn(
          "text-base w-8 h-8 p-1.5 rounded-lg",
          "sm:w-9 sm:h-9 sm:p-2 sm:rounded-xl"
        ),
        iconSm: "text-base  w-8 h-8 p-1.5 rounded-lg",
        icon2xs: "text-base w-5 h-5 p-0.5 rounded-md",
        tileLg: cn(
          "h-20 p-2 flex flex-col gap-2 rounded-[14px]",
          "text-base font-medium"
        ),
        tileSm: cn(
          "h-13 rounded-xl flex flex-col gap-1",
          "text-sm font-medium"
        ),

        breadcrumb: "h-6 pl-1 pr-1.5 py-1 rounded-lg gap-1",
        spacedRepetition:
          "h-[52px] px-4 py-2 flex-col items-center gap-1 sm:h-11 sm:flex-row rounded-[0.875rem]",
        // Non responsive layouts
        _xl: "h-[3.25rem] px-4 py-2 rounded-2xl inline-flex gap-2 text-base font-bold",
        _lg: "h-11 px-4 py-2 rounded-2xl inline-flex gap-2 text-base font-medium",
        _md: "h-9 px-4 py-2 rounded-[0.875rem] inline-flex gap-2 text-base font-medium",
        _sm: "h-8 px-3 py-2 rounded-xl inline-flex gap-1.5 text-sm font-medium",

        _iconLg: "text-base w-[2.875rem] h-[2.875rem] p-3 rounded-xl",
        _iconMd: "text-base  w-9 h-9 p-2 rounded-xl",
        _iconSm: "text-base  w-8 h-8 p-1.5 rounded-lg",
        _icon2xs: "text-base  w-5 h-5 p-0.5 rounded-md",

        default: "h-10 px-4 py-2",
        icon: "h-10 w-10",
        toolbarIcon: "h-8 w-8",
        sidebarIcon: "h-9 w-9",
        newAi: "w-full flex items-center justify-center h-[80px] lg:h-[100px] ",
        simple: "h-8 p-0",
      },
    },
    defaultVariants: {
      variant: "secondary",
      size: "md",
    },
  }
);

export interface ButtonProps
  extends React.ComponentProps<"button">,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

export function Button({
  className,
  variant,
  size,
  asChild = false,
  ...props
}: ButtonProps) {
  const Comp = asChild ? Slot : "button";
  return (
    <Comp
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    />
  );
}

Button.displayName = "Button";

export { buttonVariants };
