import {
  add,
  addDays,
  isAfter,
  isBefore,
  startOfDay,
  sub,
  subDays,
} from "date-fns";
import { AnkiDuration } from "./spacedRepetitionTypes";

const oneDayInMinutes = 60 * 24;

export function minutesToAnkiDuration(minutes: number) {
  if (minutes < oneDayInMinutes) {
    return {
      minutes: Math.floor(minutes),
    };
  } else {
    return {
      days: Math.floor(minutes / oneDayInMinutes),
    };
  }
}

export function formatAnkiDuration(duration: AnkiDuration) {
  if (duration.days && duration.days > 0) {
    return `${duration.days}d`;
  } else if (duration.minutes && duration.minutes > 60) {
    return `${Math.round(duration.minutes / 60)}h`;
  } else {
    return `${duration.minutes}m`;
  }
}

export function adjustedStartOfDay(
  baseDate: Date,
  cutoffTime: { hours: number; minutes?: number }
) {
  const todayAtCutoff = add(startOfDay(baseDate), cutoffTime);
  if (isBefore(baseDate, todayAtCutoff)) {
    return subDays(todayAtCutoff, 1);
  } else {
    return todayAtCutoff;
  }
}

export function adjustedEndOfDay(
  baseDate: Date,
  cutoffTime: { hours: number; minutes?: number }
) {
  const todayAtCutoff = add(startOfDay(baseDate), cutoffTime);
  if (isBefore(baseDate, todayAtCutoff)) {
    return sub(todayAtCutoff, { seconds: 1 });
  } else {
    return sub(addDays(todayAtCutoff, 1), { seconds: 1 });
  }
}

export function isAdjustedSameDay(
  date1: Date,
  date2: Date,
  cutoffTime: { hours: number; minutes?: number }
) {
  const date1FirstSecond = adjustedStartOfDay(date1, cutoffTime);
  const date1LastSecond = adjustedEndOfDay(date1, cutoffTime);

  if (date1.getTime() === date2.getTime()) {
    return true;
  }

  return !isBefore(date2, date1FirstSecond) && !isAfter(date2, date1LastSecond);
}
/**
 * Add to a date a anki interval (composed only by days and minutes).
 * The result of the add will be rounded down to the start of the day at the cutoff hour
 * (Useful for calculating due dates of the cards, that are always rounded to the beginning of the day, when they cross the day boundary)
 * @param baseDate The date from starting
 * @param ankiDuration A duration composed only by days and minutes
 * @param cutoffTime The time where the new day is conisdered to start
 * @returns
 */
export function adjustedDayAdd(
  baseDate: Date,
  ankiDuration: AnkiDuration,
  cutoffTime: { hours: number; minutes?: number }
) {
  const nextMoment = add(baseDate, ankiDuration);
  const todayAtCutoff = add(startOfDay(baseDate), cutoffTime);

  const isBeforeCutoff = isBefore(baseDate, todayAtCutoff);
  const daysToAddForTomorrowCutoff = isBeforeCutoff ? 0 : 1;

  const tomorrowStart = add(
    startOfDay(addDays(baseDate, daysToAddForTomorrowCutoff)),
    cutoffTime
  );

  if (isBefore(nextMoment, tomorrowStart)) {
    return nextMoment;
  } else {
    return add(startOfDay(nextMoment), cutoffTime);
  }
}
