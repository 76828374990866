// db.ts
import Dexie, { Table } from "dexie";
import { DeckLocal } from "@/lib/decks-utils/utils";
import { v4 } from "uuid";
import { COLORS } from "../components/personalization/deck-editing";
import { migrateCardFromLegacyToAnki } from "@/lib/spaced-repetition/migrations";
import { SpacedRepetitionSettings } from "@/lib/spaced-repetition/spacedRepetitionTypes";
import { CardProps } from "@/lib/decks-utils/deck-types";

export type StoredSource = {
  data: {
    source: string | "MANUAL" | "GPT";
    pdfInfo?: {
      filePath: string;
      pages: number[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      extraction: any;
    };
  };
};

export interface Deck {
  name: string;
  cards: CardProps[];
}

export interface DeckSource {
  name: string;
  source: StoredSource;
}

export interface DeckPDF {
  name: string;
  pdf: Blob;
}

export interface Folder {
  id: string;
  name: string;
  color: (typeof COLORS)[number];
  archivedTimeISO?: string;
}

// Redefined interface for Deck Properties
export interface DeckProperties {
  name: string; // Unique reference to Deck by name
  displayName?: string;
  imageBackground?: string;
  folder?: string;
  color?: (typeof COLORS)[number];
  thumbnailText?: string;
  languageSettings?: {
    front: string | undefined;
    back: string | undefined;
    quiz: string | undefined;
  };
}

// Define a simple key-value pair interface
export type KeyValue = {
  key: "latestUpdate" | "globalSrSettings";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any; // Can store any type of value
};

export class MySubClassedDexie extends Dexie {
  // We just tell the typing system this is the case
  decks!: Table<Deck>;
  deckProperties!: Table<DeckProperties>; // Table for deck properties
  deckSource!: Table<DeckSource>;
  deckPdf!: Table<DeckPDF>;
  settings!: Table<KeyValue>; // Table for key-value pairs
  folders!: Table<Folder>;

  constructor() {
    super("Flashka");
    this.version(38)
      .stores({
        decks: "&name",
        deckProperties: "&name", // Schema for deck properties
        deckSource: "&name",
        deckPdf: "&name",
        settings: "&key", // Define a schema for the settings table
        folders: "&id",
      })
      .upgrade((tx) => {
        // Imporant: In case of new migrations this function with the old data structure must be maintained.
        console.log("Upgrading to version 38");

        return tx.db
          .table<Deck>("decks")
          .toCollection()
          .modify((deck) => {
            for (let i = 0; i < deck.cards.length; i++) {
              deck.cards[i] = migrateCardFromLegacyToAnki(deck.cards[i]);
            }
          });
      });
  }

  async clearAll() {
    console.warn("Full clear as the user is logged out.");
    await this.settings.clear();
    await this.deckSource.clear();
    await this.deckPdf.clear();
    await this.deckProperties.clear();
    await this.decks.clear();
    await this.folders.clear();
  }

  // Helper method to get a value by key
  async getLatestUpdateTimestamp(): Promise<string | null> {
    const setting = await this.settings.get("latestUpdate");

    return setting?.value || null;
  }

  // Helper method to get a value by key
  async setLatestUpdateTimestamp(value: string): Promise<string> {
    // const currentTimestamp = new Date();
    await this.settings.put({ key: "latestUpdate", value: value });

    return value;
  }

  // Helper method to get a value by key
  async setGlobalSRSettings(
    value: SpacedRepetitionSettings
  ): Promise<SpacedRepetitionSettings> {
    // const currentTimestamp = new Date();
    await this.settings.put({ key: "globalSrSettings", value: value });
    await this.setTimestampAfterOperation();
    return value;
  }

  // Helper method to get a value by key
  async getGlobalSRSettings(): Promise<SpacedRepetitionSettings> {
    // const currentTimestamp = new Date();
    return (
      (await this.settings.get("globalSrSettings"))?.value ?? {
        preset: "default",
        overrides: {},
      }
    );
  }

  async setTimestampAfterOperation() {
    const ts = await this.settings.put({
      key: "latestUpdate",
      value: new Date().toISOString(),
    });
    return ts;
  }

  // Helper method to get a value by key
  async deleteDeck(deckName: string) {
    this.decks.delete(deckName);
    this.deckSource.delete(deckName);
    this.deckPdf.delete(deckName);
    this.deckProperties.delete(deckName);

    // const currentTimestamp = new Date();
    await this.setTimestampAfterOperation();

    return;
  }

  async updateDeckProperties(deckProperties: DeckProperties) {
    await this.deckProperties.put(deckProperties);

    await this.setTimestampAfterOperation();
  }

  async updateDeckCards(deckName: string, cards: CardProps[]) {
    await this.decks.put({
      name: deckName,
      // Copy the dictionary manually to ensure that no
      // unwanted fields are introudced
      // TODO Maybe we should do this also for sub-objects
      cards: cards.map((card) => {
        return {
          front: card.front,
          back: card.back,
          source: card.source,
          id: card.id,
          notes: card.notes,
          aiNotes: card.aiNotes,
          createdTimeISO: card.createdTimeISO,
          deletedTimeISO: card.deletedTimeISO,
          actions: card.actions,
          studyState: card.studyState,
          tags: card.tags,
          chat: card.chat,
          images: card.images,
        };
      }),
    });
    await this.setTimestampAfterOperation();
  }

  async updateDeckSource(deckName: string, source: StoredSource) {
    await this.deckSource.put({
      name: deckName,
      source: source,
    });

    //await this.setTimestampAfterOperation();
  }

  async updateDeckPDF(deckName: string, pdf: Blob) {
    await this.deckPdf.put({
      name: deckName,
      pdf: pdf,
    });

    //await this.setTimestampAfterOperation();
  }

  async updateFolder(properties: {
    name: string;
    color: (typeof COLORS)[number];
    id?: string;
    archivedTimeISO?: string;
  }) {
    if (properties.id) {
      await this.folders.put({
        color: properties.color,
        name: properties.name,
        id: properties.id,
        archivedTimeISO: properties.archivedTimeISO,
      });
    } else {
      const id = v4();
      await this.folders.put({
        color: properties.color,
        name: properties.name,
        id: id,
        archivedTimeISO: properties.archivedTimeISO,
      });
    }

    await this.setTimestampAfterOperation();
  }

  async deleteFolder(id: string) {
    await this.folders.delete(id);
    await this.setTimestampAfterOperation();
  }

  async updateDeckWithFunction(
    deck: DeckLocal | undefined,
    func: (cards: CardProps[]) => CardProps[]
  ) {
    if (!deck) {
      return;
    }

    console.log("we are now updating deck");
    await this.transaction("rw", [this.decks, this.settings], async () => {
      // We fetch the latest deck state from dexie to have a better guarantee of getting the right card
      const latestDeck = await localDB.decks.get(deck.name);
      const res = func(latestDeck!.cards);

      this.updateDeckCards(deck.name, res);
      console.log("we have updated deck", deck.name);
    });
  }
}

export const localDB = new MySubClassedDexie();
