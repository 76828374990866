import { ReactNode, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { cn } from "@/lib/utils";

export function ComboBox<K extends string>({
  value,
  onChange,
  defaultValue,
  options,
  selectText,
  searchText,
  notFoundText,
}: {
  value?: K | undefined;
  onChange?: (value: K | undefined) => void;
  defaultValue?: K;
  options: Record<K, ReactNode>;
  selectText: string;
  searchText: string;
  notFoundText: string;
}) {
  const [open, setOpen] = useState(false);
  const [internalSelected, setInternalSelected] = useState<K | undefined>(
    defaultValue
  );

  const selected = value !== undefined ? value : internalSelected;
  const setSelected = onChange || setInternalSelected;

  return (
    <div className="flex gap-2 flex-col" translate="no">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <Button
            variant="strokeSecondary"
            size="xl"
            role="combobox"
            aria-expanded={open}
            className="w-full bg-secondary-100 justify-between"
          >
            <span className="overflow-hidden whitespace-nowrap text-ellipsis">
              {selected ? options[selected] ?? selected : selectText}
            </span>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50 flex-none" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 w-[--radix-popover-trigger-width]">
          <Command className="w-full">
            <CommandInput placeholder={searchText} />
            <CommandList>
              <CommandEmpty>{notFoundText}</CommandEmpty>
              <CommandGroup>
                {Object.entries<ReactNode>(options).map(([key, display]) => (
                  <CommandItem
                    key={key}
                    value={key}
                    onSelect={(currentValue) => {
                      setSelected(currentValue as K);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        selected === key ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {display}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
