import { useEffect } from "react";
import {
  useLocation,
  Link,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router";
import { Button } from "@/components/ui/button";
import { RefreshCcw } from "lucide-react";
import { DiscordSVG } from "./components/icons/discord";

import React, { Component, ReactNode } from "react";
import { clientRPC } from "./utils/hono-client";
import { toast } from "sonner";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

interface WithRouterProps {
  navigate: NavigateFunction;
  location: Location;
}

const withRouter = <P extends WithRouterProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: Omit<P, keyof WithRouterProps>) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
      <WrappedComponent
        {...(props as P)}
        navigate={navigate}
        location={location}
      />
    );
  };
};

class ErrorBoundaryBase extends Component<
  ErrorBoundaryProps & WithRouterProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps & WithRouterProps): void {
    // If location has changed and we have an error state, reset the error
    if (
      this.state.hasError &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.resetError();
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  resetError = () => {
    this.setState({ hasError: false, error: null });
  };

  render(): React.ReactNode {
    const { hasError, error } = this.state;
    const { navigate, location, children } = this.props;

    if (hasError && error) {
      return (
        <ErrorPage
          error={error}
          reset={() => {
            this.resetError();
            navigate(location.pathname, { replace: true });
          }}
        />
      );
    }

    return children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryBase);

interface ErrorPageProps {
  error?: Error & { digest?: string };
  reset?: () => void;
}

export const ErrorPage = ({ error, reset }: ErrorPageProps) => {
  const location = useLocation();

  // If no reset function is provided, create one that refreshes the page
  const handleReset =
    reset ||
    (() => {
      window.location.reload();
    });

  useEffect(() => {
    if (!error) return;

    // Log the error to an error reporting service
    console.error(error);

    const errorInfo = {
      message: error.message,
      stack: error.stack,
      digest: error.digest,
      pathname: location.pathname,
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
      url: window.location.href,
    };

    clientRPC.api["error-warning"]
      .$post({
        json: {
          error: JSON.stringify(errorInfo),
        },
      })
      .catch(console.error);

    // Forcing user reload of the page
    if (
      error.message.startsWith(
        "'text/html' is not a valid JavaScript MIME type"
      ) ||
      error.message.startsWith("Importing a module script failed") ||
      error.message.startsWith("Failed to fetch dynamically imported module")
    ) {
      toast.error("Trying to reload a new version of the page...");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [error, location.pathname]);

  return (
    <div className="flex flex-col h-screen absolute top-0 items-center justify-center w-full">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="flex justify-center items-center flex-col gap-4 mt-20 p-2">
          <h2 className="text-3xl font-bold">Ooops...</h2>
          <div className="text-center">
            Something went wrong. Please refresh the page or ask for help in our
            Discord
          </div>
          <div className="text-center">
            Alternatively, please report the error to{" "}
            <b>stefandjokovic@flashka.ai</b>
          </div>
          <Link to="/dashboard">
            <Button size="xl" variant="primary">
              Go to Home
            </Button>
          </Link>
          <Button size="xl" variant="secondary" onClick={handleReset}>
            <RefreshCcw /> Refresh
          </Button>
          {/* Uncomment if you have these components */}
          <DiscordCTA />
        </div>
      </div>
      <div>
        {/* Uncomment if you have this component */}
        <KaError />
      </div>
    </div>
  );
};

const KaError = () => {
  return (
    <svg
      width="300"
      height="342"
      viewBox="0 0 300 342"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 24C4 12.9543 12.9543 4 24 4L243 4C254.046 4 263 12.9543 263 24V254C263 265.046 254.046 274 243 274H24C12.9543 274 4 265.046 4 254L4 24Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24C0 10.7452 10.7452 0 24 0L243 0C256.255 0 267 10.7452 267 24V254C267 267.255 256.255 278 243 278H24C10.7452 278 0 267.255 0 254L0 24ZM24 8C15.1634 8 8 15.1634 8 24L8 254C8 262.837 15.1634 270 24 270H243C251.837 270 259 262.837 259 254V24C259 15.1634 251.837 8 243 8L24 8Z"
        fill="#0A0A0A"
      />
      <path
        d="M19 24C19 12.9543 27.9543 4 39 4L242 4C253.046 4 262 12.9543 262 24V254C262 265.046 253.046 274 242 274H39C27.9543 274 19 265.046 19 254L19 24Z"
        fill="#FEF3C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 24C15 10.7452 25.7452 0 39 0L242 0C255.255 0 266 10.7452 266 24V254C266 267.255 255.255 278 242 278H39C25.7452 278 15 267.255 15 254L15 24ZM39 8C30.1634 8 23 15.1634 23 24L23 254C23 262.837 30.1634 270 39 270H242C250.837 270 258 262.837 258 254V24C258 15.1634 250.837 8 242 8L39 8Z"
        fill="#0A0A0A"
      />
      <path
        d="M4.11328 249.503H263.141V518.748H4.11328L4.11328 249.503Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.113281 249.503C0.113281 247.294 1.90414 245.503 4.11328 245.503H263.141C265.35 245.503 267.141 247.294 267.141 249.503V518.748C267.141 520.957 265.35 522.748 263.141 522.748H4.11328C1.90414 522.748 0.113281 520.957 0.113281 518.748L0.113281 249.503ZM8.11328 253.503L8.11328 514.748H259.141V253.503H8.11328Z"
        fill="#0A0A0A"
      />
      <path d="M19 250H262V519H19L19 250Z" fill="#A78BFA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 250C15 247.791 16.7909 246 19 246H262C264.209 246 266 247.791 266 250V519C266 521.209 264.209 523 262 523H19C16.7909 523 15 521.209 15 519L15 250ZM23 254L23 515H258V254H23Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.069 198.975C139.021 198.134 135.216 197.343 128.811 199.09C127.479 199.453 126.104 198.668 125.741 197.336C125.378 196.004 126.163 194.629 127.495 194.266C135.064 192.202 139.838 193.195 144.065 194.075C144.314 194.127 144.561 194.178 144.807 194.229C146.159 194.506 147.031 195.828 146.753 197.18C146.476 198.533 145.155 199.404 143.802 199.127C143.556 199.076 143.312 199.026 143.069 198.975Z"
        fill="#0A0A0A"
      />
      <path
        d="M128.443 171.41C128.443 180.364 121.181 187.623 112.222 187.623C103.263 187.623 96 180.364 96 171.41C96 162.456 103.263 155.197 112.222 155.197C121.181 155.197 128.443 162.456 128.443 171.41Z"
        fill="#0A0A0A"
      />
      <path
        d="M177.103 171.41C177.103 180.364 169.841 187.623 160.882 187.623C151.923 187.623 144.66 180.364 144.66 171.41C144.66 162.456 151.923 155.197 160.882 155.197C169.841 155.197 177.103 162.456 177.103 171.41Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.839 137.565C128.504 138.904 127.147 139.719 125.807 139.384C118.094 137.457 109.407 137.462 101.735 139.574C94.0588 141.688 87.6129 145.841 83.9936 152.025C83.2963 153.216 81.7647 153.617 80.5726 152.919C79.3806 152.222 78.9796 150.691 79.6769 149.5C84.1364 141.88 91.8684 137.105 100.406 134.755C108.947 132.403 118.51 132.408 127.019 134.534C128.358 134.869 129.173 136.226 128.839 137.565Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.345 139.623C150.68 140.962 152.037 141.776 153.377 141.442C161.09 139.514 169.776 139.519 177.449 141.632C185.125 143.745 191.571 147.898 195.19 154.082C195.887 155.274 197.419 155.674 198.611 154.977C199.803 154.28 200.204 152.749 199.507 151.557C195.047 143.938 187.315 139.163 178.778 136.812C170.236 134.461 160.673 134.466 152.165 136.592C150.825 136.927 150.01 138.284 150.345 139.623Z"
        fill="#0A0A0A"
      />
      <path
        d="M118.107 176.052C118.107 178.291 116.291 180.105 114.051 180.105C111.812 180.105 109.996 178.291 109.996 176.052C109.996 173.814 111.812 171.999 114.051 171.999C116.291 171.999 118.107 173.814 118.107 176.052Z"
        fill="#FEF3C7"
      />
      <path
        d="M162.107 176.052C162.107 178.291 160.291 180.105 158.051 180.105C155.812 180.105 153.996 178.291 153.996 176.052C153.996 173.814 155.812 171.999 158.051 171.999C160.291 171.999 162.107 173.814 162.107 176.052Z"
        fill="#FEF3C7"
      />
      <path
        d="M108 232.061C108 229.753 109.871 227.883 112.178 227.883H169.748C172.055 227.883 173.926 229.753 173.926 232.061V287.747C173.926 290.054 172.055 291.925 169.748 291.925H112.178C109.871 291.925 108 290.054 108 287.747V232.061Z"
        fill="#F9FAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.748 231.883H112.178C112.08 231.883 112 231.963 112 232.061V287.747C112 287.845 112.08 287.925 112.178 287.925H169.748C169.846 287.925 169.926 287.845 169.926 287.747V232.061C169.926 231.963 169.846 231.883 169.748 231.883ZM112.178 227.883C109.871 227.883 108 229.753 108 232.061V287.747C108 290.054 109.871 291.925 112.178 291.925H169.748C172.055 291.925 173.926 290.054 173.926 287.747V232.061C173.926 229.753 172.055 227.883 169.748 227.883H112.178Z"
        fill="#0A0A0A"
      />
      <path
        d="M108 230.178C108 227.871 109.871 226 112.178 226H169.748C172.055 226 173.926 227.871 173.926 230.178V287.748C173.926 290.055 172.055 291.926 169.748 291.926H112.178C109.871 291.926 108 290.055 108 287.748V230.178Z"
        fill="#F9FAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.748 230H112.178C112.08 230 112 230.08 112 230.178V287.748C112 287.846 112.08 287.926 112.178 287.926H169.748C169.846 287.926 169.926 287.846 169.926 287.748V230.178C169.926 230.08 169.846 230 169.748 230ZM112.178 226C109.871 226 108 227.871 108 230.178V287.748C108 290.055 109.871 291.926 112.178 291.926H169.748C172.055 291.926 173.926 290.055 173.926 287.748V230.178C173.926 227.871 172.055 226 169.748 226H112.178Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.471 261.801H108.383V257.801H173.471V261.801Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.817 242.793C19.9913 242.403 22.0706 243.849 22.4611 246.023L44.9145 371.031L125.511 271.958C126.905 270.244 129.425 269.985 131.138 271.379C132.852 272.773 133.111 275.292 131.717 277.006L45.621 382.839C44.6345 384.052 43.032 384.579 41.5184 384.188C40.0048 383.797 38.8575 382.561 38.5811 381.022L14.5871 247.437C14.1966 245.263 15.6426 243.184 17.817 242.793Z"
        fill="#0A0A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.872 246.815C260.695 246.443 258.628 247.906 258.256 250.084L237.143 373.64L156.554 274.587C155.16 272.874 152.64 272.615 150.927 274.009C149.213 275.403 148.954 277.923 150.348 279.636L236.499 385.524C237.489 386.741 239.1 387.267 240.618 386.868C242.136 386.47 243.28 385.22 243.545 383.673L266.141 251.431C266.514 249.254 265.05 247.187 262.872 246.815Z"
        fill="#0A0A0A"
      />
      <path
        d="M149.197 248.001L149.185 246.021H145.141V244.701L148.765 239.349H150.961V244.581H152.089L152.077 246.021H150.961V248.001H149.197ZM146.893 244.581H149.197V241.233L146.893 244.581Z"
        fill="black"
      />
      <path
        d="M141.034 248.084C138.982 248.084 137.758 246.392 137.758 243.644C137.758 240.812 139.078 239.24 141.07 239.24C143.11 239.24 144.322 240.932 144.322 243.668C144.322 246.524 143.002 248.084 141.034 248.084ZM141.046 246.596C141.934 246.596 142.57 245.936 142.57 243.656C142.57 241.376 141.934 240.728 141.046 240.728C140.146 240.728 139.522 241.376 139.522 243.656C139.522 245.936 140.146 246.596 141.046 246.596Z"
        fill="black"
      />
      <path
        d="M133.939 248.001L133.927 246.021H129.883V244.701L133.507 239.349H135.703V244.581H136.831L136.819 246.021H135.703V248.001H133.939ZM131.635 244.581H133.939V241.233L131.635 244.581Z"
        fill="black"
      />
      <g clipPath="url(#clip0_3427_7607)">
        <path
          d="M257.203 143.282C270.559 146.541 284.027 138.355 287.286 124.999C290.545 111.643 282.359 98.1746 269.003 94.916C255.647 91.6573 242.179 99.8427 238.92 113.199C235.661 126.555 243.847 140.023 257.203 143.282Z"
          fill="#A78BFA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M268.529 96.859C256.247 93.8622 243.86 101.39 240.863 113.673C237.866 125.956 245.394 138.342 257.677 141.339C269.96 144.336 282.346 136.808 285.343 124.525C288.34 112.243 280.812 99.8559 268.529 96.859ZM236.977 112.725C240.498 98.2957 255.049 89.4526 269.478 92.973C283.907 96.4935 292.75 111.044 289.229 125.473C285.709 139.903 271.158 148.746 256.729 145.225C242.3 141.705 233.457 127.154 236.977 112.725Z"
          fill="#0A0A0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M266.265 109.19C265.081 108.676 263.753 108.598 262.516 108.97C261.279 109.342 260.214 110.139 259.509 111.221C258.906 112.146 257.667 112.408 256.742 111.804C255.816 111.201 255.555 109.962 256.158 109.037C257.379 107.164 259.223 105.783 261.364 105.139C263.506 104.496 265.806 104.63 267.857 105.52C269.908 106.41 271.578 107.996 272.572 110C273.565 112.003 273.818 114.293 273.285 116.464L271.342 115.987L273.285 116.461C273.285 116.462 273.285 116.463 273.285 116.464C272.442 119.912 269.315 121.607 267.071 122.423C265.879 122.856 264.745 123.113 263.917 123.263C263.5 123.339 263.153 123.388 262.905 123.419C262.781 123.435 262.682 123.446 262.61 123.453C262.574 123.457 262.545 123.459 262.524 123.461L262.497 123.464L262.488 123.465L262.483 123.465C262.483 123.465 262.482 123.465 262.322 121.525L262.482 123.465C261.381 123.556 260.415 122.737 260.324 121.637C260.233 120.537 261.05 119.572 262.149 119.479C262.15 119.479 262.151 119.479 262.153 119.479L262.317 121.472C262.153 119.479 262.152 119.479 262.152 119.479L262.156 119.478L262.201 119.474C262.245 119.469 262.315 119.462 262.408 119.45C262.594 119.427 262.869 119.388 263.206 119.327C263.885 119.204 264.787 118.997 265.704 118.664C267.676 117.947 269.061 116.9 269.399 115.513L269.4 115.51C269.708 114.256 269.562 112.934 268.988 111.777C268.415 110.62 267.45 109.704 266.265 109.19Z"
          fill="#0A0A0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258.206 130.718C258.467 129.645 259.55 128.988 260.623 129.249L260.646 129.255C261.719 129.517 262.377 130.599 262.115 131.672C261.853 132.745 260.771 133.403 259.698 133.141L259.675 133.135C258.601 132.874 257.944 131.791 258.206 130.718Z"
          fill="#0A0A0A"
        />
      </g>
      <g clipPath="url(#clip1_3427_7607)">
        <path
          d="M233.467 210.671C243.006 208.98 249.368 199.875 247.677 190.336C245.985 180.797 236.881 174.436 227.342 176.127C217.803 177.818 211.441 186.922 213.133 196.461C214.824 206.001 223.928 212.362 233.467 210.671Z"
          fill="#A78BFA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.589 177.515C218.817 179.07 212.966 187.443 214.522 196.215C216.077 204.988 224.45 210.839 233.222 209.283C241.995 207.728 247.846 199.355 246.29 190.583C244.735 181.81 236.362 175.959 227.589 177.515ZM211.746 196.708C209.919 186.402 216.792 176.567 227.097 174.739C237.403 172.912 247.238 179.785 249.066 190.09C250.893 200.396 244.02 210.231 233.714 212.059C223.409 213.886 213.573 207.013 211.746 196.708Z"
          fill="#0A0A0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.63 186.109C228.72 186.114 227.842 186.441 227.15 187.032C226.458 187.623 225.997 188.44 225.85 189.338C225.724 190.106 224.999 190.626 224.231 190.5C223.463 190.374 222.942 189.649 223.068 188.881C223.324 187.326 224.121 185.912 225.319 184.889C226.517 183.866 228.039 183.3 229.614 183.291C231.19 183.282 232.718 183.831 233.927 184.841C235.137 185.85 235.95 187.255 236.223 188.806C236.659 191.27 235.124 193.251 233.908 194.415C233.262 195.033 232.604 195.521 232.113 195.852C231.865 196.02 231.655 196.15 231.504 196.24C231.429 196.286 231.367 196.321 231.323 196.346C231.301 196.359 231.283 196.369 231.27 196.376L231.254 196.385L231.248 196.388L231.246 196.389L231.245 196.39C231.245 196.39 231.244 196.39 230.598 195.199L231.244 196.39C230.56 196.762 229.705 196.508 229.333 195.824C228.962 195.141 229.215 194.286 229.897 193.914C229.898 193.914 229.898 193.914 229.898 193.914C229.898 193.914 229.899 193.914 229.899 193.913C229.899 193.913 229.899 193.913 229.899 193.913L229.902 193.912L229.93 193.896C229.957 193.881 230 193.856 230.056 193.822C230.17 193.754 230.336 193.651 230.536 193.516C230.939 193.244 231.462 192.854 231.959 192.379C233.027 191.356 233.623 190.288 233.447 189.297L233.447 189.295C233.289 188.399 232.82 187.588 232.121 187.004C231.423 186.421 230.54 186.104 229.63 186.109ZM236.223 188.806C236.223 188.806 236.223 188.807 236.223 188.807L234.835 189.051L236.223 188.805C236.223 188.806 236.223 188.806 236.223 188.806Z"
          fill="#0A0A0A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.547 202.283C230.411 201.517 230.922 200.785 231.689 200.649L231.706 200.646C232.472 200.51 233.204 201.022 233.34 201.788C233.476 202.554 232.964 203.286 232.198 203.422L232.181 203.425C231.414 203.561 230.683 203.05 230.547 202.283Z"
          fill="#0A0A0A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3427_7607">
          <rect
            width="59.7419"
            height="59.7419"
            fill="white"
            transform="translate(241.16 83) rotate(13.7115)"
          />
        </clipPath>
        <clipPath id="clip1_3427_7607">
          <rect
            width="42.0994"
            height="42.0994"
            fill="white"
            transform="translate(206 176.35) rotate(-10.0547)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const DiscordCTA = ({
  variant = "secondary",
}: {
  variant?: "primary" | "secondary";
}) => {
  return (
    <div className="mt-8 flex justify-center pb-20">
      <a href="https://discord.gg/eafSXxrB3F" target="_blank">
        <Button size="xl" variant={variant}>
          <div className="mr-2">
            <DiscordSVG />{" "}
          </div>
          Join our Discord
        </Button>
      </a>
    </div>
  );
};
