import { useLocation, useNavigate, useSearchParams, Link } from "react-router";
import { useUserInfoCollected } from "../dashboard/user-info";
import { useGetUserStateAndAlign } from "@/lib/decks-utils/utils";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { HouseIcon, PanelLeftCloseIcon, PanelLeftOpenIcon } from "lucide-react";
import { NewFolderDialog } from "./dialogs";
import { Separator } from "@/components/ui/separator";
import {
  HelpDialog,
  LeaveFeedbackDialog,
  ShareWithFriendsDialog,
} from "@/components/sidebar/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AICreditDisplay } from "./ai-credits";
import { Folder } from "@/database/db";
import { getCustomizedColor } from "./folder-color-utils";
import { AddDeckIcon } from "../icons/add-deck";
import { usePostHog } from "posthog-js/react";

export const availableColors = [
  "hover:bg-violet-200",
  "hover:bg-yellow-200",
  "hover:bg-orange-200",
  "hover:bg-blue-200",
  "hover:bg-sky-200",
  "hover:bg-teal-200",
  "hover:bg-green-200",
  "hover:bg-lime-200",
  "bg-violet-300",
  "bg-yellow-300",
  "bg-orange-300",
  "bg-blue-300",
  "bg-sky-300",
  "bg-teal-300",
  "bg-green-300",
  "bg-lime-300",
  "bg-violet-400",
  "bg-yellow-400",
  "bg-orange-400",
  "bg-blue-400",
  "bg-sky-400",
  "bg-teal-400",
  "bg-green-400",
  "bg-lime-400",
  "bg-violet-400/20",
  "bg-yellow-400/20",
  "bg-orange-400/20",
  "bg-blue-400/20",
  "bg-sky-400/20",
  "bg-teal-400/20",
  "bg-green-400/20",
  "bg-lime-400/20",
  "bg-gray-400/20",
  "bg-violet-100",
  "bg-yellow-100",
  "bg-orange-100",
  "bg-blue-100",
  "bg-sky-100",
  "bg-teal-100",
  "bg-green-100",
  "bg-lime-100",
  "bg-gray-100",
  "hover:border-red-400",
  "hover:bg-red-50",
  "group-hover:border-red-100",
  "hover:border-gray-400",
  "hover:bg-gray-50",
  "group-hover:border-gray-100",
  "hover:border-lime-400",
  "hover:bg-lime-50",
  "group-hover:border-lime-100",
  "hover:border-green-400",
  "hover:bg-green-50",
  "group-hover:border-green-100",
  "hover:border-teal-400",
  "hover:bg-teal-50",
  "group-hover:border-teal-100",
  "hover:border-sky-400",
  "hover:bg-sky-50",
  "group-hover:border-sky-100",
  "hover:border-blue-400",
  "hover:bg-blue-50",
  "group-hover:border-blue-100",
  "hover:border-orange-400",
  "hover:bg-orange-50",
  "group-hover:border-orange-100",
  "hover:border-yellow-400",
  "hover:bg-yellow-50",
  "group-hover:border-yellow-100",
  "hover:border-violet-400",
  "hover:bg-violet-50",
  "group-hover:border-violet-100",
  "hover:border-gray-400",
  "hover:bg-gray-50",
  "group-hover:border-gray-100",
];

export const FoldersSidebar = ({
  isCollapsed,
  setIsCollapsed,
  onClickFolder,
  className = "",
}: {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  onClickFolder?: () => void;
  className?: string;
}) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const folderId = params.get("folder") as string | null;
  const pathname = useLocation().pathname;
  const isUserInfoCollected = useUserInfoCollected();

  const { folders, localDecks, addedDecks } = useGetUserStateAndAlign();

  if (!folders || !localDecks || !isUserInfoCollected) {
    return <></>;
  }

  const hasDeckWithoutFolder = localDecks.some(
    (deck) =>
      !deck.folder || !folders.some((folder) => folder.id === deck.folder)
  );

  return (
    <TooltipProvider>
      <div
        className={cn(
          "flex flex-col bg-secondary-50 border-r border-secondary-200 justify-between h-full p-4 transition-transform duration-300",
          className,
          isCollapsed ? "w-16" : "w-64"
        )}
      >
        <div className="flex flex-col gap-0 items-center ">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="strokeSecondary"
                size="iconSm"
                className={cn(!isCollapsed ? "self-start ml-[6px]" : "")}
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                {isCollapsed ? (
                  <PanelLeftOpenIcon className="h-icon-md w-icon-md" />
                ) : (
                  <PanelLeftCloseIcon className="h-icon-md w-icon-md" />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right" className="bg-background">
              {isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
            </TooltipContent>
          </Tooltip>
          {/* Enable tooltip only when collapsed. when Tooltip.open is undefined -> default behavior.*/}
          <Tooltip open={isCollapsed && undefined}>
            <TooltipTrigger asChild>
              <Button
                variant="strokeSecondarySidebar"
                size={isCollapsed ? "iconSm" : "sm"}
                className={cn(
                  "w-full justify-start text-xs",
                  pathname === "/dashboard" && folderId === null
                    ? "bg-secondary-200"
                    : ""
                )}
                onClick={() => {
                  onClickFolder?.();
                  navigate("/dashboard");
                }}
              >
                <HouseIcon className={cn("w-icon-md h-icon-md text-base")} />

                {!isCollapsed && (
                  <span className={cn(isCollapsed ? "hidden" : "block")}>
                    Home
                  </span>
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right" className="bg-background">
              Home
            </TooltipContent>
          </Tooltip>
          <Tooltip open={isCollapsed && undefined}>
            <TooltipTrigger asChild>
              <Link
                to="/add"
                className="w-full justify-start text-xs"
                onClick={() => posthog.capture("New-Deck-From-Sidebar")}
              >
                <Button
                  size={isCollapsed ? "iconSm" : "sm"}
                  variant="strokeSecondarySidebar"
                >
                  <AddDeckIcon className="h-icon-md w-icon-md" />
                  <span
                    className={cn(
                      "text-body-600 text-xs font-bold",
                      isCollapsed ? "hidden" : "block"
                    )}
                  >
                    Add deck
                  </span>
                </Button>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">Create Deck</TooltipContent>
          </Tooltip>
          <Separator className=" border-secondary-100 my-4" />
          <Tooltip open={isCollapsed && undefined}>
            <TooltipTrigger asChild>
              <div
                className={cn(
                  "flex flew-row items-center w-full",
                  isCollapsed ? "justify-center" : "justify-between"
                )}
              >
                {!isCollapsed ? (
                  <span className="text-body-600 text-xs font-bold">
                    Folders
                  </span>
                ) : (
                  <></>
                )}
                <NewFolderDialog />
              </div>
            </TooltipTrigger>
            <TooltipContent side="right">Create Folder</TooltipContent>
          </Tooltip>

          {folders.map((folder) => {
            return (
              <FolderSidebarButton
                folder={folder}
                isCollapsed={isCollapsed}
                onClickFolder={() => onClickFolder?.()}
                selectedFolderId={folderId!}
                key={folder.id}
              />
            );
          })}
          {hasDeckWithoutFolder ? (
            <FolderSidebarButton
              folder={{
                color: "gray",
                id: "unassigned",
                name: "Decks without folder",
              }}
              isCollapsed={isCollapsed}
              onClickFolder={() => onClickFolder?.()}
              selectedFolderId={folderId!}
            />
          ) : (
            <></>
          )}
          {addedDecks.length > 0 ? (
            <FolderSidebarButton
              folder={{
                color: "gray",
                id: "gpt",
                name: "GPT decks",
              }}
              isCollapsed={isCollapsed}
              onClickFolder={() => onClickFolder?.()}
              selectedFolderId={folderId!}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={cn("flex flex-col gap-2")}>
          <Separator className="border-secondary-100" />
          <ShareWithFriendsDialog isCollapsed={isCollapsed} />
          <HelpDialog isCollapsed={isCollapsed} />
          <LeaveFeedbackDialog isCollapsed={isCollapsed} />
          <Separator className="border-secondary-100" />
          <div className="w-full flex flex-col gap-2">
            <AICreditDisplay
              isCollapsed={isCollapsed}
              isShowWhenPremium={false}
            />
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

const FolderSidebarButton = ({
  isCollapsed,
  folder,
  selectedFolderId,
  onClickFolder,
}: {
  isCollapsed: boolean;
  folder: Folder;
  selectedFolderId: string;
  onClickFolder: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <Tooltip open={isCollapsed && undefined} key={folder.id}>
      <TooltipTrigger asChild>
        <Button
          key={folder.id}
          variant="strokeSecondarySidebar"
          size={isCollapsed ? "iconSm" : "sm"}
          className={cn(
            "w-full p-2 my-0.5 text-xs",
            getCustomizedColor(folder.color, {
              weight: 100,
              pseudoClass: "hover",
            }),
            getCustomizedColor(folder.color, {
              weight: 100,
              pseudoClass: "focus",
            }),
            "border",
            selectedFolderId === folder.id && [
              getCustomizedColor(folder.color, { weight: 100 }, "bg"),
              getCustomizedColor(folder.color, {}, "border"),
            ]
          )}
          onClick={() => {
            onClickFolder?.();
            navigate(`/dashboard?folder=${folder.id}`);
          }}
        >
          <div
            className={cn(
              "flex w-full flex-row gap-2 items-center rounded-md",
              isCollapsed ? "justify-center" : "justify-start"
            )}
          >
            <div
              className={cn(
                "w-4 h-4 min-w-4 rounded-md",
                getCustomizedColor(folder.color),
                "flex-none"
              )}
            />
            {!isCollapsed ? (
              <span className="truncate">{folder.name}</span>
            ) : (
              <></>
            )}
          </div>
        </Button>
      </TooltipTrigger>
      <TooltipContent side="right" className="bg-background text-subtitle-800">
        {folder.name}
      </TooltipContent>
    </Tooltip>
  );
};
