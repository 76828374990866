import { Folder, localDB } from "@/database/db";
import { useState } from "react";
import { COLORS } from "../personalization/deck-editing";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import {
  FolderEdit,
  FolderOpenIcon,
  FolderPenIcon,
  FolderPlusIcon,
  Trash2Icon,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useLocation, useNavigate } from "react-router";
import { TooltipWithShortcuts } from "@/components/tooltips";
import { getCustomizedColor } from "./folder-color-utils";

export const NewFolderDialog = ({
  editFolder,
  displayLabel,
}: {
  editFolder?: Folder;
  alwaysVisible?: boolean;
  displayLabel?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [folderName, setFolderName] = useState(editFolder?.name || "");
  const [selectedColor, setSelectedColor] = useState<(typeof COLORS)[number]>(
    editFolder?.color || COLORS[0]
  );

  return (
    <>
      {editFolder ? (
        <Button
          variant="secondary"
          size="iconMd"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          <FolderPenIcon className="w-icon-md h-icon-md" />
        </Button>
      ) : (
        <Button
          variant={displayLabel ? "secondary" : "strokeSecondary"}
          size={displayLabel ? "sm" : "icon"}
          onClick={() => setIsOpen(true)}
        >
          <FolderPlusIcon className={cn("h-icon-md w-icon-md")} />{" "}
          {displayLabel ? <span className="ml-1">Add Folder</span> : ""}
        </Button>
      )}
      <Dialog open={isOpen} onOpenChange={(change) => setIsOpen(change)}>
        <DialogContent className="max-w-[400px] items-center justify-center text-subtitle-800">
          <DialogHeader className="flex gap-2 items-center justify-start flex-row text-title-950 font-semibold">
            <FolderOpenIcon className=" h-icon-lg w-icon-lg" />{" "}
            {editFolder ? "Edit the folder" : "Create a new folder"}
          </DialogHeader>
          <div className="border border-secondary-100  -ml-10 w-[120%]"></div>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await localDB.updateFolder({
                name: folderName,
                color: selectedColor,
                id: editFolder?.id,
                archivedTimeISO: undefined,
              });
              setIsOpen(false);
            }}
            className="max-w-[342px] flex flex-col gap-8"
          >
            <div className="flex flex-col gap-2">
              <Label htmlFor="name" className="font-semibold">
                Name
              </Label>
              <Input
                id="name"
                value={folderName}
                onChange={(e) => {
                  setFolderName(e.target.value);
                }}
                placeholder="My new Folder! 📁"
                className="max-w-md"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="thumbnail" className="font-semibold">
                Color
              </Label>
              <div className="grid grid-cols-6 gap-3">
                {COLORS.slice(0, -1).map((color, index) => (
                  <TooltipWithShortcuts
                    key={index}
                    text={color}
                    className="capitalize"
                  >
                    <div
                      key={index}
                      className={cn(
                        `w-8 h-8 rounded-xl cursor-pointer`,
                        getCustomizedColor(color),
                        selectedColor === color
                          ? `border-2 border-secondary-700`
                          : ""
                      )}
                      onClick={() => {
                        setSelectedColor(color);
                      }}
                    />
                  </TooltipWithShortcuts>
                ))}
              </div>
            </div>
            <div className="w-full flex justify-center">
              <Button
                type="submit"
                variant="primary"
                disabled={folderName.length === 0}
              >
                {editFolder ? (
                  <>
                    <FolderEdit className="h-5 w-5 mr-2" /> Edit Folder
                  </>
                ) : (
                  <>
                    <FolderPlusIcon className="h-5 w-5 mr-2" /> Create Folder
                  </>
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const DeleteFolderDialog = ({
  activeFolderId,
}: {
  activeFolderId: string;
}) => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [isDialogFolderDeletionOpen, setIsDialogFolderDeletionOpen] =
    useState(false);

  return (
    <Dialog
      open={isDialogFolderDeletionOpen}
      onOpenChange={(status) => setIsDialogFolderDeletionOpen(status)}
    >
      <DialogTrigger asChild>
        <Button variant="secondary" size="iconMd">
          <Trash2Icon className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[400px] items-center justify-center">
        <DialogTitle>Are you sure you want to delete this folder?</DialogTitle>
        <div>This action is not reversable. The decks will not be deleted.</div>
        <DialogFooter>
          <Button
            variant="secondary"
            onClick={() => setIsDialogFolderDeletionOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="alert"
            onClick={async () => {
              await localDB.deleteFolder(activeFolderId);
              setIsDialogFolderDeletionOpen(false);
              navigate(pathname);
            }}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
