import { cn } from "@/lib/utils";

export const AddDeckIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("[--icon-secondary:theme(colors.background)]", className)}
    >
      <g clipPath="url(#clip0_9334_55006)">
        <path
          d="M1.25 11.4578L15.8333 11.4578M1.25 17.1291L1.25 5.78655C1.25 4.89164 1.97546 4.16618 2.87037 4.16618L14.213 4.16618C15.1079 4.16618 15.8333 4.89164 15.8333 5.78655L15.8333 17.1291C15.8333 18.024 15.1079 18.7495 14.213 18.7495L2.87037 18.7495C1.97546 18.7495 1.25 18.024 1.25 17.1291Z"
          stroke="currentColor"
          strokeWidth="1.6625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />{" "}
        <path
          d="M4.16626 2.87053L4.16626 14.2131C4.16626 15.108 4.89172 15.8335 5.78663 15.8335L17.1292 15.8335C18.0241 15.8335 18.7496 15.108 18.7496 14.2131L18.7496 2.87053C18.7496 1.97563 18.0241 1.25016 17.1292 1.25016L5.78663 1.25016C4.89172 1.25016 4.16626 1.97563 4.16626 2.87053Z"
          fill="var(--icon-secondary)"
          stroke="currentColor"
          strokeWidth="1.6625"
        />
        <path
          d="M11.4587 5.62536L11.4587 11.2504M8.74954 8.54183L14.3746 8.54183"
          stroke="currentColor"
          strokeWidth="1.6625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9334_55006">
          <rect width="20" height="20" fill="var(--icon-secondary)" />
        </clipPath>
      </defs>
    </svg>
  );
};
