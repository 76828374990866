import { UserData, UserSettings } from "@/lib/decks-utils/utils";
import { Folder, localDB } from "./db";
import { COLORS } from "@/components/personalization/deck-editing";
import { migrateCardFromLegacyToAnki } from "@/lib/spaced-repetition/migrations";
import { CardProps } from "@/lib/decks-utils/deck-types";

const createNewManualDeck = async ({
  deckName,
  displayName,
  color,
  thumbnailText,
  folder,
  cards = [],
}: {
  deckName: string;
  displayName: string;
  color?: (typeof COLORS)[number];
  thumbnailText?: string;
  folder: string;
  cards?: CardProps[];
}) => {
  await localDB.deckProperties.put({
    name: deckName,
    displayName: displayName,
    color: color,
    thumbnailText: thumbnailText,
    folder: folder,
  });

  await localDB.updateDeckCards(deckName, cards);
};

const syncLocalWithRemote = async ({
  remoteDecks,
  deckNames,
  remoteAiDecks,
  remoteData,
  localFolders,
  remoteFolders,
  remoteSettings,
}: {
  remoteDecks: Record<string, CardProps[]>;
  deckNames: string[];
  remoteAiDecks: string[];
  remoteData: UserData;
  localFolders: Folder[];
  remoteFolders: Folder[];
  remoteSettings: UserSettings;
}) => {
  try {
    // Start a transaction for atomic operations
    await localDB.transaction(
      "rw",
      [
        localDB.decks,
        localDB.folders,
        localDB.deckProperties,
        localDB.settings,
      ],
      async () => {
        // NOTE: BE CAREFUL TO NOT TRIGGER ANY FUNCTION HERE THAT UPDATES THE TIMESTAMP
        // Because this will trigger a lot of syncing!
        for (const [deckName, deckData] of Object.entries(remoteDecks)) {
          localDB.decks.put(
            {
              name: deckName,
              cards: deckData.map(migrateCardFromLegacyToAnki),
            },
            deckName
          );
        }

        const aiDecksSet = new Set(deckNames);
        const aiDecksSyncSet = new Set(remoteAiDecks);

        // Delete the decks that have been deleted remotely but not locally
        const decksNotInLocal = [...aiDecksSet].filter(
          (x) => !aiDecksSyncSet.has(x)
        );
        for (const deckName of decksNotInLocal) {
          localDB.decks.delete(deckName);
        }

        const remoteFoldersSet = new Set(
          (remoteFolders || []).map((folder) => folder.id)
        );
        const foldersNotInRemote = [...localFolders].filter(
          (x) => !remoteFoldersSet.has(x.id)
        );
        for (const folderId of foldersNotInRemote) {
          localDB.folders.delete(folderId.id);
        }

        for (const [folderId, folder] of Object.entries(
          remoteData.data.decks.folders
        )) {
          localDB.folders.put(
            {
              ...folder,
            },
            folderId
          );
        }

        for (const [deckName, properties] of Object.entries(
          remoteData.data.decks.additionalProperites
        )) {
          localDB.deckProperties.put({
            name: deckName,
            displayName: properties.displayName,
            imageBackground:
              properties.imageBackground || properties.imageBackround,
            color: properties.color,
            thumbnailText: properties.thumbnailText,
            languageSettings: properties.languageSettings,
            folder: properties.folder,
          });
        }

        if (remoteSettings?.spacedRepetition) {
          localDB.settings.put({
            key: "globalSrSettings",
            value: remoteSettings.spacedRepetition,
          });
        }

        await localDB.setLatestUpdateTimestamp(remoteData.data.updated_at);
      }
    );
  } catch (error) {
    console.error("Error during sync:", error);
    throw new Error("Failed to synchronize with remote data");
  }
};

export const localDBUtils = { createNewManualDeck, syncLocalWithRemote };
