import { CardProps } from "@/components/memo/study-session";
import { gzip, ungzip } from "pako";
import { Buffer } from "buffer";

// Utility functions for compression
export const compressData = (data: unknown): string => {
  const startTime = performance.now();
  const jsonString = JSON.stringify(data);
  const compressed = gzip(jsonString);
  const result = Buffer.from(compressed).toString("base64");

  const endTime = performance.now();
  console.log(`Compression took ${endTime - startTime}ms`);
  console.log(`Original size: ${jsonString.length} bytes`);
  console.log(`Compressed size: ${result.length} bytes`);
  console.log(
    `Compression ratio: ${((result.length / jsonString.length) * 100).toFixed(
      2
    )}%`
  );

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const decompressData = (compressedData: string): any => {
  const startTime = performance.now();
  const compressedBuffer = Buffer.from(compressedData, "base64");

  const decompressed = ungzip(compressedBuffer as unknown as Uint8Array);

  const jsonString = new TextDecoder().decode(decompressed);

  const result = JSON.parse(jsonString);

  const endTime = performance.now();
  console.log(`Decompression took ${endTime - startTime}ms`);

  return result;
};

// Heuristric: if the deck has more than 1000 cards, compress
export const shouldCompress = (obj: {
  decks: Record<string, CardProps[]>;
}): boolean => {
  if (!obj.decks) return false;

  return true;
};
