export const TikTokIcon = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3596_872)">
          <path d="M14.8104 4.21283C13.9107 4.02038 13.096 3.54558 12.485 2.85766C11.8741 2.16973 11.4988 1.30464 11.414 0.388508V-0.00878906H8.31038V12.3083C8.30846 12.8536 8.13573 13.3846 7.81647 13.8267C7.49721 14.2687 7.04746 14.5997 6.53042 14.7729C6.01339 14.9462 5.45506 14.9531 4.93389 14.7927C4.41272 14.6323 3.95491 14.3126 3.62479 13.8786C3.28955 13.4373 3.10359 12.9007 3.09386 12.3466C3.08414 11.7925 3.25116 11.2498 3.57071 10.797C3.89026 10.3442 4.34573 10.005 4.87106 9.82852C5.39639 9.65205 5.96427 9.6475 6.49236 9.81553V6.66238C5.31475 6.49959 4.11571 6.70895 3.06295 7.26118C2.01019 7.81341 1.15636 8.68089 0.620901 9.74228C0.0854409 10.8037 -0.104872 12.0059 0.0765811 13.1808C0.258035 14.3556 0.802179 15.4444 1.6329 16.2948C2.42512 17.1054 3.44091 17.6615 4.55061 17.8922C5.66031 18.1229 6.81359 18.0177 7.86322 17.59C8.91285 17.1623 9.81122 16.4315 10.4437 15.4909C11.0761 14.5504 11.4139 13.4427 11.414 12.3092V6.01553C12.6683 6.91223 14.1721 7.39305 15.714 7.39031V4.30653C15.4103 4.3069 15.1075 4.2755 14.8104 4.21283Z" />
        </g>
        <defs>
          <clipPath id="clip0_3596_872">
            <rect width="15.9459" height="18" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
