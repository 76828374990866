import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import React from "react";

export const TooltipWithShortcuts = ({
  children,
  text,
  shortcut,
  className = "",
  open,
  delayDuration,
}: {
  children: React.ReactElement;
  text: string;
  shortcut?: string | string[];
  className?: string;
  open?: boolean;
  delayDuration?: number;
}) => {
  const shortcutList: string[] = [];

  if (typeof shortcut === "string") {
    shortcutList.push(shortcut);
  } else if (
    Array.isArray(shortcut) &&
    shortcut.every((item) => typeof item === "string")
  ) {
    shortcut.forEach((el) => shortcutList.push(el));
  }

  return (
    <TooltipProvider>
      <Tooltip delayDuration={delayDuration} open={open}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent
          className={cn(
            "rounded-lg text-neutral-600 bg-neutral-100 border-0",
            "font-medium",
            shortcutList.length > 0 ? "mr-2" : "",
            className
          )}
        >
          <span className="px-2">{text}</span>
          {shortcutList.map((shortc, i) => {
            return (
              <React.Fragment key={shortc}>
                {" "}
                {i > 0 && <span className="pr-1">+</span>}
                <span
                  key={shortc + "-key"}
                  className="px-2 py-1 bg-neutral-200 rounded-md font-medium"
                >
                  {shortc}
                </span>
              </React.Fragment>
            );
          })}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
