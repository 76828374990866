import { createContext, ReactNode, useContext, useEffect } from "react";
import { useLocalStorage } from "../components/hooks/use-local-storage";
import { Switch } from "../components/ui/switch";
import { MoonIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";

export type Theme = "light" | "dark";

const ThemeContext = createContext<
  | {
      theme: Theme;
      setTheme: (theme: Theme) => void;
    }
  | undefined
>(undefined);

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [storedTheme, setStoredTheme] = useLocalStorage<Theme>(
    "theme",
    "light"
  );

  useEffect(() => {
    document.documentElement.classList.toggle("dark", storedTheme === "dark");
  }, [storedTheme]);

  const setTheme = (newTheme: Theme) => {
    setStoredTheme(newTheme);
    document.documentElement.classList.toggle("dark", newTheme === "dark");
  };

  return (
    <ThemeContext.Provider value={{ theme: storedTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
}

export const ThemeSwitch = () => {
  const { theme, setTheme } = useTheme();
  const posthog = usePostHog();

  return (
    <div className="flex flex-row gap-3 pl-2 p-1.5 bg-secondary-50 border border-secondary-200 rounded-[14px]">
      <MoonIcon className=" h-icon-md w-icon-md" />
      <Switch
        checked={theme === "dark"}
        onCheckedChange={(checked) => {
          if (checked) {
            posthog.capture("Dark-Mode-Enable");
            setTheme("dark");
          } else {
            posthog.capture("Dark-Mode-Disable");
            setTheme("light");
          }
        }}
        className="data-[state=checked]: data-[state=checked]:border-violet-500"
      />
    </div>
  );
};
